import { MedicationInfoTabsDialog } from '@/components/Dialogs';
import { H3 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import TriageContext from '@/context/triageContext';
import useMedication from '@/hooks/useMedication';
import useToken from '@/hooks/useToken';
import type { DosageForm, Medication, Period, RouteOfAdministration, Strength, TimeOfDay } from '@/types/medication';
import { pathnameIncludes } from '@/util/location';
import { buildFromValues } from '@/util/medications';
import { gql, useMutation } from '@apollo/client';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import { Badge, Box, Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type { SyntheticEvent } from 'react';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import TriageMedicationBoard from './triageMedicationBoard';
import TriageMedicationList from './triageMedicationList';

const CHANGE_MEDICATION_STATUS = gql`
  mutation changeStatusAccountMedications($medsStatuses: [MedicationStatusInput!]!) {
    changeStatusAccountMedications(medsStatuses: $medsStatuses) {
      status
    }
  }
`;

const ADD_MEDICATION = gql`
  mutation saveMedication(
    $truentityId: String!
    $id: String
    $name: String
    $instructions: String
    $prescriberName: String
    $adherance: String
    $quantity: Float
    $prescriptionWrittenDateAt: String
    $soldDateAt: String
    $pharmacyName: String
    $ndc: String
    $numRefills: Int
    $source: String
    $dosage: [MedicationAttributeInput]
    $strength: [MedicationAttributeInput]
    $period: [MedicationAttributeInput]
    $timeOfDay: [MedicationAttributeInput]
    $routeOfAdministration: [MedicationAttributeInput]
    $lastFillDateAt: String
    $isEffective: Boolean
    $isNoLongerTaking: Boolean
    $isTakenAsPrescribed: Boolean
    $isTakingDifferently: Boolean
    $isUnableToVerify: Boolean
    $isTakenAsDirected: Boolean
    $isSupplement: Boolean
  ) {
    saveMedication(
      truentityId: $truentityId
      medication: {
        id: $id
        name: $name
        instructions: $instructions
        prescriberName: $prescriberName
        adherance: $adherance
        quantity: $quantity
        prescriptionWrittenDateAt: $prescriptionWrittenDateAt
        soldDateAt: $soldDateAt
        pharmacyName: $pharmacyName
        ndc: $ndc
        numRefills: $numRefills
        source: $source
        dosage: $dosage
        strength: $strength
        period: $period
        lastFillDateAt: $lastFillDateAt
        isEffective: $isEffective
        isNoLongerTaking: $isNoLongerTaking
        isTakenAsPrescribed: $isTakenAsPrescribed
        isTakingDifferently: $isTakingDifferently
        isUnableToVerify: $isUnableToVerify
        isTakenAsDirected: $isTakenAsDirected
        timeOfDay: $timeOfDay
        routeOfAdministration: $routeOfAdministration
        isSupplement: $isSupplement
      }
    ) {
      medication {
        id
      }
    }
  }
`;

const TriageMedicationSection = () => {
  const { medications, reloadMeds, resettingMeds } = useContext(PatientDetailContext);
  const { handleVerifyMedications } = useMedication();

  const [triageMedications, setTriageMedications] = useState<Object[]>([]);
  const [interactionsInfo, setInteractionsInfo] = useState<Object[]>([]);

  const { id } = useParams();
  const { roleType } = useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const { showModal } = useModal();
  const [tab, setTab] = useState<number>(0);

  const [saveMedication, { data }] = useMutation(ADD_MEDICATION);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setTab(pathnameIncludes(`board`) ? 1 : 0);
  }, [pathnameIncludes(`board`)]);

  useEffect(() => {
    if (medications?.length > 0) {
      setTriageMedications(medications);

      const tempInteractions = [];
      medications.map(med => {
        if (med.interactions.length > 0) {
          med.interactions.map(int => {
            tempInteractions.push(int);
          });
        }
      });

      const uniqueInteractions = [];
      tempInteractions.map(x => (uniqueInteractions.filter(a => a.id === x.id).length > 0 ? null : uniqueInteractions.push(x)));
      setInteractionsInfo(uniqueInteractions);
    } else {
      setTriageMedications([]);
    }
  }, [medications]);

  useEffect(() => {
    if (resettingMeds) {
      reloadMeds();
    }
  }, [resettingMeds]);

  const triageTabs = useMemo(() => {
    return [
      {
        a11yLabel: 'list',
        label: 'List',
        path: 'list',
        icon: <FormatListBulletedIcon />
      },
      {
        a11yLabel: 'board',
        label: 'Board',
        path: 'board',
        icon: <DashboardIcon />
      }
    ];
  }, [roleType]);

  const notifyMedicationsUpdated = (numOfUpdates: number) => {
    const message = numOfUpdates > 1 ? 'Medications Updated' : 'Medication Updated';
    enqueueSnackbar(message, { variant: 'success' });
  };

  const [changeStatusAccountMedications] = useMutation(CHANGE_MEDICATION_STATUS);

  const changeMedicationStatuses = async statuses => {
    const result = await changeStatusAccountMedications({
      variables: {
        medsStatuses: statuses
      }
    });

    const { status } = result.data!.changeStatusAccountMedications;

    if (status === 'Success') {
      notifyMedicationsUpdated(statuses.length);
      reloadMeds();
    } else {
      console.error('Unable to change medication status');
    }
  };

  const verifyMedications = async statuses => {
    const status = await handleVerifyMedications(statuses);

    if (status) {
      reloadMeds();
    }
  };

  const updateMedications = (medications: Medication[]) => {
    const requests = medications.map((medication: Medication) => ({
      fn: () => {
        let medicationToUpdate = {
          ...medication
        };

        if (medication.strength && medication.strength.length > 0) {
          const strengths = Array.from(medication.strength) || [];
          const strength = strengths.shift();
          medicationToUpdate = {
            ...medicationToUpdate,
            strength: buildFromValues<Strength>(strength, null, strengths, false)
          };
        }

        if (medication.dosage && medication.dosage.length > 0) {
          const dosages = Array.from(medication.dosage) || [];
          const dosage = dosages.shift();

          medicationToUpdate = {
            ...medicationToUpdate,
            dosage: buildFromValues<DosageForm>(dosage, null, dosages, false)
          };
        }

        if (medication.period && medication.period.length > 0) {
          const periods = Array.from(medication.period) || [];
          const period = periods.shift();

          medicationToUpdate = {
            ...medicationToUpdate,
            period: buildFromValues<Period>(period, null, periods, false)
          };
        }

        if (medication.routeOfAdministration && medication.routeOfAdministration.length > 0) {
          const routeOfAdministrations = Array.from(medication.routeOfAdministration) || [];
          const routeOfAdministration = routeOfAdministrations.shift();

          medicationToUpdate = {
            ...medicationToUpdate,
            routeOfAdministration: buildFromValues<RouteOfAdministration>(routeOfAdministration, null, routeOfAdministrations, false)
          };
        }

        if (medication.timeOfDay && medication.timeOfDay.length > 0) {
          const timeOfDays = Array.from(medication.timeOfDay) || [];
          const timeOfDay = timeOfDays.shift();

          medicationToUpdate = {
            ...medicationToUpdate,
            timeOfDay: buildFromValues<TimeOfDay>(timeOfDay, null, timeOfDays, false)
          };
        }

        saveMedication({
          variables: {
            ...medicationToUpdate,
            truentityId: id
          }
        });
      },
      attempts: 0,
      status: null,
      data: null
    }));

    const mapper = item => {
      return new Promise(async resolve => {
        const call = async (attempts = 0) => {
          try {
            const data = await item.fn();
            resolve({ ...item, status: 'OK', attempts, data });
          } catch (err) {
            ++attempts;
            if (attempts < 3) {
              call(attempts);
            } else {
              resolve({ ...item, status: 'ERROR', attempts, data: null });
            }
          }
        };
        call();
      });
    };

    Promise.all(requests.map(mapper))
      .then(response => {
        const fails = response.filter(item => item.status === 'ERROR');

        if (fails.length > 0) {
          const message = fails.length > 1 ? 'Unable to update medications' : 'Unable to update medication';
          enqueueSnackbar(message, { variant: 'error' });
          console.error('Unable to update medications', fails);
          return;
        }

        //TODO: check with rajeev, we are needing this timeout because the data coming back from the api is out of date
        setTimeout(() => {
          notifyMedicationsUpdated(medications.length);
          reloadMeds();
        }, 1000);
      })
      .catch(err => console.warn(err));
  };

  const medicationSelected = medication => {
    navigate(`/patients/${id}/details/medications/edit/${medication.id}`, {
      state: {
        returnUrl: window.location.pathname
      }
    });
  };

  const providerValue = useMemo(
    () => ({
      triageMedications,
      changeMedicationStatuses,
      updateMedications,
      medicationSelected,
      verifyMedications
    }),
    [triageMedications, changeMedicationStatuses, updateMedications, medicationSelected, verifyMedications]
  );

  useEffect(() => {
    let selectedIndex = 0;
    if (location.pathname.toLowerCase().endsWith('/board')) {
      selectedIndex = 1;
    }

    setTab(selectedIndex);
  }, []);

  const handleTabChange = useCallback(
    (event: SyntheticEvent, selectedTabIndex: number) => {
      setTab(selectedTabIndex);
      navigate(`./${triageTabs[selectedTabIndex].path}`);
    },
    [triageTabs, navigate]
  );

  const showMedicationInfoModal = modalType => {
    //TODO:  Need to handle this better.  Needing to add this here otherwise when info modal is closed wrong route is loaded
    navigate(location.pathname, { state: null });
    const medicationInfoModal = showModal(MedicationInfoTabsDialog, {
      modalType: modalType,
      customTitleHeader: true,
      title: modalType !== 'medicationInfo' ? 'Interactions' : '',
      medicationInfo: [],
      medicationInteractionInfo: interactionsInfo,
      hideDialog: () => medicationInfoModal.hide()
    });
  };

  return (
    <TriageContext.Provider value={providerValue}>
      <Stack spacing={4}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            p: 1,
            m: 1,
            bgcolor: 'background.paper',
            borderRadius: 1,
            alignItems: 'center'
          }}
        >
          <H3 sx={{ flex: '1 0 auto', textAlign: 'center' }} css={{ width: '100%' }}>
            Triage Medications
          </H3>

          <Badge badgeContent={interactionsInfo.length} color="secondary" sx={{ maxWidth: 'max-content' }}>
            <Button
              disableElevation
              startIcon={<SyncDisabledIcon />}
              onClick={() => showMedicationInfoModal('medicationInteractionInfo')}
              variant="contained"
              disabled={medications?.length <= 0}
            >
              Interactions
            </Button>
          </Badge>
        </Box>

        <Stack
          sx={{
            backgroundColor: '#fff',
            borderBottom: 1,
            borderColor: 'divider',
            paddingLeft: '10px',
            paddingRight: '10px'
          }}
        >
          <Tabs value={tab} onChange={handleTabChange}>
            {triageTabs.map((tab, index) => (
              <Tab icon={tab.icon} iconPosition={'start'} hidden={tab.hidden} label={tab.label} key={`tab-${index}`} id={`tab-${index}`} />
            ))}
          </Tabs>

          <Stack sx={{ borderTop: 1, borderColor: 'divider', padding: '10px' }}>
            <Outlet />
          </Stack>
        </Stack>
      </Stack>
    </TriageContext.Provider>
  );
};

export { TriageMedicationBoard, TriageMedicationList };
export default TriageMedicationSection;
