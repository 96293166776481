import type { BaseDialogProps } from '@/components/Dialogs/BaseDialog';
import BaseDialog from '@/components/Dialogs/BaseDialog';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import HorizontalStepper from '@/components/Stepper/HorizontalStepper';
import { FollowUpStatusTypes, FollowUpSubTypes, FollowUpTypes } from '@/graphql/account';
import type { CreateRpmFollowupConsultResponse } from '@/graphql/rpmWorkflow';
import { CREATE_RPM_FOLLOWUP_CONSULT } from '@/graphql/rpmWorkflow';
import type { AddFollowupConsultFormType } from '@/routes/PatientDetails/RemotePatientMonitoring/Components/FollowupConsults/AddFollowupConsultForm';
import AddFollowupConsultForm from '@/routes/PatientDetails/RemotePatientMonitoring/Components/FollowupConsults/AddFollowupConsultForm';
import RequestExternalServiceStep, {
  type RequestExternalStepsHandles
} from '@/routes/PatientDetails/RemotePatientMonitoring/Components/ScheduleEnrollments/RequestExternalServiceStep';
import { color } from '@/styles/assets/colors';
import { formatDateAndTime2 } from '@/util/format';
import { useMutation } from '@apollo/client';
import DialogContent from '@mui/material/DialogContent';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useRef, useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';
import Button from '../Button';

type Props = BaseDialogProps & {
  hideDialog: () => void;
  onComplete: () => void;
  truentityId: string;
};

const AddFollowupConsultDialog: React.FC<Props> = ({ title, onComplete, truentityId, hideDialog, ...props }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, reset, handleSubmit, formState } = useForm<AddFollowupConsultFormType>({
    defaultValues: {
      followupConsultDate: null,
      followupConsultTime: null,
      calendarFollowupType: FollowUpTypes.FOLLOW_UP_VISIT,
      calendarFollowupSubType: FollowUpSubTypes.RPM,
      calendarFollowupStatus: FollowUpStatusTypes.PENDING,
      calendarFollowupNote: ''
    }
  });
  const { showModal } = useModal();
  const externalServiceRequestRef = useRef<RequestExternalStepsHandles>(null);

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number>(0);
  const [rpmFollowupConsultId, setRpmFollowupConsultId] = useState<string | undefined>(undefined);
  const [isExternalServicesLoading, setIsExternalServicesLoading] = useState<boolean>(false);

  const [createRpmFollowupConsult, { loading: loadingCreateRpmFollowupConsult }] =
    useMutation<CreateRpmFollowupConsultResponse>(CREATE_RPM_FOLLOWUP_CONSULT);

  const onSubmit: SubmitHandler<AddFollowupConsultFormType> = data => handleAddFollowupConsultSubmit(data);

  const setFormError = (message: string): boolean => {
    enqueueSnackbar(message, {
      variant: 'error'
    });
    return false;
  };

  const increaseCurrentStep = () => setCurrentStep((previous: number) => previous + 1);
  const increaseCompletedStep = () => setCompletedSteps((previous: number) => previous + 1);
  const decreaseCurrentStep = () => setCurrentStep((previous: number) => previous - 1);

  const validateFollowupConsultForm = (values: AddFollowupConsultFormType): boolean => {
    if (values.followupConsultDate === null) {
      return setFormError('Follow-up date is required');
    } else if (values.followupConsultTime === null) {
      return setFormError('Follow-up time is required');
    } else {
      return true;
    }
  };

  const handleAddFollowupConsultSubmit = async (values: AddFollowupConsultFormType) => {
    try {
      if (validateFollowupConsultForm(values)) {
        const dateTime = formatDateAndTime2(values.followupConsultDate, values.followupConsultTime);
        const response = await createRpmFollowupConsult({
          variables: {
            rpmFollowupConsultInput: {
              truentityId: truentityId,
              consultAt: dateTime,
              accountFollowupInput: {
                type: values.calendarFollowupType,
                subType: values.calendarFollowupSubType,
                note: values.calendarFollowupNote,
                status: values.calendarFollowupStatus
              }
            }
          }
        });
        const responseStatus = response.data?.createRpmFollowupConsult?.status;
        if (responseStatus === 'Success') {
          setRpmFollowupConsultId(response?.data?.createRpmFollowupConsult?.rpmFollowupConsultId);
          enqueueSnackbar(response?.data?.createRpmFollowupConsult?.message ?? 'RPM Follow-up Visit added successfully.', {
            variant: 'success'
          });
          increaseCompletedStep();
          increaseCurrentStep();
        } else {
          enqueueSnackbar(response.data?.createRpmFollowupConsult?.message ?? 'Could not add a new Follow-up Visit', {
            variant: 'error'
          });
        }
      }
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Could not add a new Follow-up Visit', {
        variant: 'error'
      });
    }
  };

  const handleExternalServices = async () => {
    try {
      const results = await externalServiceRequestRef.current?.submitForm();
      if (results) {
        increaseCompletedStep();
      }
    } catch (error) {
      console.error('Error while requesting external services:', error);
      enqueueSnackbar('An error occurred while requesting external services', { variant: 'error' });
    }
  };

  const handleAddFollowupConsultCancel = () => {
    if (completedSteps > 0) {
      const modal = showModal(ConfirmDialog, {
        title: 'Confirm Cancellation of Follow-up Visit Schedule',
        message: 'This will cancel, but not the steps already taken. Are you sure?',
        onAgree: () => {
          reset();
          onComplete();
          modal.hide();
        },
        onDisagree: () => modal.hide()
      });
    } else {
      reset();
      hideDialog();
    }
  };

  return (
    <BaseDialog hideDialog={handleAddFollowupConsultCancel} maxWidth={'md'} fullWidth {...props} title={title}>
      <DialogContent sx={{ backgroundColor: color.grey100 }}>
        <HorizontalStepper
          steps={[
            {
              label: 'Schedule Follow-up Visit',
              children: <AddFollowupConsultForm control={control} onSubmit={handleSubmit(onSubmit)} />,
              tryBack: () => decreaseCurrentStep(),
              canNext: () => completedSteps > 0,
              tryNext: () => increaseCurrentStep(),
              canSave: () => formState?.isDirty && formState?.isValid,
              customActionButtons: [
                <Button disabled={completedSteps > 0} isLoading={loadingCreateRpmFollowupConsult} onClick={handleSubmit(onSubmit)}>
                  Schedule
                </Button>,
                <Button type="reset" a11yLabel="Cancel" appearance="outline" onClick={handleAddFollowupConsultCancel} />
              ]
            },
            {
              label: 'Request Medications and Clinical Summary',
              children: (
                <RequestExternalServiceStep
                  truentityId={truentityId}
                  ref={externalServiceRequestRef}
                  setIsExternalServicesLoading={setIsExternalServicesLoading}
                  rpmFollowupConsultId={rpmFollowupConsultId}
                />
              ),
              tryBack: () => decreaseCurrentStep(),
              customActionButtons: [
                <Button onClick={handleExternalServices} isLoading={isExternalServicesLoading}>
                  Send Requests
                </Button>,
                <Button
                  color="success"
                  onClick={() => {
                    onComplete();
                  }}
                  disabled={completedSteps <= 1}
                >
                  Done
                </Button>
              ]
            }
          ]}
          currentStep={currentStep}
        />
      </DialogContent>
    </BaseDialog>
  );
};

export default AddFollowupConsultDialog;
