import InfoCard from '@/components/Cards/InfoCard';
import type { DefaultValueType } from '@/components/Dialogs/FollowUpAlertReviewTaskAddDialog';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import { H4 } from '@/components/Typography';
import type { FollowUpCategoryTypes } from '@/graphql/account';
import type { RelyingPartyAdminData } from '@/routes/Administration';
import { followUpTaskTypes } from '@/util/followUp';
import { capitalizeLetterBeforeCharacterAddSpace } from '@/util/string';
import InfoIcon from '@mui/icons-material/Info';
import { Autocomplete, Box, FormControlLabel, Grid, Paper, Switch, TextField } from '@mui/material';
import type React from 'react';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  index: number;
  accountAssignees: RelyingPartyAdminData[];
  category: FollowUpCategoryTypes;
};

const AlertReviewFollowUpForm: React.FC<Props> = ({ index, accountAssignees, category }) => {
  const { control, watch, setValue } = useFormContext<DefaultValueType>();

  const alertInfo = watch(`followUps.${index}.alertInfo`);
  const createdFollowUpValue = watch(`followUps.${index}.isCreateFollowUpTask`);

  const handleIsCreatedFollowUp = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    if (checked) {
      setValue(`followUps.${index}.isReadAlert`, checked);
    }
    setValue(`followUps.${index}.isCreateFollowUpTask`, checked);
  };

  const FOLLOWUP_TASK_TYPE = useMemo(() => followUpTaskTypes(category), [category]);

  return (
    <>
      <Box component={Paper} bgcolor="white" elevation={0} p={3}>
        <H4>Alert Information</H4>
        <Grid container spacing={0.5} mt={1}>
          {alertInfo &&
            Object.entries(alertInfo).map(([key, value]) => (
              <Grid item xs={6}>
                <InfoCard Icon={InfoIcon} label={capitalizeLetterBeforeCharacterAddSpace(key) || ''} value={value} />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box component={Paper} p={1} bgcolor="white" elevation={0} my={1}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={`followUps.${index}.isCreateFollowUpTask`}
              render={({ field: { value } }) => (
                <FormControlLabel label="Initiate Follow-Up Task" control={<Switch checked={value} onChange={handleIsCreatedFollowUp} />} />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              control={control}
              name={`followUps.${index}.isReadAlert`}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  label="Mark as Read"
                  disabled={createdFollowUpValue}
                  control={<Switch checked={value} onChange={onChange} />}
                />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name={`followUps.${index}.followUpDueDate`}
            rules={{ required: createdFollowUpValue }}
            render={({ field: { onChange, value } }) => (
              <TruentityDatePicker
                label="Follow-up Due Date"
                format="YYYY-MM-DD"
                value={value}
                onChange={onChange}
                disabled={!createdFollowUpValue}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name={`followUps.${index}.type`}
            rules={{ required: createdFollowUpValue }}
            render={({ field: { onChange, value } }) => (
              <SelectList
                label={'Follow-up Type'}
                options={FOLLOWUP_TASK_TYPE}
                placeholder="Select an option..."
                value={value}
                keepOriginalValue
                onChange={onChange}
                required={createdFollowUpValue}
                disabled={!createdFollowUpValue}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            control={control}
            name={`followUps.${index}.taskAssignees`}
            rules={{ required: createdFollowUpValue }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="follow-up-assignees"
                options={accountAssignees}
                onChange={(_event, value) => onChange(value)}
                getOptionLabel={option => option.name || ''}
                value={value?.[0]}
                disabled={!createdFollowUpValue}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Follow-up Assignee"
                    placeholder="select an option"
                    margin="dense"
                    required={createdFollowUpValue}
                    inputProps={{ ...params.inputProps }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name={`followUps.${index}.note`}
            render={({ field: { onChange, value } }) => (
              <TruentityTextField
                value={value}
                multiline
                fullWidth
                rows={2}
                inputProps={{ max: 2 }}
                placeholder="Type your note here"
                id="note"
                label="Notes (Optional)"
                onChange={onChange}
                disabled={!createdFollowUpValue}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AlertReviewFollowUpForm;
