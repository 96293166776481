// typescript globals
declare let __BUILD_COMMIT: string;
declare let __BUILD_VERSION: string;
declare let __BUILD_BRANCH: string;
declare let __BUILD_DATE: string;

export default {
  // BASEPATH: process.env.BASEPATH,
  // process.env.BASEPATH gives problems; after a lot of
  // troubleshooting on 4/29/2022 could not figure out the
  // root cause, assigning '/' directly as a workaround
  BASEPATH: '/',
  DEFAULT_PATH: process.env.DEFAULT_PATH || '/home',
  BUILD_COMMIT: __BUILD_COMMIT.substring(0, 7),
  BUILD_VERSION: __BUILD_VERSION,
  BUILD_BRANCH: __BUILD_BRANCH,
  BUILD_DATE: __BUILD_DATE,
  GRAPHQL_ENDPOINT: process.env.GRAPHQL_ENDPOINT,
  WEBSOCKET_URL: process.env.WEBSOCKET_URL || '',
  INACTIVITY_TIMEOUT_S: 900,
  INACTIVITY_PROMPT_TIMEOUT_S: 60,
  POLLING_INTERVAL_MS: 5000,
  APOLLO_LINK_TIMEOUT_S: 360,
  CACHE_POLICY: process.env.CACHE_POLICY || 'cache-first',
  DEBUG_LAYOUT: false,
  FEATURE_ACTION_CABLED_ENABLED: process.env.FEATURE_ACTION_CABLED_ENABLED || 'true',
  TRUENTITY_INITIAL_VISIT_URL: process.env.TRUENTITY_INITIAL_VISIT_URL,
  TRUENTITY_FOLLOWUP_VISIT_URL: process.env.TRUENTITY_FOLLOWUP_VISIT_URL
};
