import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import type { TabContent } from '@/components/MuiTabs';
import TruentityPhoneNumber from '@/components/TruentityPhoneNumber';
import { H1 } from '@/components/Typography';
import type { GetRpmMonthlyReviewAccountsResponse } from '@/graphql/remotePatientMonitoring';
import { GET_RPM_MONTHLY_REVIEW_ACCOUNTS } from '@/graphql/remotePatientMonitoring';
import type { PatientsData } from '@/routes/Patients/patients';
import { RpmReportReviewTypeEnum } from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDate, formatDateAndTime } from '@/util/format';
import { useQuery } from '@apollo/client';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import QueueIcon from '@mui/icons-material/Queue';
import SendIcon from '@mui/icons-material/Send';
import { Box, Paper, Stack, Tab, Tabs } from '@mui/material';
import type { GridColDef, GridColumnVisibilityModel, GridSortModel } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import type React from 'react';
import type { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const reviewAccountsTabs: TabContent[] = [
  {
    key: RpmReportReviewTypeEnum.PENDING,
    label: 'Pending',
    icon: <QueueIcon />
  },
  {
    key: RpmReportReviewTypeEnum.IN_PROGRESS,
    label: 'In Progress',
    icon: <CalendarTodayIcon />
  },
  {
    key: RpmReportReviewTypeEnum.SIGNED_OFF,
    label: 'Signed Off',
    icon: <SendIcon />
  }
];

const columnVisibility: Record<RpmReportReviewTypeEnum, Record<string, boolean>> = {
  PENDING: {
    patientName: true,
    numCriticalAlerts: true
  },
  IN_PROGRESS: {
    patientName: true,
    numCriticalAlerts: true
  },
  SIGNED_OFF: {
    patientName: true,
    numCriticalAlerts: true
  }
};

export const RpmReviewAccounts: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(columnVisibility.PENDING);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const activeTabKey = useMemo(() => reviewAccountsTabs[activeTab].key, [activeTab]);

  const {
    data: rpmMonthlyReviewAccountsData,
    loading: rpmMonthlyReviewAccountsLoading,
    error: rpmMonthlyReviewAccountsError
  } = useQuery<GetRpmMonthlyReviewAccountsResponse>(GET_RPM_MONTHLY_REVIEW_ACCOUNTS, {
    variables: {
      filterOptions: {
        reviewStatus: activeTabKey
      },
      pageNum: currentPage + 1,
      pageSize: DEFAULT_PAGE_SIZE
    },
    fetchPolicy: 'cache-and-network',
    skip: !activeTabKey
  });

  const handleReviewAccountRowClick = useCallback(
    (patient: Partial<PatientsData>) => {
      navigate(`/patients/${patient?.truentityId}/details/rpm/reports`);
    },
    [navigate]
  );

  const columns: GridColDef<PatientsData>[] = useMemo(() => {
    return [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        flex: 2,
        minWidth: 135,
        sortable: true,
        valueGetter: params => getAccountUserFullName(params.row?.user)
      },
      {
        field: 'numCriticalAlerts',
        headerName: 'Num of Critical Alerts',
        sortable: true,
        flex: 2,
        minWidth: 110,
        valueGetter: params => {
          return params.row.numCriticalAlerts ?? '-';
        }
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDate(params.row.birthDate)
      },
      {
        field: 'healthPlan',
        headerName: 'Health Plan',
        sortable: true,
        flex: 2,
        minWidth: 110,
        width: 110,
        align: 'left',
        headerAlign: 'left',
        valueGetter: params => (params.row.healthPlan ? params.row.healthPlan.orgName : '')
      },
      {
        field: 'phone',
        headerName: 'Phone number',
        sortable: true,
        flex: 2,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.phone,
        renderCell: params => <TruentityPhoneNumber value={params.value} />
      },
      {
        field: 'zipcode',
        headerName: 'Zipcode',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.zipcode
      },
      {
        field: 'rpmEnrolledAt',
        headerName: 'Enrolled On',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 130,
        sortable: true,
        valueGetter: params => (params.row.rpmEnrolledAt ? formatDateAndTime(params.row.rpmEnrolledAt) : '-')
      }
    ];
  }, []);

  const getCustomRowId = useCallback(
    (row: PatientsData): string => {
      return `${row.truentityId} ${activeTabKey}`;
    },
    [activeTabKey]
  );

  const handleTabChange = useCallback((_event: SyntheticEvent, selectedTabIndex: number): void => {
    setActiveTab(selectedTabIndex);
  }, []);

  useEffect(() => {
    if (activeTabKey) {
      setColumnVisibilityModel(columnVisibility[activeTabKey ?? RpmReportReviewTypeEnum.PENDING]);
      setCurrentPage(0);
    }
  }, [activeTabKey]);

  useEffect(() => {
    if (rpmMonthlyReviewAccountsError) {
      const errorMessage =
        rpmMonthlyReviewAccountsError?.message ?? 'Something went wrong, failed to retrieve Review Patients. Please try again later.';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    }
  }, [rpmMonthlyReviewAccountsError]);

  return (
    <Stack spacing={2}>
      <Paper component={Stack} padding={2} elevation={0}>
        <H1>Review Patients</H1>
      </Paper>
      <Paper component={Stack} padding={2} elevation={0}>
        <Box mb={3}>
          <Tabs value={activeTab} onChange={handleTabChange} variant="fullWidth">
            {reviewAccountsTabs?.map(tab => (
              <Tab
                label={<h6 style={{ marginBottom: 0 }}>{tab.label}</h6>}
                key={tab?.key}
                id={`tab-${tab?.key}`}
                icon={tab.icon}
                iconPosition="start"
              />
            ))}
          </Tabs>
        </Box>
        <Stack
          sx={{
            width: '100%',
            height: 'auto'
          }}
          spacing={2}
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          <TruentityDataGrid
            name={`dg-review-accounts-${activeTabKey}`}
            getRowId={getCustomRowId}
            paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
            sortModel={sortModel}
            onSortModelChange={newModel => {
              setSortModel(newModel);
            }}
            columnVisibilityModel={columnVisibilityModel}
            onPaginationModelChange={({ page }) => {
              setCurrentPage(page);
            }}
            rows={rpmMonthlyReviewAccountsData?.getRpmMonthlyReviewAccounts?.accounts ?? []}
            columns={columns}
            loading={rpmMonthlyReviewAccountsLoading}
            paginationMode="server"
            disableRowSelectionOnClick
            rowCount={rpmMonthlyReviewAccountsData?.getRpmMonthlyReviewAccounts?.meta?.totalCount ?? 0}
            sx={{
              '& .MuiDataGrid-columnHeaderCheckbox': {
                minWidth: 50
              }
            }}
            onCellClick={selectedCell => {
              if (selectedCell.field !== '__check__') {
                handleReviewAccountRowClick(selectedCell.row as PatientsData);
              }
            }}
          />
        </Stack>
      </Paper>
    </Stack>
  );
};

export default RpmReviewAccounts;
