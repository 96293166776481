import Button from '@/components/Button';
import SideMenuContext from '@/context/sideMenuContext';
import { GET_PATIENT_DETAIL } from '@/graphql/account';
import { useLazyQuery } from '@apollo/client';
import { Box, List, ListItemButton, ListItemText, ListSubheader, Paper, Stack } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import PatientInfo from '../PatientDetails/patientInfo';

const ProviderSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getInfo, { data }] = useLazyQuery(GET_PATIENT_DETAIL);
  const { setContent } = useContext(SideMenuContext);
  const { pathname } = useLocation();

  const SideMenuContent = useCallback(() => {
    const pathnameIncludes = path => {
      return pathname.includes(path);
    };

    return (
      <List component="nav">
        <Box sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
          <List subheader={<ListSubheader>Administration</ListSubheader>}>
            {/*{isSuperAdmin && (*/}
            {/*  <ListItemButton*/}
            {/*    selected={pathnameIncludes('/manage')}*/}
            {/*    onClick={() => {*/}
            {/*      navigate('manage');*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <ListItemText primary="Manage Portal Orgs" />*/}
            {/*  </ListItemButton>*/}
            {/*)}*/}

            <ListItemButton
              selected={pathnameIncludes('/update-profile')}
              onClick={() => {
                navigate('update-profile');
              }}
            >
              <ListItemText primary="Patient Profile" />
            </ListItemButton>

            <ListItemButton
              selected={pathnameIncludes('/client-org-store')}
              onClick={() => {
                navigate('client-org-store');
              }}
            >
              <ListItemText primary="Manage Client Orgs and Stores" />
            </ListItemButton>

            <ListItemButton
              selected={pathnameIncludes('/insurance-plans')}
              onClick={() => {
                navigate('insurance-plans');
              }}
            >
              <ListItemText primary="Insurance Plans" />
            </ListItemButton>
          </List>
        </Box>
      </List>
    );
  }, [navigate, pathname]);

  useEffect(() => {
    getInfo({
      variables: {
        truentityId: id
      }
    }).catch(err => console.error(err));
  }, [getInfo, id]);

  useEffect(() => {
    setContent(<SideMenuContent />);
  }, [SideMenuContent, setContent]);

  return (
    <Stack direction="column" spacing={4}>
      <Paper component={Stack} p={4}>
        <PatientInfo
          lastName={data?.accountGet?.user?.lastName}
          firstName={data?.accountGet?.user?.firstName}
          address={data?.accountGet?.address}
          birthDate={data?.accountGet?.birthDate}
          phone={data?.accountGet?.phone}
          email={data?.accountGet?.user?.email}
          gender={data?.accountGet?.gender}
        >
          <Stack sx={{ width: '100%' }} alignItems={'flex-end'} justifyContent={'flex-end'}>
            <Button label={'Clinical Details'} onClick={() => navigate(`/patients/${id}/details/`)} />
          </Stack>
        </PatientInfo>
      </Paper>

      <Paper component={Stack} p={4} sx={{ height: '100%' }}>
        <Outlet />
      </Paper>
    </Stack>
  );
};

export default ProviderSection;
