import type { RpmAccountReviewWorkflowNotesResponse } from '@/graphql/remotePatientMonitoring';
import { RPM_ACCOUNT_REVIEW_WORKFLOW_NOTES } from '@/graphql/remotePatientMonitoring';
import RpmPatientReviewNote from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmPatientReviewWorkflow/RpmPatientReviewNote';
import type { PatientReviewNoteType } from '@/types/remotePatientMonitoring';
import { useSubscription } from '@apollo/client';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useEffect, useState } from 'react';

type Props = {
  accountReviewWorkflowId?: string;
};

const RpmPatientReviewNotesList: React.FC<Props> = ({ accountReviewWorkflowId }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [reviewNotes, setReviewNotes] = useState<PatientReviewNoteType[]>([]);

  useSubscription<RpmAccountReviewWorkflowNotesResponse>(RPM_ACCOUNT_REVIEW_WORKFLOW_NOTES, {
    variables: { accountReviewWorkflowId: accountReviewWorkflowId },
    skip: !accountReviewWorkflowId,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.rpmAccountReviewWorkflowNotes?.rpmAccountReviewNotes) {
        setReviewNotes(subscriptionData.data.rpmAccountReviewWorkflowNotes?.rpmAccountReviewNotes);
      }
    },
    onError: err => {
      if (err) {
        const errorMessage = err?.message ?? 'Could not fetch Review Notes for RPM Report';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
        setReviewNotes([]);
      }
    }
  });

  useEffect(() => {
    if (!accountReviewWorkflowId) {
      setReviewNotes([]);
    }
  }, [accountReviewWorkflowId]);

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="start" width="100%" height="auto" spacing={2}>
      {reviewNotes.map(note => (
        <RpmPatientReviewNote
          key={note.id}
          reviewWorkflowId={accountReviewWorkflowId}
          relyingPartyAdminId={note.relyingPartyAdmin.id}
          reviewNoteId={note.id}
          message={note.note}
          user={{
            avatar: note?.relyingPartyAdmin?.presignedAvatarUrl,
            name: `${note?.relyingPartyAdmin?.user?.firstName} ${note?.relyingPartyAdmin?.user?.lastName}`
          }}
          createdAt={note.createdAt}
          replies={note.replies}
        />
      ))}
    </Stack>
  );
};

export default RpmPatientReviewNotesList;
