import Button from '@/components/Button';
import EditPatientReviewNoteDialog from '@/components/Dialogs/EditPatientReviewNoteDialog';
import { ProfileAvatar } from '@/components/ProfileAvatar';
import { Body1, Body2, Caption, H5, H6 } from '@/components/Typography';
import { DELETE_REVIEW_NOTE, SAVE_PATIENT_REVIEW_NOTE } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import type { GeneralUpdateResponseType } from '@/types/graphql';
import type { PatientReviewNoteType } from '@/types/remotePatientMonitoring';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { formatDateAndTime } from '@/util/format';
import { useMutation, useReactiveVar } from '@apollo/client';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import SendIcon from '@mui/icons-material/Send';
import { Card, CardContent, CardHeader, IconButton, Menu, MenuItem, Stack, TextField } from '@mui/material';
import parse from 'html-react-parser';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

type RpmPatientReviewNoteProps = {
  reviewWorkflowId?: string;
  reviewNoteId: string;
  relyingPartyAdminId?: string;
  user: {
    avatar?: string;
    name: string;
  };
  message: string;
  replies?: PatientReviewNoteType[];
  createdAt: string;
};

const RpmPatientReviewNote: React.FC<RpmPatientReviewNoteProps> = ({
  reviewWorkflowId,
  relyingPartyAdminId,
  reviewNoteId,
  message,
  user,
  replies,
  createdAt
}) => {
  const [moreAnchorEl, setMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMoreOpen = Boolean(moreAnchorEl);
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { showModal } = useModal();

  const [showReplyInput, setShowReplyInput] = React.useState(false);
  const [showReplies, setShowReplies] = React.useState(true);
  const [newReplyText, setNewReplyText] = React.useState('');
  const [deletePatientReviewNote, { loading: loadingDeleteNote }] = useMutation<{
    deleteAccountReviewNote: GeneralUpdateResponseType;
  }>(DELETE_REVIEW_NOTE);
  const [savePatientReviewNote, { loading: loadingSavePatientReviewNote }] = useMutation<{
    createAccountReviewNote: GeneralUpdateResponseType;
  }>(SAVE_PATIENT_REVIEW_NOTE);

  const handleMoreOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMoreAnchorEl(event.currentTarget);
  };

  const handleMoreClose = () => {
    setMoreAnchorEl(null);
  };

  const handleChooseReply = useCallback(() => {
    setShowReplyInput(prevState => !prevState);
  }, [setShowReplyInput]);

  const handleChooseEdit = useCallback(() => {
    const modal = showModal(EditPatientReviewNoteDialog, {
      hideDialog: () => {
        modal.hide();
      },
      note: message,
      noteId: reviewNoteId,
      title: 'Update Patient Review Note'
    });
  }, [showModal]);

  const handleShowReply = useCallback(
    (show: boolean) => {
      if (!show) {
        setShowReplyInput(false);
      }
      setShowReplies(show);
    },
    [setShowReplies]
  );

  const handleDeleteReviewNote = useCallback(async () => {
    try {
      const response = await deletePatientReviewNote({
        variables: {
          accountReviewNoteId: reviewNoteId
        }
      });

      if (response.data?.deleteAccountReviewNote?.status === 'Success') {
        enqueueSnackbar('Review Note Deleted Successfully', {
          variant: 'success'
        });
      } else {
        const errorMessage = response?.errors?.[0]?.message ?? 'Failed to delete Review Note. Please try again later.';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    } catch (err: any) {
      const errorMessage = err?.message ?? 'Failed to delete Review Note. Please try again later.';
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    } finally {
      handleMoreClose();
    }
  }, [deletePatientReviewNote, enqueueSnackbar, reviewNoteId]);

  const handleNewReplyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewReplyText(event.target.value);
    },
    [setNewReplyText]
  );

  const handleReplyReviewNote = useCallback(async () => {
    try {
      const response = await savePatientReviewNote({
        variables: {
          accountReviewWorkflowId: reviewWorkflowId,
          note: newReplyText,
          accountReviewNoteId: reviewNoteId
        }
      });

      if (response?.data?.createAccountReviewNote?.status === 'Success') {
        enqueueSnackbar('Reply Added Successfully', {
          variant: 'success'
        });
        setNewReplyText('');
      } else {
        const errorMessage = response?.errors?.[0]?.message ?? 'Failed to save Reply. Please try again later.';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    } catch (err: any) {
      const errorMessage = err?.message ?? 'Failed to save Reply. Please try again later.';
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  }, [savePatientReviewNote, enqueueSnackbar, setNewReplyText, newReplyText, reviewNoteId, reviewWorkflowId]);

  return (
    <Card sx={{ minWidth: { sm: 300, lg: 400 }, maxWidth: { sm: 300, lg: 400 }, height: 'auto' }}>
      <CardHeader
        sx={{ padding: 2, margin: 0 }}
        avatar={
          <ProfileAvatar
            avatarUrl={user?.avatar}
            name={user?.name}
            sx={{ bgcolor: color.primaryMain, width: 30, height: 30, fontSize: 'small' }}
            aria-label="me-avatar"
          />
        }
        action={
          <>
            <IconButton size="small" id="reply-btn" aria-label="reply" onClick={handleChooseReply}>
              <ReplyIcon />
            </IconButton>
            {relyingPartyAdminId && currentUser?.id && currentUser.id === relyingPartyAdminId ? (
              <IconButton size="small" id="reply-btn" aria-label="reply" onClick={handleChooseEdit}>
                <EditIcon />
              </IconButton>
            ) : (
              <></>
            )}
            <IconButton
              id="more-btn"
              size="small"
              aria-controls={isMoreOpen ? 'more-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isMoreOpen ? 'true' : undefined}
              onClick={handleMoreOpen}
              aria-label="settings"
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="more-menu"
              anchorEl={moreAnchorEl}
              open={isMoreOpen}
              onClose={handleMoreClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              <MenuItem disabled={loadingDeleteNote} onClick={handleDeleteReviewNote}>
                Delete Thread
              </MenuItem>
            </Menu>
          </>
        }
        title={
          <Stack width="100%" direction="row" justifyContent="space-between">
            <H5 sx={{ color: color.black100 }}>{user?.name}</H5>
            <Caption sx={{ color: color.grey600 }}>{formatDateAndTime(createdAt)}</Caption>
          </Stack>
        }
      ></CardHeader>
      <CardContent sx={{ margin: 0, padding: 1 }}>
        <Body1 sx={{ color: color.black100 }}>{parse(message)}</Body1>
      </CardContent>
      {replies && replies.length > 0 && (
        <Stack width="100%" direction="row" justifyContent="flex-end">
          {!showReplies && (
            <Button
              sx={{
                fontSize: 'small',
                padding: 0.5,
                margin: 0.5
              }}
              size="small"
              type="button"
              variant={'text'}
              onClick={() => handleShowReply(true)}
            >
              Show Replies
            </Button>
          )}
        </Stack>
      )}
      {(showReplies && replies && replies.length > 0) || showReplyInput ? (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          padding={0}
          margin={0}
          sx={{
            backgroundColor: theme => theme.palette.background.default
          }}
        >
          {replies && replies.length > 0 && (
            <CardContent sx={{ margin: 0, padding: 1 }}>
              {replies.map((reply, index) => (
                <Stack
                  key={index}
                  direction="column"
                  justifyContent="flex-start"
                  alignItems="stretch"
                  spacing={2}
                  sx={{
                    marginBottom: 1,
                    padding: 1,
                    backgroundColor: theme => theme.palette.background.paper,
                    borderRadius: 1,
                    boxShadow: 1
                  }}
                >
                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <ProfileAvatar
                        avatarUrl={reply?.relyingPartyAdmin?.presignedAvatarUrl}
                        name={`${reply?.relyingPartyAdmin?.user?.firstName} ${reply?.relyingPartyAdmin?.user?.lastName}`}
                        sx={{ bgcolor: color.primaryMain, width: 25, height: 25, fontSize: 'x-small' }}
                      />
                      <H6
                        sx={{ color: color.black100 }}
                      >{`${reply?.relyingPartyAdmin?.user?.firstName} ${reply?.relyingPartyAdmin?.user?.lastName}`}</H6>
                    </Stack>
                    <Caption sx={{ color: color.grey600 }}>{formatDateAndTime(reply.createdAt)}</Caption>
                  </Stack>
                  <Body2 sx={{ color: color.black100 }}>{parse(reply.note)}</Body2>
                </Stack>
              ))}
            </CardContent>
          )}
          {(showReplyInput || (replies?.length ?? 0) > 0) && (
            <CardContent sx={{ margin: 0, padding: 1 }}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  id="note-reply"
                  size="small"
                  placeholder={'Write a reply...'}
                  variant="outlined"
                  fullWidth
                  value={newReplyText}
                  onChange={handleNewReplyChange}
                />
                <IconButton type="submit" disabled={newReplyText === '' || loadingSavePatientReviewNote} onClick={handleReplyReviewNote}>
                  <SendIcon />
                </IconButton>
              </Stack>
            </CardContent>
          )}
          <Stack width="100%" direction="row" justifyContent="flex-end">
            <Button
              sx={{
                fontSize: 'small',
                padding: 0.5,
                margin: 0.5
              }}
              size="small"
              type="button"
              variant={'text'}
              onClick={() => handleShowReply(false)}
            >
              Collapse
            </Button>
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default RpmPatientReviewNote;
