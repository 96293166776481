import { AthenaObjectActionType } from '@/types/administration';
import AthenaObjectTab from '../PatientDetails/imports/Components/AthenaObjectTab';

const PatientInsurancePlans = () => {
  return (
    <>
      <AthenaObjectTab type={AthenaObjectActionType.PATIENT_ONBOARDING} />
    </>
  );
};

export default PatientInsurancePlans;
