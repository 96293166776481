import { Box, DialogActions, DialogContent } from '@mui/material';
import type React from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import Button from '../Button';
import TruentityTextField from '../TruentityTextField';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

const defaultValues = {
  claimId: ''
};

type FormValues = typeof defaultValues;

type Props = BaseDialogProps & {
  handleSubmitUserInput: (values: FormValues) => void;
};

const AthenaPatientInputModal: React.FC<Props> = ({ open, title, hideDialog, handleSubmitUserInput, ...props }) => {
  const { control, handleSubmit } = useForm<FormValues>({ defaultValues });

  const onSubmit: SubmitHandler<FormValues> = data => handleSubmitImpl(data);

  const handleSubmitImpl = (values: FormValues) => {
    handleSubmitUserInput(values);
  };

  return (
    <BaseDialog open={open} title={title} hideDialog={hideDialog} {...props} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="claimId"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField id="claimId" type="number" value={value} label="Claim Id" name="claimId" onChange={onChange} />
            )}
          />
          <DialogActions>
            <Button a11yLabel="Submit" type="submit" appearance="primary" />
            <Button a11yLabel="Cancel" type="reset" appearance="outline" onClick={hideDialog} />
          </DialogActions>
        </Box>
      </DialogContent>
    </BaseDialog>
  );
};

export default AthenaPatientInputModal;
