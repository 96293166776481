import type { SvgIconProps } from '@mui/material';
import { Stack } from '@mui/material';
import type { ComponentType, ReactNode } from 'react';
import { Body1, H5 } from '../Typography';

const InfoCard = ({ Icon, label, value }: { label: string; value: ReactNode | string; Icon: ComponentType<SvgIconProps> }) => {
  return (
    <Stack direction="row" gap={1}>
      <Icon fontSize="small" color="info" />
      <Stack>
        <H5>{label}</H5>
        {typeof value === 'string' ? <Body1>{value}</Body1> : value}
      </Stack>
    </Stack>
  );
};

export default InfoCard;
