export type User = {
  lastName: string;
  firstName: string;
  email: string;
};

export type PatientObject = {
  id?: string;
  truentityId?: string;
};

export type ClientStoreObject = {
  name?: string;
  nameTag?: string;
  id?: string;
  clientOrg?: ClientOrganizationObject;
};

export type ClientOrganizationObject = {
  name?: string;
  id?: string | undefined;
  clientStores: ClientStoreObject[];
  accounts: PatientObject[];
  createdAt?: string;
};

export type RelyingPartyAdminsObject = {
  name?: string;
  id?: string | undefined;
  roleType: string;
};

export type PatientInfo = {
  user: User;
  address: string;
  birthDate: string;
  phone: string;
  gender: string;
  numQuickUploadsNew: number;
  doNotCall: boolean;
  doNotCallLastSpecifiedAt: Date | null;
  nextCallSuggestedAt: Date | null;
  primaryLanguage: string;
  consent: boolean;
  accountRpmDiagnosis?: RpmDiagnoses[];
};

export type GetClientOrganizationDataResponse = {
  clientOrganizations: any;
};

export type GetClientOrganizationResponse = {
  clientOrganizations: GetClientOrganizationDataResponse;
};

export enum PerformanceTableType {
  SELF = 'SELF',
  TEAM = 'TEAM'
}

export interface PerformanceStatsType {
  tipsCompleted: number;
  cmrsCompleted: number;
  encounters: number;
  tcmsCompleted: number;
  rpmsCompleted: number;
  invoicedTime: string;
  utilizationRate: number;
}

export interface PerformanceData {
  label: string;
  stats: PerformanceStatsType;
}

export interface MedicalServicesData {
  map: boolean;
  mtm: boolean;
  rpm: boolean;
  tcm: boolean;
  ms: boolean;
  bloodGlucose: boolean;
  bloodPressure: boolean;
  weight: boolean;
}

export type GetAccountMedicalServicesResponse = {
  getAccountMedicalServices: MedicalServicesData;
};

export enum AgreementActionEnum {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT'
}

export enum ScheduledReportTypeEnum {
  MTM_BILLING_REPORT = 'MTM_BILLING_REPORT',
  RPM_PERFORMANCE_REPORT = 'RPM_PERFORMANCE_REPORT',
  ALL_RPM_BILLING_REPORT = 'ALL_RPM_BILLING_REPORT',
  RPM_BILLING_REPORT = 'RPM_BILLING_REPORT'
}

export enum ScheduledReportStatusEnum {
  SCHEDULED = 'SCHEDULED',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}

export type ScheduledReportType = {
  id: string;
  name: string;
  organization: string;
  status: string;
  reportType: string;
  s3Url: string;
  createdAt: string;
};

export type RpmDiagnoses = {
  diagnosisCode?: string;
  diagnosesName?: string;
};

export interface SystemOptionType {
  value: string;
  defaultContactMode?: string;
  isOptionsEditable?: boolean;
  nestedOptions?: SystemOptionType[];
}

export enum SystemOptionKeyEnum {
  RPM_CARE_ACTIVITY_DESCRIPTIONS = 'RPM_CARE_ACTIVITY_DESCRIPTIONS',
  RPM_CO_PILOT_SUGGESTIONS = 'RPM_CO_PILOT_SUGGESTIONS'
}

export enum AthenaObjectActionType {
  PATIENT_ONBOARDING = 'PATIENT_ONBOARDING',
  PATIENT_INSURANCE = 'PATIENT_INSURANCE',
  PATIENT_MEDICATION = 'PATIENT_MEDICATION',
  PATIENT_CLAIMS = 'PATIENT_CLAIMS'
}
