import { FollowUpCategoryTypes, FollowUpStatusTypes, UPDATE_ACCOUNT_FOLLOWUP } from '@/graphql/account';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import { getAlertTypeStyles } from '@/util/rpm';
import { convertToTitleCase } from '@/util/string';
import { useMutation } from '@apollo/client';
import { CheckCircleOutline, ErrorOutlineRounded } from '@mui/icons-material';
import AccessibleIcon from '@mui/icons-material/Accessible';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ChecklistIcon from '@mui/icons-material/Checklist';
import DescriptionIcon from '@mui/icons-material/Description';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import TypeSpecimenIcon from '@mui/icons-material/TypeSpecimen';
import { Box, Chip, DialogActions, DialogContent, Grid, Paper } from '@mui/material';
import moment from 'moment';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback } from 'react';
import Button from '../Button';
import InfoCard from '../Cards/InfoCard';
import { H4 } from '../Typography';
import AddActivityDialog from './AddActivityDialog';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

type Props = BaseDialogProps & {
  category: FollowUpCategoryTypes;
  hideDialog: () => void;
  followUpData: FollowUpRemindersTypes;
  onCompleted: () => void;
};

const FollowUpTaskInfoDialog: React.FC<Props> = ({
  title,
  category,
  hideDialog,
  followUpData,
  onCompleted: onFollowupGetCompleted,
  ...props
}) => {
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();

  const [updateAccountFollowUp] = useMutation(UPDATE_ACCOUNT_FOLLOWUP, {
    onCompleted: data => {
      const status = data?.updateAccountFollowup?.status === 'Success' ? 'success' : 'error';
      const message = data?.updateAccountFollowup?.message || 'Unexpected error occurred while updating a task';
      enqueueSnackbar(message, { variant: status });
      onFollowupGetCompleted();
    },
    onError: err => {
      const code = err.graphQLErrors?.[0]?.extensions?.code;
      const message = err.graphQLErrors?.[0]?.message;

      if (code && code === 'UNEXPECTED_CODE') {
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unexpected error occurred while updating a task', { variant: 'error' });
      }
      hideDialog();
    }
  });

  const onActivitiesChanged = () => {
    updateAccountFollowUp({
      variables: {
        followUpIds: [followUpData.id],
        followUpAssigneeIds: [followUpData.relyingPartyAdmin.id],
        status: FollowUpStatusTypes.COMPLETED
      }
    });
  };

  const handleComplete = () => {
    const modal = showModal(AddActivityDialog, {
      title: 'RPM Care Management Activities',
      hideDialog: () => {
        modal.hide();
      },
      onActivitiesChanged: onActivitiesChanged,
      id: followUpData.account.truentityId,
      followUpId: followUpData.id,
      rpmEnrolledAtDate: moment(followUpData.account.rpmEnrolledAt, 'YYYY-MM-DD HH:mm:ss')
    });
  };

  const renderAlertLabelChip = useCallback((value: string) => {
    const alertTypeStyles = getAlertTypeStyles(value);

    if (alertTypeStyles?.labelColor) {
      return (
        <Chip
          icon={
            <ErrorOutlineRounded
              sx={{
                color: `${alertTypeStyles?.labelColor} !important`
              }}
            />
          }
          size="small"
          variant="outlined"
          label={alertTypeStyles?.labelText}
          sx={{
            borderColor: alertTypeStyles?.labelColor,
            color: alertTypeStyles?.labelColor
          }}
        />
      );
    }
  }, []);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        <Box bgcolor="white" p={2} component={Paper} elevation={0} mb={1}>
          <H4>Patient Information</H4>
          <Grid container spacing={0.5} mt={1}>
            <Grid item xs={6}>
              <InfoCard Icon={AccessibleIcon} label="Patient Name" value={getAccountUserFullName(followUpData.account.user)} />
            </Grid>
          </Grid>
        </Box>
        {category === FollowUpCategoryTypes.ALERT_REVIEW && (
          <Box bgcolor="white" p={2} component={Paper} elevation={0} mb={1}>
            <H4>Alert Information</H4>
            <Grid container spacing={0.5} mt={1}>
              <Grid item xs={6}>
                {followUpData.rpmAlert?.label && (
                  <InfoCard Icon={ChecklistIcon} label="Alert Type" value={renderAlertLabelChip(followUpData.rpmAlert?.label)} />
                )}
              </Grid>
              <Grid item xs={6}>
                <InfoCard Icon={MonitorHeartIcon} label="Reading" value={followUpData.rpmAlert?.reading} />
              </Grid>
              <Grid item xs={6}>
                <InfoCard Icon={DescriptionIcon} label="Description" value={followUpData.rpmAlert?.description} />
              </Grid>
              <Grid item xs={6}>
                <InfoCard Icon={CalendarMonthIcon} label="Recorded At" value={formatDate(followUpData.rpmAlert?.recordedAt)} />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box bgcolor="white" p={2} component={Paper} elevation={0} mb={1}>
          <H4>Follow-up Task Information</H4>
          <Grid container rowSpacing={2} columnSpacing={1} mt={1}>
            <Grid item xs={6}>
              <InfoCard Icon={ChecklistIcon} label="Follow-up Type" value={followUpData.type} />
            </Grid>

            <Grid item xs={6}>
              <InfoCard
                Icon={AdminPanelSettingsIcon}
                label="Assigned By"
                value={getAccountUserFullName(followUpData.createdByRelyingPartyAdmin?.user)}
              />
            </Grid>
            <Grid item xs={6}>
              <InfoCard Icon={AdminPanelSettingsIcon} label="Follow-up Task Due At" value={formatDate(followUpData.followUpOn)} />
            </Grid>
            <Grid item xs={6}>
              <InfoCard
                Icon={TypeSpecimenIcon}
                label="Follow-up Task Status"
                value={
                  <Chip
                    label={followUpData.status}
                    size="small"
                    variant="outlined"
                    color={followUpData.status === FollowUpStatusTypes.COMPLETED ? 'success' : 'info'}
                  />
                }
              />
            </Grid>
            <Grid item xs={6}>
              <InfoCard Icon={TypeSpecimenIcon} label="Follow-up Task Category" value={convertToTitleCase(followUpData.category)} />
            </Grid>
            <Grid item xs={12}>
              <InfoCard Icon={TextSnippetIcon} label="Follow-up Task Note" value={followUpData.notes} />
            </Grid>
          </Grid>
        </Box>
        <DialogActions>
          <Button variant="outlined" onClick={hideDialog}>
            Cancel
          </Button>
          <Button
            startIcon={<CheckCircleOutline />}
            color="success"
            onClick={() => {
              handleComplete();
            }}
          >
            Complete
          </Button>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default FollowUpTaskInfoDialog;
