import type { AccountsFollowupResponse, FollowUpCategoryTypes } from '@/graphql/account';
import { ADD_ACCOUNTS_FOLLOWUPS, FollowUpStatusTypes, FollowUpSubTypes } from '@/graphql/account';
import type { RelyingPartyAdminData } from '@/routes/Administration';
import AlertReviewFollowUpForm from '@/routes/PatientDetails/FollowUps/Components/AlertReviewFollowUpForm';
import type { RPMAccountAlert } from '@/types/remotePatientMonitoring';
import { formatDateAndTime, formatDateIgnoreTZAddDays, formatEndOfDay, getCurrentDate } from '@/util/format';
import { getAlertTypeStyles } from '@/util/rpm';
import { unknown } from '@/util/string';
import { useMutation } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import Alert from '../Alert';
import Button from '../Button';
import TruentitySwiper from '../Swiper';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';
import type { FollowupFormModeTypes } from './FollowUpAddEditDialog';

type Props = BaseDialogProps & {
  accountAssignees: RelyingPartyAdminData[];
  formMode: FollowupFormModeTypes;
  category: FollowUpCategoryTypes.ALERT_REVIEW | FollowUpCategoryTypes.CHART_REVIEW;
  selectedCriticalAlerts?: RPMAccountAlert[];
  onSubmitted: (arg1: string[]) => void;
  hideDialog: () => void;
};

type DefaultFollowUpType = {
  truentityId: string;
  followUpDueDate: DateInput;
  type: string;
  note: string;
  taskAssignees: RelyingPartyAdminData;
  isCreateFollowUpTask: boolean;
  isReadAlert: boolean;
  alertId: string;
  alertInfo: {
    description: string;
    alertType: string;
    reading: string;
    recordedAt: string;
  };
};

export type DefaultValueType = { followUps: Array<DefaultFollowUpType> };

const FollowUpAlertReviewTaskAddDialog: React.FC<Props> = ({
  title,
  hideDialog,
  selectedCriticalAlerts,
  accountAssignees,
  category,
  onSubmitted,
  ...props
}) => {
  const swiperRef = useRef<{ goNext: () => void; goPrev: () => void }>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [addAccountsFollowUps] = useMutation<AccountsFollowupResponse>(ADD_ACCOUNTS_FOLLOWUPS, {
    onCompleted: data => {
      const status = data?.addAccountsFollowups?.status === 'Success' ? 'success' : 'error';
      const message = data?.addAccountsFollowups?.message || "Unexpected error occurred while creating a task'";
      enqueueSnackbar(message, { variant: status });
    },
    onError: err => {
      const code = err.graphQLErrors?.[0]?.extensions?.code;
      const message = err.graphQLErrors?.[0]?.message;

      if (code && code === 'UNEXPECTED_CODE') {
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        enqueueSnackbar('Unexpected error occurred while creating a task', { variant: 'error' });
      }
      hideDialog();
    }
  });

  const methods = useForm<DefaultValueType>({
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      followUps: selectedCriticalAlerts?.map(criticalAlert => ({
        truentityId: criticalAlert.account.truentityId,
        followUpDueDate: formatDateIgnoreTZAddDays(getCurrentDate(), 3),
        type: '',
        note: '',
        taskAssignees: undefined,
        isCreateFollowUpTask: true,
        isReadAlert: true,
        alertId: criticalAlert.id,
        alertInfo: {
          alertType: criticalAlert.label ? getAlertTypeStyles(criticalAlert.label).labelText : unknown(),
          description: criticalAlert.description,
          reading: criticalAlert.reading,
          recordedAt: formatDateAndTime(criticalAlert.recordedAt) || unknown()
        }
      }))
    }
  });

  const { control, watch, handleSubmit } = methods;
  const { fields } = useFieldArray({ control: control, name: 'followUps' });

  const [slideIndex, setSlideIndex] = useState<number>(0);

  const currentFollowUp = watch(`followUps.${slideIndex}`);

  const onSubmit: SubmitHandler<DefaultValueType> = async data => {
    // Filter submitted data
    const filteredData = data.followUps.filter(followUp => followUp.isCreateFollowUpTask);
    const alertReadOnly = data.followUps.filter(followUp => followUp.isReadAlert);

    const followUpObjects = filteredData.map(data => ({
      truentityId: data.truentityId,
      alertId: data.alertId,
      followUpAssigneeIds: data.taskAssignees.id,
      followUpOn: formatEndOfDay(data.followUpDueDate),
      type: data.type,
      subType: FollowUpSubTypes.RPM,
      note: data.note,
      status: FollowUpStatusTypes.PENDING,
      category: category
    }));

    try {
      if (followUpObjects.length > 0) {
        await addAccountsFollowUps({ variables: { followUps: followUpObjects } });
      }

      if (alertReadOnly.length > 0) {
        const alertReadOnlyIds = alertReadOnly.map(data => data.alertId);
        onSubmitted(alertReadOnlyIds);
      } else {
        hideDialog();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unexpected error occurred while creating a task', { variant: 'error' });
    }
  };

  const isCurrentSlideValid =
    !currentFollowUp.isCreateFollowUpTask ||
    (!!currentFollowUp?.followUpDueDate && !!currentFollowUp?.type && !!currentFollowUp?.taskAssignees);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} maxWidth="sm" fullWidth={true}>
      <DialogContent>
        {fields && fields?.length > 0 && (
          <Alert
            description={`Reviewing ${fields.length} critical alerts. Use this form to initiate follow-ups or mark them as read.`}
            status="warning"
            style={{ marginBottom: '16px' }}
          />
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TruentitySwiper ref={swiperRef} onSlideChange={swiper => setSlideIndex(swiper.activeIndex)}>
              {fields.map((filed, index) => (
                <AlertReviewFollowUpForm key={filed.id} index={index} accountAssignees={accountAssignees} category={category} />
              ))}
            </TruentitySwiper>
            <DialogActions>
              <Stack direction="row" width="100%" justifyContent="space-between">
                <Button onClick={hideDialog} variant="outlined">
                  Cancel
                </Button>
                <Stack direction="row" gap={1}>
                  {slideIndex > 0 && (
                    <Button onClick={() => swiperRef.current?.goPrev()} variant="outlined">
                      Prev
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      if (slideIndex < fields.length - 1) {
                        swiperRef.current?.goNext();
                      }
                    }}
                    type={slideIndex === fields.length - 1 ? 'submit' : 'button'}
                    disabled={!isCurrentSlideValid}
                    color={slideIndex === fields.length - 1 ? 'success' : 'primary'}
                  >
                    {slideIndex === fields.length - 1 ? 'Submit' : 'Next'}
                  </Button>
                </Stack>
              </Stack>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </BaseDialog>
  );
};

export default FollowUpAlertReviewTaskAddDialog;
