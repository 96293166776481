import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import type { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import { Navigation } from 'swiper/modules';
import type { SwiperProps } from 'swiper/react';
import { Swiper, SwiperSlide } from 'swiper/react';

interface TruentitySwiperProps extends SwiperProps {
  children: React.ReactNode;
}

interface SwiperControls {
  goNext: () => void;
  goPrev: () => void;
}

const TruentitySwiper = forwardRef<SwiperControls, TruentitySwiperProps>(
  ({ children, spaceBetween = 50, slidesPerView = 1, ...props }, ref) => {
    const swiperRef = useRef<SwiperType | null>(null);

    useImperativeHandle(ref, () => ({
      goNext: () => swiperRef.current?.slideNext(),
      goPrev: () => swiperRef.current?.slidePrev()
    }));

    return (
      <>
        <Swiper
          modules={[Navigation]}
          spaceBetween={spaceBetween}
          slidesPerView={slidesPerView}
          onSwiper={swiper => (swiperRef.current = swiper)}
          preventClicks={false}
          simulateTouch={false}
          {...props}
        >
          {React.Children.map(children, child => (
            <SwiperSlide>{child}</SwiperSlide>
          ))}
        </Swiper>
      </>
    );
  }
);

export default TruentitySwiper;
