import { gql } from '@apollo/client';

export const SEND_COPILOT_MESSAGE = gql`
  mutation SendMessage($truentityId: ID!, $relyingPartyId: ID!, $message: String!) {
    sendMessage(truentityId: $truentityId, relyingPartyId: $relyingPartyId, message: $message) {
      status
      message
    }
  }
`;

export type SendCoPilotMessageResponse = {
  sendMessage: {
    status: string;
    message: string;
  };
};

export const COPILOT_MESSAGING_SUBSCRIPTION = gql`
  subscription OnCopilotMessage {
    copilotMessaging {
      copilot {
        truentityId
        relyingPartyAdminId
        relyingPartyId
        type
        message
        loading
      }
    }
  }
`;

export type CopilotMessagingSubscriptionResponse = {
  copilotMessaging: {
    copilot: {
      truentityId: string;
      relyingPartyId: string;
      relyingPartyAdminId: string;
      type: string;
      message: string;
      loading: boolean;
    };
  };
};

export const LOAD_MESSAGE_CONTEXT = gql`
  mutation MessageContextLoad($truentityId: ID!, $relyingPartyId: ID!) {
    messageContextLoad(truentityId: $truentityId, relyingPartyId: $relyingPartyId) {
      status
      message
    }
  }
`;

export type LoadMessageContextResponse = {
  messageContextLoad: {
    status: string;
    message: string;
  };
};
