import type { UpdateRpmAccountStatusResponse } from '@/graphql/remotePatientMonitoring';
import { UPDATE_RPM_ACCOUNT_STATUS } from '@/graphql/remotePatientMonitoring';
import type { UpdateRpmFollowupConsultResponse } from '@/graphql/rpmWorkflow';
import { UPDATE_RPM_FOLLOWUP_CONSULT } from '@/graphql/rpmWorkflow';
import type { PatientsData } from '@/routes/Patients/patients';
import { RpmApprovalStatusTypes } from '@/types/remotePatientMonitoring';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { today } from '@/util/date';
import { formatDateAndTime } from '@/util/format';
import { useMutation, useReactiveVar } from '@apollo/client';
import { DialogActions, DialogContent, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import Button from '../Button';
import { H4 } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

interface StoreAddDialogProps extends BaseDialogProps {
  hideDialog: () => void;
  patientInfo: PatientsData | null;
  onSignOffComplete: (success: boolean) => void;
  rpmFollowupConsultId?: string;
}

const PatientSignOffDialog: React.FC<StoreAddDialogProps> = ({
  title,
  patientInfo,
  hideDialog,
  onSignOffComplete,
  rpmFollowupConsultId = null,
  ...props
}) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();

  const [updateRpmAccountStatus] = useMutation<UpdateRpmAccountStatusResponse>(UPDATE_RPM_ACCOUNT_STATUS);
  const [updateRpmFollowupConsult] = useMutation<UpdateRpmFollowupConsultResponse>(UPDATE_RPM_FOLLOWUP_CONSULT);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (rpmFollowupConsultId) {
        await updateRpmFollowupConsult({
          variables: {
            id: rpmFollowupConsultId,
            rpmFollowupConsultInput: {
              signOff: true
            }
          }
        });
      } else {
        await updateRpmAccountStatus({
          variables: {
            truentityId: patientInfo?.truentityId,
            rpmStatus: RpmApprovalStatusTypes.APPROVED
          }
        });
      }

      enqueueSnackbar(`Successfully signed off for ${patientInfo?.user?.lastName}, ${patientInfo?.user?.firstName}`, {
        variant: 'success'
      });
      onSignOffComplete(true);
    } catch (error) {
      enqueueSnackbar('Unable to sign off', { variant: 'error' });
      onSignOffComplete(false);
    } finally {
      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <form onSubmit={onSubmit}>
          <TableContainer>
            <Table sx={{ border: 'none' }}>
              <TableBody>
                {/* Patient Info */}
                {patientInfo && (
                  <>
                    <TableRow sx={{ borderBottom: 'none' }}>
                      <TableCell sx={{ borderBottom: 'none', padding: '2px 2px 18px' }}>
                        <H4>
                          Signed by {`${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`}; Date{' '}
                          {formatDateAndTime(today().toDate())}
                        </H4>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'end', padding: '5px 0 0 0' }}>
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  hideDialog();
                }}
              />
              <Button type="submit" a11yLabel="Sign Off" appearance="primary">
                Sign Off
              </Button>
            </DialogActions>
          </Grid>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default PatientSignOffDialog;
