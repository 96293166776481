import Button from '@/components/Button';
import PatientSendVisitUrlDialog from '@/components/Dialogs/PatientSendVisitUrlDialog';
import config from '@/config';
import type { PatientsData } from '@/routes/Patients/patients';
import { VisitTypeEnum } from '@/types/remotePatientMonitoring';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { getMeetUrlWithParams, redirectToDoxyUrl } from '@/util/rpm';
import { useReactiveVar } from '@apollo/client';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import SmsIcon from '@mui/icons-material/Sms';
import { DialogContent, Divider, IconButton, Stack } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Body1 } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

interface PatientStartVisitDialogProps extends BaseDialogProps {
  hideDialog: () => void;
  patientInfo: PatientsData | null;
  onStartTelehealthVisit: (keepModal?: boolean) => Promise<void>;
  visitType: VisitTypeEnum;
}

const PatientStartVisitDialog: React.FC<PatientStartVisitDialogProps> = ({
  title,
  patientInfo,
  hideDialog,
  onStartTelehealthVisit,
  visitType,
  ...props
}) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [meetUrl, setMeetUrl] = useState<string | null>(null);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isAdditionalOptionClicked, setIsAdditionalOptionClicked] = useState(false);

  const showMeetUrlError = useCallback(() => {
    enqueueSnackbar('Could not get Meet URL. Please try again later.', {
      variant: 'error'
    });
  }, [enqueueSnackbar]);

  const handleCopyLink = () => {
    if (meetUrl) {
      navigator.clipboard.writeText(meetUrl).then(() => {
        setIsLinkCopied(true);
        setIsAdditionalOptionClicked(true);
      });
      onStartTelehealthVisit(true);
    } else {
      showMeetUrlError();
    }
  };

  const handleSendLink = () => {
    if (meetUrl) {
      const modal = showModal(PatientSendVisitUrlDialog, {
        title: 'Send Meeting URL Text Message',
        onSend: async () => {
          setIsAdditionalOptionClicked(true);
          await onStartTelehealthVisit(true);
          modal.hide();
        },
        meetUrl,
        hideDialog: () => {
          modal.hide();
        },
        patientInfo: patientInfo
      });
    } else {
      showMeetUrlError();
    }
  };

  const handleStartMeeting = async () => {
    redirectToDoxyUrl({
      firstName: patientInfo?.user?.firstName,
      lastName: patientInfo?.user?.lastName,
      relyingPartyName: currentUser?.relyingParty?.name,
      visitType: visitType
    });
    onStartTelehealthVisit();
  };

  useEffect(() => {
    const meetUrl = visitType === VisitTypeEnum.INITIAL_VISIT ? config.TRUENTITY_INITIAL_VISIT_URL : config.TRUENTITY_FOLLOWUP_VISIT_URL;

    if (meetUrl) {
      const meetUrlWithParams = getMeetUrlWithParams({
        firstName: patientInfo?.user?.firstName,
        lastName: patientInfo?.user?.lastName,
        relyingPartyName: currentUser?.relyingParty?.name,
        meetUrl
      });

      setMeetUrl(meetUrlWithParams);
    } else {
      showMeetUrlError();
    }
  }, [currentUser?.relyingParty?.name, enqueueSnackbar, patientInfo?.user?.firstName, showMeetUrlError]);

  useEffect(() => {
    if (!isLinkCopied) return;

    const timeout = setTimeout(() => {
      setIsLinkCopied(false);
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, [isLinkCopied]);

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <Stack flexDirection="column" justifyContent="flex-start" alignItems="stretch">
          <Stack>
            <Body1>
              Ready to launch the {visitType === VisitTypeEnum.INITIAL_VISIT ? 'telehealth' : 'follow-up telehealth'} visit?
              <br />
              <br />
              <ol>
                <li>Ensure the patient is seated and visible.</li>
                <li>Confirm your volume is turned up, and the camera is on.</li>
              </ol>
              Proceed when ready to place the patient in the {visitType === VisitTypeEnum.INITIAL_VISIT ? "provider's" : ''} queue.
            </Body1>
          </Stack>
          <Stack flexDirection="row" justifyContent="flex-start" marginY={2}>
            <Button
              sx={{
                mx: 1,
                textTransform: 'none'
              }}
              startIcon={<LinkIcon />}
              type="button"
              variant={'outlined'}
              onClick={handleStartMeeting}
            >
              Start Visit
            </Button>
          </Stack>
          <Divider
            sx={{
              marginY: 2
            }}
          />
          <Stack gap={1}>
            <Body1>You may also use this link on a different device to start the visit from that device.</Body1>
            {meetUrl && (
              <Link variant="body1" href={meetUrl}>
                {meetUrl}
              </Link>
            )}
          </Stack>
          <Stack marginTop={1} flexDirection="row" justifyContent="flex-start" alignItems="stretch">
            <IconButton
              sx={{
                mx: 1,
                textTransform: 'none'
              }}
              type="button"
              disabled={!meetUrl}
              onClick={handleCopyLink}
            >
              {isLinkCopied ? <CheckCircleIcon color="success" /> : <ContentCopyIcon />}
            </IconButton>
            <IconButton
              sx={{
                mx: 1,
                textTransform: 'none'
              }}
              type="button"
              disabled={!meetUrl}
              onClick={handleSendLink}
            >
              <SmsIcon />
            </IconButton>
          </Stack>
        </Stack>
        <DialogActions>
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="text" type="button" onClick={hideDialog}>
              {isAdditionalOptionClicked ? 'Done' : 'Cancel'}
            </Button>
          </Stack>
        </DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};

export default PatientStartVisitDialog;
