import Button from '@/components/Button';
import AthenaPatientInputModal from '@/components/Dialogs/AthenaPatientInputModal';
import type { JSONData } from '@/components/JsonKit/JsonEditor';
import JsonEditor from '@/components/JsonKit/JsonEditor';
import { H3 } from '@/components/Typography';
import type { GetAthenaPatientDetails } from '@/graphql/account';
import { FETCH_AND_SAVE_ATHENA_PATIENT_DETAILS, GET_ATHENA_PATIENT_DETAILS } from '@/graphql/account';
import { AthenaObjectActionType } from '@/types/administration';
import { useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

type Props = {
  type: AthenaObjectActionType;
};

const AthenaObjectTab = ({ type }: Props) => {
  const { id: truentityId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { showModal } = useModal();

  const [patientDetails, setPatientDetails] = useState<JSONData | null>(null);

  const { loading: isLoading, error: athenaPatientDetailsError } = useQuery<GetAthenaPatientDetails>(GET_ATHENA_PATIENT_DETAILS, {
    variables: { truentityId, actionType: type },
    onCompleted: data => {
      setPatientDetails(data.getAthenaPatientDetails.athenaObjectData);
    },
    fetchPolicy: 'cache-and-network'
  });

  const [fetchAthenaPatientDetails, { loading: fetchPatientDetailsIsLoading }] = useMutation(FETCH_AND_SAVE_ATHENA_PATIENT_DETAILS, {
    onCompleted: data => {
      const variant = data?.fetchAndSaveAthenaPatientDetails?.status === 'Failure' ? 'error' : 'success';

      enqueueSnackbar(data?.fetchAndSaveAthenaPatientDetails?.message, { variant });
      setPatientDetails(data?.fetchAndSaveAthenaPatientDetails?.data);
    },
    onError: err => {
      console.error(err.message);
      enqueueSnackbar("Can not fetch the patient's data, Please try again", { variant: 'error' });
    }
  });

  const handleImport = () => {
    if (type === AthenaObjectActionType.PATIENT_CLAIMS) {
      const modal = showModal(AthenaPatientInputModal, {
        title: 'Enter claim id',
        hideDialog: () => modal.hide(),
        handleSubmitUserInput: values => {
          fetchAthenaPatientDetails({
            variables: {
              truentityId,
              actionType: type,
              claimId: parseInt(values.claimId)
            }
          });
          modal.hide();
        }
      });
    } else {
      fetchAthenaPatientDetails({
        variables: {
          truentityId,
          actionType: type
        }
      });
    }
  };

  if (isLoading) {
    return (
      <Stack alignItems="center" justifyContent="center" width="100%" bgcolor="white" gap={4} py={6} px={2}>
        <CircularProgress />
      </Stack>
    );
  }

  if (athenaPatientDetailsError) {
    return <H3>An error occurred while fetching data.</H3>;
  }

  return (
    <Stack gap={2}>
      {patientDetails && (
        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Button onClick={handleImport} isLoading={fetchPatientDetailsIsLoading}>
            Re-Import Data
          </Button>
        </Stack>
      )}
      {patientDetails ? (
        <JsonEditor src={patientDetails} />
      ) : (
        <Stack alignItems="center" justifyContent="center" width="100%" bgcolor="white" gap={4} py={6} px={2}>
          <H3>No data available. Please click 'Import Data' to import the data.</H3>
          <Button onClick={handleImport} isLoading={fetchPatientDetailsIsLoading}>
            Import Data
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default AthenaObjectTab;
