import type { ProviderSessionType } from '@/graphql/remotePatientMonitoring';
import type { GeneralUpdateResponseType } from '@/types/graphql';
import type {
  RpmAccountHealthTrackingType,
  RpmAccountSummaryType,
  RpmFollowupConsultTabType,
  RpmReportsType
} from '@/types/remotePatientMonitoring';
import { gql } from '@apollo/client';
export enum AccountCareSummaryStatus {
  Requested = 'Requested',
  Available = 'Available',
  Unavailable = 'Unavailable'
}

export type AccountCareSummaryRequest = {
  id: string;
  externalCorrelationId: string;
  s3Key: string;
  jsonS3Key: string;
  source: string;
  status: AccountCareSummaryStatus;
  requestedOn: string;
  importedOn: string;
  submittedForReviewOn: string;
  createdAt: string;
  updatedAt: string;
};

export type GetAccountCareSummariesResponse = {
  accountCareSummaries: {
    rpmEncounterS3Key?: string;
    carePlanS3Key?: string;
    accountCareSummaries: AccountCareSummaryRequest[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export const GET_ACCOUNT_CARE_SUMMARIES = gql`
  query GetAccountCareSummariesQuery(
    $truentityId: String!
    $filterOptions: AccountCareSummariesFilterOptionsInput
    $pageNum: Int!
    $pageSize: Int!
  ) {
    accountCareSummaries(truentityId: $truentityId, filterOptions: $filterOptions, pageNum: $pageNum, pageSize: $pageSize) {
      accountCareSummaries {
        id
        externalCorrelationId
        s3Key
        jsonS3Key
        source
        status
        requestedOn
        importedOn
        submittedForReviewOn
        createdAt
        updatedAt
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type ImportAccountCareSummaryResponse = {
  importAccountCareSummary: {
    status: string;
    message: string;
  };
};

export const IMPORT_ACCOUNT_CARE_SUMMARY = gql`
  mutation ImportAccountCareSummary($truentityId: String!, $appointmentDate: String!, $rpmFollowupConsultId: String) {
    importAccountCareSummary(truentityId: $truentityId, appointmentDate: $appointmentDate, rpmFollowupConsultId: $rpmFollowupConsultId) {
      status
      message
    }
  }
`;

export const REGENERATE_ACCOUNT_CARE_PLAN_REPORT = gql`
  mutation RegenerateCarePlanReport($truentityId: ID!) {
    regenerateCarePlanReport(truentityId: $truentityId) {
      status
      message
    }
  }
`;

export type SubmitAccountCareSummaryForReviewResponse = {
  reviewAccountCareSummary: {
    status: string;
    message: string;
    providerSession: ProviderSessionType | null;
  };
};

export const SUBMIT_ACCOUNT_CARE_SUMMARY_FOR_REVIEW = gql`
  mutation ReviewAccountCareSummary(
    $truentityId: String!
    $accountCareSummaryIds: [String!]!
    $isRpmEncounterSubmitted: Boolean!
    $reviewSubmitType: ReviewSubmitTypeEnum!
  ) {
    reviewAccountCareSummary(
      truentityId: $truentityId
      accountCareSummaryIds: $accountCareSummaryIds
      isRpmEncounterSubmitted: $isRpmEncounterSubmitted
      reviewSubmitType: $reviewSubmitType
    ) {
      status
      message
      providerSession {
        status
        sessionId
      }
    }
  }
`;

export type CreatePdfRpmReportResponse = {
  createPdfRpmReport: {
    rpmReport?: RpmReportsType;
    errors: string[];
  };
};

export const CREATE_PDF_RPM_REPORT = gql`
  mutation createPdfRpmReport($truentityId: String!) {
    createPdfRpmReport(truentityId: $truentityId) {
      rpmReport {
        id
        reportedDocS3Key
        rpmAccountReviewWorkflow {
          id
        }
      }
      errors
    }
  }
`;

export type CreateRpmCoverLetterResponse = {
  createPdfRpmCoverLetter: {
    pdfDownloadUrl: string;
    errors: string[];
  };
};

export const CREATE_PDF_RPM_COVER_LETTER = gql`
  mutation createPdfRpmCoverLetter($truentityId: String!) {
    createPdfRpmCoverLetter(truentityId: $truentityId) {
      pdfDownloadUrl
      errors
    }
  }
`;

export type GetDocumentPreviewResponse = {
  documentPreview: {
    documentS3Key: string;
    documentUrl: string;
    documentType: string;
  };
};

export type GetConnectiveJsonPreviewResponse = {
  connectiveJsonPreview: {
    documentS3Key: string;
    documentUrl: string;
    documentType: string;
  };
};

export const GET_DOCUMENT_PREVIEW = gql`
  query GetDocumentPreview($documentS3Key: String!) {
    documentPreview(documentS3Key: $documentS3Key) {
      documentS3Key
      documentUrl
      documentType
    }
  }
`;

export const CONNECTIVE_JSON_PREVIEW = gql`
  query ConnectiveJsonPreview($jsonS3Key: String!) {
    connectiveJsonPreview(jsonS3Key: $jsonS3Key) {
      documentS3Key
      documentUrl
      documentType
    }
  }
`;

export type CreatePdfRpmEncounterReportResponse = {
  createPdfRpmAccountEncounterReport: {
    pdfDownloadUrl: string;
    status: string;
    errors: string[];
  };
};

export const CREATE_PDF_RPM_ENCOUNTER_REPORT = gql`
  mutation CreatePdfRpmAccountEncounterReport($truentityId: String!) {
    createPdfRpmAccountEncounterReport(truentityId: $truentityId) {
      pdfDownloadUrl
      status
      errors
    }
  }
`;

export type GetRpmHealthTrackingsResponse = {
  getAccountHealthTrackings: {
    healthTrackings: RpmAccountHealthTrackingType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export const GET_RPM_HEALTH_TRACKINGS = gql`
  query GetAccountHealthTrackings($filterOptions: HealthTrackingsFilterOptionsInput, $truentityId: ID!, $pageNum: Int!, $pageSize: Int!) {
    getAccountHealthTrackings(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      healthTrackings {
        id
        account {
          user {
            firstName
            lastName
          }
        }
        value
        unit
        key
        startedAt
        endedAt
        sourceType
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetRpmAccountSummaryResponse = {
  getRpmAccountSummary: RpmAccountSummaryType;
};

export const GET_RPM_ACCOUNT_SUMMARY = gql`
  query GetRpmAccountSummary($truentityId: String!) {
    getRpmAccountSummary(truentityId: $truentityId) {
      carePlanS3Key
      rpmEncounterS3Key
      signOffAt
      isCarePlanFilled
    }
  }
`;

export type CreateRpmFollowupConsultResponse = {
  createRpmFollowupConsult: GeneralUpdateResponseType & {
    rpmFollowupConsultId?: string;
  };
};

export const CREATE_RPM_FOLLOWUP_CONSULT = gql`
  mutation CreateRpmFollowupConsult($rpmFollowupConsultInput: RpmFollowupConsultInput!) {
    createRpmFollowupConsult(rpmFollowupConsultInput: $rpmFollowupConsultInput) {
      message
      status
      rpmFollowupConsultId
    }
  }
`;

export type UpdateRpmFollowupConsultResponse = {
  updateRpmFollowupConsult: GeneralUpdateResponseType;
};

export const UPDATE_RPM_FOLLOWUP_CONSULT = gql`
  mutation UpdateRpmFollowupConsult($id: ID!, $rpmFollowupConsultInput: UpdateRpmFollowupConsultInput!) {
    updateRpmFollowupConsult(id: $id, rpmFollowupConsultInput: $rpmFollowupConsultInput) {
      message
      status
    }
  }
`;

export type UpdateRpmFollowupConsultTabsTabsResponse = {
  updateRpmFollowupConsultTab: GeneralUpdateResponseType;
};

export const UPDATE_RPM_FOLLOWUP_CONSULT_TAB = gql`
  mutation UpdateRpmFollowupConsultTab($id: ID!, $status: String, $isManualStatusUpdate: Boolean) {
    updateRpmFollowupConsultTab(id: $id, status: $status, isManualStatusUpdate: $isManualStatusUpdate) {
      status
      message
    }
  }
`;

export type GetRpmFollowupConsultTabsResponse = {
  getRpmFollowupConsultTabs: {
    rpmFollowupConsultTabs: RpmFollowupConsultTabType[];
  };
};

export const GET_RPM_FOLLOWUP_CONSULT_TABS = gql`
  query GetRpmFollowupConsultTabs($rpmFollowupConsultId: String!, $rpmFollowupConsultTabTypes: [RpmSetupTabsTypeEnum!]!) {
    getRpmFollowupConsultTabs(rpmFollowupConsultId: $rpmFollowupConsultId, rpmFollowupConsultTabTypes: $rpmFollowupConsultTabTypes) {
      rpmFollowupConsultTabs {
        id
        type
        status
        isManualStatusUpdate
        createdAt
        updatedAt
      }
    }
  }
`;

export type CreatePdfRpmFollowupConsultReport = {
  createPdfRpmFollowupConsultReport: {
    pdfDownloadUrl: string;
    status: string;
  };
};

export const CREATE_PDF_RPM_FOLLOWUP_CONSULT_REPORT = gql`
  mutation CreatePdfRpmFollowupConsultReport($rpmFollowupConsultId: ID!) {
    createPdfRpmFollowupConsultReport(rpmFollowupConsultId: $rpmFollowupConsultId) {
      pdfDownloadUrl
      status
    }
  }
`;

export type SendMeetUrlTextMessage = {
  sendMeetUrlTextMessage: {
    status: string;
  };
};

export const SEND_MEET_URL_TEXT_MESSAGE = gql`
  mutation SendMeetUrlTextMessage($meetUrl: String!, $accountUsername: String!, $toPhoneNumber: String!) {
    sendMeetUrlTextMessage(meetUrl: $meetUrl, accountUsername: $accountUsername, toPhoneNumber: $toPhoneNumber) {
      status
      message
    }
  }
`;
