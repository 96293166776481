import { VERIFY_MEDICATION } from '@/graphql/medication';
import type { MedicationVerificationStatus } from '@/types/medication';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';

type UseMedicationType = {
  handleVerifyMedications: (statuses: MedicationVerificationStatus[]) => Promise<boolean>;
  verifyMedicationsLoading: boolean;
};

const useMedication: () => UseMedicationType = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [verifyMedications, { loading: verifyMedicationsLoading }] = useMutation<{
    changeVerificationStatusAccountMedications: {
      status: string;
    };
  }>(VERIFY_MEDICATION);

  const handleVerifyMedications = async (statuses: MedicationVerificationStatus[]): Promise<boolean> => {
    try {
      const result = await verifyMedications({
        variables: {
          medsVerificationStatuses: statuses
        }
      });

      if (result?.data?.changeVerificationStatusAccountMedications?.status === 'Success') {
        const message =
          statuses.length === 1
            ? 'Medication verification status updated successfully.'
            : 'Medications verification statuses updated successfully.';
        enqueueSnackbar(message, {
          variant: 'success'
        });
        return true;
      } else {
        enqueueSnackbar('Unable to update medication verification status, please contact support.', {
          variant: 'error'
        });
        return false;
      }
    } catch (err: any) {
      enqueueSnackbar('Unable to update medication verification status, please contact support.', {
        variant: 'error'
      });
      return false;
    }
  };

  return {
    handleVerifyMedications,
    verifyMedicationsLoading
  };
};

export default useMedication;
