import type { JSONData } from '@/components/JsonKit/JsonEditor';
import type { PatientsData } from '@/routes/Patients/patients';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import type { AccountType, ActivityLogType, RelyingPartyAdminType, UserType } from '@/types/graphql';
import type Meta from '@/types/meta';
import type {
  AccountDevicesType,
  AccountNoteType,
  AccountOrderType,
  AccountSmartGoalType,
  AccountsMonitoringDeviceType,
  ActivityLog,
  AlertLimitsType,
  AlertLimitType,
  CareActivityType,
  ConsentType,
  Diagnosis,
  PatientReviewNoteType,
  PatientReviewWorkflowType,
  ProviderType,
  RPMAccountAlert,
  RpmAccountsType,
  RpmAlertsCount,
  RpmClaimsType,
  RpmFollowupConsultType,
  RpmProviderActivitiesType,
  RpmReportsCommunicationType,
  RpmReportsType,
  SmartGoalCategory,
  TableReadingsType,
  UpdateRpmReportStatusTypeEnum,
  VitalsDataAvailabilityType
} from '@/types/remotePatientMonitoring';
import type { MetaDataType } from '@/types/tasks';
import { gql } from '@apollo/client';

export type GetAccountDevicesResponse = {
  getMonitoringDevices: {
    monitoringDevices: AccountDevicesType[];
  };
};

export enum GetAccountVitalsReadingsErrors {
  NO_DATA_READINGS = 'No vitals readings found'
}

export type ReadingsSummaryType = {
  uniqueDaysCount: string;
  criticallyHigh: number;
  high: number;
  normal: number;
  low: number;
  criticallyLow: number;
};

export type ReadingsSummaryResponseType = {
  uniqueDaysCount: number;
  uniqueDaysWithBaselineCount: number;
  hasBaseline: boolean;
  criticallyHigh: number;
  high: number;
  normal: number;
  low: number;
  criticallyLow: number;
};

export type GetAccountVitalsReadingsResponse = {
  getAccountVitalsReadings: {
    range?: string;
    average?: string;
    unit?: string;
    xValues?: string[];
    yValues?: number[];
    readingsSummary?: ReadingsSummaryResponseType;
    tableReadings: [TableReadingsType];
    meta?: MetaDataType;
  };
};

export type GetRpmAlertsResponse = {
  getRpmAlerts: {
    rpmAlerts: RPMAccountAlert[];
    meta: MetaDataType;
  };
};

export type GetRpmAlertsCountResponse = {
  getRpmAlertsCounts: RpmAlertsCount;
};

export type GetRpmProviderApprovalResponse = {
  getProviderApprovalAccounts: {
    accountsCounts: {
      readyForReviewCount: number;
      scheduleForReviewCount: number;
      inReviewCount: number;
      approvedCount: number;
      skippedCount: number;
    };
    accounts: PatientsData[];
    meta: Meta;
  };
};

export type RpmEnrolledAccounts = {
  account: PatientsData;
  devices: null | JSONData;
  lastContactedType: null | string;
  lastContactedDate: null | string;
  monthlyTotalReadings: number;
  monthlyTimeSpent: number;
  isRpmReportAvailable: boolean;
};

export type GetRpmEnrolledAccountsResponse = {
  getRpmEnrolledAccounts: {
    rpmEnrolledAccounts: RpmEnrolledAccounts[];
    meta: Meta;
  };
};

export type GetAccountsByRpmStatusResponse = {
  getAccountsByRpmStatus: { rpmAccounts: RpmAccountsType[]; meta: Meta };
};

export type GetRpmProviderAccountsResponse = {
  getProviderSessionAccounts: {
    providerActivities: RpmProviderActivitiesType[];
    meta: Meta;
  };
};

export type CareSummaryUrlType = {
  url: string;
  requestedOn: string;
};

export type GetRpmProviderSessionAccountResponse = {
  getProviderSessionAccount: {
    account: PatientsData;
    summary: string | null;
    careSummaryUrls: CareSummaryUrlType[];
    rpmEncounterUrl: string | null;
  };
};

export type CarePlanValues = {
  conditions: string[];
  outcomes: string[];
  goals: string[];
  barriers: string[];
};

export type CarePlanByAccount = {
  programDescription: string;
  consent: ConsentType;
} & CarePlanValues;

export type GetCarePlanByAccountResponse = {
  getCarePlanByAccount: CarePlanByAccount;
};
export type GetCarePlanValuesResponse = {
  getCarePlanValues: CarePlanValues;
};

export type GetRpmConsentResponse = {
  getRpmConsent: {
    id: string;
    accountId: string;
    rpmParticipation: boolean;
    rpmShareData: boolean;
    jointCollaborationWithIndycare: boolean;
    rpmMonthlyReportSharing: boolean;
    rpmWillReturnDevicesOnCancellation: boolean;
    rpmProgramDescriptionAccepted: boolean;
    rpmCanCancelAnytime: boolean;
    rpmObtainedOn: string;
    rpmObtainedBy: RelyingPartyAdminType;
  };
};

export type GetAccountConfigurationsTypes = {
  getAccountConfigurations: {
    id: string;
    rpmReportProviderByFax: boolean;
    rpmReportProviderByEmail: boolean;
    rpmReportProviderByText: boolean;
    rpmReportPatientByEmail: boolean;
    rpmReportPatientByText: boolean;
    account: AccountType;
    provider: ProviderType;
  }[];
};

export type GetActivityLogsResponse = {
  activityLogsByFilter: {
    activityLogs: ActivityLog[];
    meta: { totalCount: number; totalPages: number };
  };
};

export type GetAccountLastNoteResponse = {
  getAccountLastNote: {
    lastNoteDate: string;
  };
};

export type GetAccountNotesByFilterResponse = {
  getAccountNotesByFilter: AccountNoteType[];
};

export type GetAccountNotesResponse = {
  getAccountNotes: {
    notes: AccountNoteType[];
    meta: Meta;
  };
};

export type GetAccountNotesByIdResponse = {
  getAccountNotesById: AccountNoteType;
};

export type UpdateRpmAlertsResponse = {
  updateRpmAlerts: {
    rpmAlerts: RPMAccountAlert[];
    status: string;
    errors: string[];
  };
};

export type GetRpmReviewActivitiesResponse = {
  getRpmReviewActivities: {
    careActivity: CareActivityType[];
    meta: Meta;
  };
};

export type GetRpmActivitiesResponse = {
  getRpmActivities: {
    careActivity: CareActivityType[];
    setupTotalTime: number;
    monthlyTotalTime: number;
    meta: Meta;
  };
};

export type GetAccountVitalsDataAvailabilityResponse = {
  getAccountVitalsDataAvailability: VitalsDataAvailabilityType;
};

export const GET_ACCOUNT_DEVICES = gql`
  query GetMonitoringDevicesQuery {
    getMonitoringDevices {
      monitoringDevices {
        id
        name
        brandName
        typeName
      }
    }
  }
`;

export const GET_RPM_CANDIDATE_STATISTICS = gql`
  query GetRpmCandidatesStatisticsQuery {
    getRpmCandidatesStatistics {
      statistics {
        title
        value
      }
    }
  }
`;

export const GET_ACCOUNT_VITALS_READINGS_FOR_PAGE = gql`
  query GetAccountVitalsReadingsQuery($pageSize: Int!, $pageNum: Int!, $truentityId: String!, $rpmData: RpmInput!) {
    getAccountVitalsReadings(pageSize: $pageSize, pageNum: $pageNum, truentityId: $truentityId, rpmData: $rpmData) {
      range
      average
      unit
      readingsSummary {
        uniqueDaysCount
        uniqueDaysWithBaselineCount
        hasBaseline
        criticallyHigh
        high
        normal
        low
        criticallyLow
      }
      tableReadings {
        id
        source
        value
        status
        recordedAt
        isBaseline
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_ACCOUNT_VITALS_READINGS_FOR_TABLE = gql`
  query GetAccountVitalsReadingsQuery($pageSize: Int!, $pageNum: Int!, $truentityId: String!, $rpmData: RpmInput!) {
    getAccountVitalsReadings(pageSize: $pageSize, pageNum: $pageNum, truentityId: $truentityId, rpmData: $rpmData) {
      tableReadings {
        id
        source
        value
        status
        recordedAt
        isBaseline
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_RPM_PROVIDER_APPROVAL_ACCOUNTS = gql`
  query GetProviderApprovalAccountsQuery($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountFilterOptionsInput!) {
    getProviderApprovalAccounts(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accountsCounts {
        scheduleForReviewCount
        approvedCount
        inReviewCount
        skippedCount
        enrolledCount
      }
      accounts {
        id
        truentityId
        phone
        gender
        totalTimeSpentSecsInCurrentMonth
        rpmStatus
        rpmStatusUpdatedAt
        rpmApprovalStatus
        rpmSignOffStatus
        onboardedAt
        healthPlan {
          orgName
        }
        birthDate
        zipcode
        rpmStatusComment
        rpmEnrolledAt
        dateOfLastReview
        lastContactedByPhoneOn
        user {
          firstName
          lastName
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_RPM_ACCOUNTS_BY_RPM_STATUS = gql`
  query GetAccountsByRpmStatusQuery($pageNum: Int!, $pageSize: Int!, $filterOptions: AccountFilterOptionsInput!) {
    getAccountsByRpmStatus(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      rpmAccounts {
        id
        truentityId
        phone
        gender
        rpmEnrolledAt
        rpmUnenrolledAt
        onboardedAt
        healthPlan {
          orgName
        }
        birthDate
        zipcode
        setting
        doNotCall
        user {
          firstName
          lastName
        }
        accountsAssignments {
          relyingPartyAdmin {
            id
            name
          }
        }
        accountsFollowupReminders {
          id
          type
          followUpOn
          relyingPartyAdmin {
            id
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_RPM_ENROLLED_ACCOUNTS = gql`
  query GetRpmEnrolledAccountsQuery(
    $pageNum: Int!
    $pageSize: Int!
    $filterMonthYear: String!
    $filterByAccountIds: [ID!]
    $sortModel: [SortModelInput!]
  ) {
    getRpmEnrolledAccounts(
      pageNum: $pageNum
      pageSize: $pageSize
      filterMonthYear: $filterMonthYear
      filterByAccountIds: $filterByAccountIds
      sortModel: $sortModel
    ) {
      rpmEnrolledAccounts {
        account {
          truentityId
          user {
            firstName
            lastName
          }
          healthPlan {
            id
            orgName
          }
          rpmEnrolledAt
          rpmUnenrolledAt
          onboardedAt
          rpmStatus
          setting
          rpmApprovalStatus
          rpmSignOffStatus
        }
        devices
        lastContactedType
        lastContactedDate
        monthlyTotalReadings
        monthlyTimeSpent
        isRpmReportAvailable
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type UpdateCandidateStatusResponse = {
  updateCandidateStatus: {
    status: string;
    message: string;
  };
};

export const UPDATE_RPM_CANDIDATE_STATUS = gql`
  mutation UpdateCandidateStatus($truentityIds: [ID!]!, $rpmStatus: String!) {
    updateCandidateStatus(truentityIds: $truentityIds, rpmStatus: $rpmStatus) {
      status
      message
    }
  }
`;

export const GET_RPM_ALERTS_COUNT = gql`
  query GetRpmAlertsCountsQuery($truentityId: ID, $filterOptions: RpmAccountAlertsFilterOptionsInput!) {
    getRpmAlertsCounts(truentityId: $truentityId, filterOptions: $filterOptions) {
      criticallyHigh
      high
      criticallyLow
      low
      rpmSetup
      noReadings
      providerConfig
      medRegimen
      reportFaxFailed
      patientVitalAccessBlocked
    }
  }
`;

export const GET_RPM_ALERTS = gql`
  query GetRpmAlertsQuery(
    $truentityId: ID
    $pageNum: Int!
    $pageSize: Int!
    $alertGenericType: RpmAlertGenericTypeEnum
    $filterOptions: RpmAccountAlertsFilterOptionsInput!
  ) {
    getRpmAlerts(
      truentityId: $truentityId
      pageNum: $pageNum
      pageSize: $pageSize
      alertGenericType: $alertGenericType
      filterOptions: $filterOptions
    ) {
      rpmAlerts {
        id
        account {
          id
          truentityId
          user {
            firstName
            lastName
          }
          birthDate
          zipcode
          phone
          rpmEnrolledAt
          rpmStatus
        }
        isRead
        reading
        description
        shortDescription
        label
        careActivity {
          id
          title
          notes
          subType
          isEncounter
          totalTimeSpentSecs
          startedAt
          endedAt
          updatedAt
        }
        lastReadingAt
        createdAt
        recordedAt
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const UPDATE_RPM_ALERTS = gql`
  mutation UpdateRpmAlerts($alertIds: [ID!]!, $input: UpdateRpmAlertInput!) {
    updateRpmAlerts(alertIds: $alertIds, input: $input) {
      rpmAlerts {
        id
        isRead
      }
      status
      errors
    }
  }
`;

export type ProviderSessionType = {
  id?: string;
  sessionId: string;
  status: string;
  createdAt?: string;
  updatedAt?: string;
};

export type CreateProviderSessionResponse = {
  createProviderSession: {
    providerSession: ProviderSessionType;
  };
};

export const CREATE_PROVIDER_SESSION = gql`
  mutation CreateProviderSession($truentityIds: [ID!]!) {
    createProviderSession(truentityIds: $truentityIds) {
      providerSession {
        sessionId
        status
      }
    }
  }
`;

export type StartProviderSessionResponse = {
  startProviderSession: {
    authCode: string;
    status: string;
  };
};

export const START_PROVIDER_SESSION = gql`
  mutation StartProviderSession($sessionId: String!, $otpCode: String!) {
    startProviderSession(sessionId: $sessionId, otpCode: $otpCode) {
      authCode
      status
    }
  }
`;

export type CheckProviderSessionResponse = {
  checkProviderSession: {
    status: string;
    provider: ProviderType;
    dateRequested: string;
  };
};

export const CHECK_PROVIDER_SESSION = gql`
  mutation CheckProviderSession($sessionId: String!) {
    checkProviderSession(sessionId: $sessionId) {
      status
      provider {
        individualFirstName
        individualLastName
        npiNumber
        deaNumber
        stateLicense
        taxonomyCode
      }
      dateRequested
    }
  }
`;

export const GET_PROVIDER_SESSION_ACCOUNTS = gql`
  query GetProviderSessionAccountsQuery(
    $pageNum: Int!
    $pageSize: Int!
    $authCode: String!
    $relyingPartyId: String!
    $filterOptions: AccountFilterOptionsInput
  ) {
    getProviderSessionAccounts(
      pageNum: $pageNum
      pageSize: $pageSize
      authCode: $authCode
      relyingPartyId: $relyingPartyId
      filterOptions: $filterOptions
    ) {
      providerActivities {
        id
        createdAt
        requestedAt
        approvalRequestedAt
        rpmStatusUpdatedAt
        performedAt
        providerId
        account {
          id
          truentityId
          phone
          rpmApprovalStatus
          rpmStatus
          createdAt
          birthDate
          zipcode
          user {
            id
            email
            firstName
            lastName
            email
          }
          currentMedsCount
          healthPlan {
            id
            orgName
          }
          lastMedrecDate
          lastImport {
            status
            lastImportDate
          }
          clientOrgs {
            name
            id
          }
          clientStores {
            name
            id
          }
          numQuickUploads
          numQuickUploadsNew
          numPendingTasks
          numCompletedTasks
          lastEncounterDate
          accountsAssignments {
            relyingPartyAdmin {
              id
              name
            }
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetAccountMonitoringDevicesResponse = {
  getAccountDevices: {
    devices: AccountsMonitoringDeviceType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export type GetAccountMonitoringDeviceByIdResponse = {
  getAccountDeviceById: AccountsMonitoringDeviceType;
};

export type GetRpmClaimsResponse = {
  getRpmClaims: {
    billingAccounts: RpmClaimsType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export const GET_ACCOUNT_MONITORING_DEVICES = gql`
  query GetAccountDevices($pageSize: Int!, $pageNum: Int!, $truentityId: String!) {
    getAccountDevices(pageSize: $pageSize, pageNum: $pageNum, truentityId: $truentityId) {
      devices {
        id
        externalPatientId
        hardwareId
        hardwarePatientId
        status
        gatewayId
        formattedGatewayId
        signalStrength
        signalStrengthPercentage
        lastGatewayCheckingTime
        monitoringDevice {
          id
          brandName
          name
          typeName
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_ACCOUNT_MONITORING_DEVICE_NAMES = gql`
  query GetAccountDevices($pageSize: Int!, $pageNum: Int!, $truentityId: String!) {
    getAccountDevices(pageSize: $pageSize, pageNum: $pageNum, truentityId: $truentityId) {
      devices {
        id
        monitoringDevice {
          id
          brandName
          name
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const ADD_ACCOUNT_MONITORING_DEVICE = gql`
  mutation AddAccountDevice($truentityId: String!, $deviceType: String!, $deviceBrand: String!, $hardwareId: String!, $gatewayId: String) {
    addAccountDevice(
      truentityId: $truentityId
      deviceType: $deviceType
      deviceBrand: $deviceBrand
      hardwareId: $hardwareId
      gatewayId: $gatewayId
    ) {
      status
      message
    }
  }
`;

export const GET_PROVIDER_SESSION_ACCOUNT = gql`
  query GetProviderSessionAccount($authCode: String!, $providerActivityId: ID!) {
    getProviderSessionAccount(authCode: $authCode, providerActivityId: $providerActivityId) {
      account {
        truentityId
        zipcode
        address
        consent
        birthDate
        rpmApprovalStatus
        rpmStatus
        rpmStatusComment
        phone
        healthPlan {
          id
          orgName
        }
        gender
        data
        user {
          id
          email
          firstName
          lastName
        }
        clientOrgs {
          name
        }
        clientStores {
          name
        }
        accountsAssignments {
          relyingPartyAdmin {
            name
          }
        }
        lastImport {
          lastImportDate
          status
        }
        numPendingTasks
        numCompletedTasks
        lastEncounterDate
        doNotCall
        doNotCallLastSpecifiedAt
        accountsFollowupReminders {
          id
          followUpOn
          type
          subType
          notes
          status
          relyingPartyAdmin {
            id
            user {
              firstName
              lastName
              email
            }
          }
          createdAt
        }
        primaryLanguage
        currentMedsCount
        lastMedrecDate
        numQuickUploads
        numQuickUploadsNew
      }
      summary
      careSummaryUrls {
        url
        requestedOn
      }
      rpmEncounterUrl
    }
  }
`;

export const UPDATE_PROVIDER_SESSION_ACCOUNT_STATUS = gql`
  mutation UpdateProviderSessionAccountStatus($providerActivityId: ID!, $rpmStatus: String!, $authCode: String!, $comment: String) {
    updateProviderSessionAccountStatus(
      providerActivityId: $providerActivityId
      rpmStatus: $rpmStatus
      authCode: $authCode
      comment: $comment
    ) {
      account {
        id
        truentityId
        phone
        rpmStatus
        rpmStatusComment
        createdAt
        birthDate
        zipcode
      }
    }
  }
`;

export type EndProviderSessionResponse = {
  endProviderSession: {
    status: string;
  };
};

export const END_PROVIDER_SESSION = gql`
  mutation EndProviderSession($authCode: String!) {
    endProviderSession(authCode: $authCode) {
      status
    }
  }
`;

export const GET_CARE_PLAN_BY_ACCOUNT = gql`
  query GetCarePlanByAccountQuery($truentityId: ID!, $filterOptions: AccountCarePlansFilterOptionsInput) {
    getCarePlanByAccount(truentityId: $truentityId, filterOptions: $filterOptions) {
      consent {
        rpmProgramDescriptionAccepted
      }
      programDescription
      conditions
      outcomes
      goals
      barriers
    }
  }
`;

export const ADD_CARE_PLAN = gql`
  mutation AddCarePlan(
    $truentityId: ID!
    $conditionPlans: [String!]
    $outcomePlans: [String!]
    $goalPlans: [String!]
    $barrierPlans: [String!]
  ) {
    addCarePlan(
      truentityId: $truentityId
      conditionPlans: $conditionPlans
      outcomePlans: $outcomePlans
      goalPlans: $goalPlans
      barrierPlans: $barrierPlans
    ) {
      status
      message
    }
  }
`;

export const GET_RPM_CONSENT = gql`
  query GetRpmConsentQuery($truentityId: ID!) {
    getRpmConsent(truentityId: $truentityId) {
      id
      accountId
      rpmParticipation
      rpmShareData
      jointCollaborationWithIndycare
      rpmMonthlyReportSharing
      rpmWillReturnDevicesOnCancellation
      rpmProgramDescriptionAccepted
      rpmCanCancelAnytime
      rpmObtainedOn
      rpmObtainedBy {
        user {
          id
          firstName
          lastName
        }
      }
      account {
        address
      }
    }
  }
`;

export const UPDATE_RPM_CONSENT = gql`
  mutation UpdateRpmConsent(
    $truentityId: ID!
    $rpmParticipation: Boolean!
    $rpmShareData: Boolean!
    $jointCollaborationWithIndycare: Boolean!
    $rpmMonthlyReportSharing: Boolean!
    $rpmCanCancelAnytime: Boolean!
    $rpmWillReturnDevicesOnCancellation: Boolean!
    $rpmProgramDescriptionAccepted: Boolean!
    $rpmObtainedOn: String!
  ) {
    updateRpmConsent(
      truentityId: $truentityId
      rpmParticipation: $rpmParticipation
      rpmShareData: $rpmShareData
      jointCollaborationWithIndycare: $jointCollaborationWithIndycare
      rpmMonthlyReportSharing: $rpmMonthlyReportSharing
      rpmCanCancelAnytime: $rpmCanCancelAnytime
      rpmWillReturnDevicesOnCancellation: $rpmWillReturnDevicesOnCancellation
      rpmProgramDescriptionAccepted: $rpmProgramDescriptionAccepted
      rpmObtainedOn: $rpmObtainedOn
    ) {
      consent {
        id
        accountId
        rpmParticipation
        rpmShareData
        jointCollaborationWithIndycare
        rpmCanCancelAnytime
        rpmMonthlyReportSharing
        rpmWillReturnDevicesOnCancellation
        rpmProgramDescriptionAccepted
        rpmObtainedOn
        account {
          truentityId
        }
      }
    }
  }
`;

export type AccountProviderType = {
  provider: ProviderType;
  isPrimaryCare?: boolean;
};

export type RelyingPartyProviderType = {
  provider: ProviderType;
  isRpmReviewer: boolean;
  isPrimary: boolean;
};

export type SelectedProvidersType = {
  providerId: string;
} & ProviderMethodsTypes;

export type ProviderMethodsTypes = {
  isRpmReportProviderByFax: boolean;
  isRpmReportProviderByEmail: boolean;
  isRpmReportProviderByText: boolean;
};

export type GetAccountProvidersResponse = {
  accountProviders: {
    accountProviders: AccountProviderType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export type GetCommunicationLogsResponse = {
  getCommunicationLogs: {
    activityLogs: ActivityLogType[];
  };
};

export type GetAllProvidersResponse = {
  getAllProviders: {
    providers: ProviderType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export type GetRelyingPartyProvidersResponse = {
  relyingPartyProviders: {
    relyingPartyProviders: RelyingPartyProviderType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};
export type AccountProvidersResponse = {
  accountProviders: {
    accountProviders: AccountProviderType[];
    meta: Meta;
  };
};

export const GET_ACCOUNT_PROVIDERS = gql`
  query GetAccountProviders($truentityId: String!, $pageSize: Int, $pageNum: Int) {
    accountProviders(truentityId: $truentityId, pageSize: $pageSize, pageNum: $pageNum) {
      accountProviders {
        provider {
          id
          individualFirstName
          individualMiddleName
          individualLastName
          npiNumber
          deaNumber
          stateLicense
          taxonomyCode
          contacts {
            type
            value
          }
        }
        isPrimaryCare
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_RELYING_PARTY_PROVIDERS = gql`
  query RelyingPartyProviders($relyingPartyId: ID!, $pageNum: Int!, $pageSize: Int!) {
    relyingPartyProviders(relyingPartyId: $relyingPartyId, pageNum: $pageNum, pageSize: $pageSize) {
      relyingPartyProviders {
        provider {
          id
          npiNumber
          individualLastName
          individualFirstName
          email
          phone
          contacts {
            type
            value
          }
        }
        rpmReportByText
        rpmReportByEmail
        rpmReportByFax
        isRpmReviewer
        isPrimary
      }
      meta {
        currentPage
        totalCount
      }
    }
  }
`;

export const CREATE_MULTIPLE_ACCOUNT_CONFIGURATIONS = gql`
  mutation CreateMultipleAccountConfigurations($truentityId: ID!, $configurationsInputs: AccountConfigurationsInput!) {
    createMultipleAccountConfigurations(truentityId: $truentityId, configurationsInputs: $configurationsInputs) {
      status
      message
    }
  }
`;

export const GET_ACCOUNT_CONFIGURATIONS = gql`
  query GetAccountConfigurations($truentityId: String!) {
    getAccountConfigurations(truentityId: $truentityId) {
      id
      rpmReportProviderByEmail
      rpmReportProviderByText
      rpmReportProviderByFax
      rpmReportPatientByEmail
      rpmReportPatientByText
      provider {
        id
      }
      account {
        id
        truentityId
      }
    }
  }
`;

export const GET_ACTIVITY_LOGS = gql`
  query ActivityLogsByFilter($pageNum: Int!, $pageSize: Int!, $activityTypes: [ActivityTypeEnum!]) {
    activityLogsByFilter(pageNum: $pageNum, pageSize: $pageSize, activityTypes: $activityTypes) {
      activityLogs {
        id
        logText
        account {
          truentityId
          user {
            firstName
            lastName
          }
        }
        activityType
        createdAt
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export const ASSIGN_MEDREC_TO_RPM_REPORT = gql`
  mutation AssignMedrecToRpmReport($truentityId: ID!, $medrecSnapshotId: String!) {
    assignMedrecToRpmReport(truentityId: $truentityId, medrecSnapshotId: $medrecSnapshotId) {
      status
      message
    }
  }
`;

export const GET_ACCOUNT_LAST_NOTE = gql`
  query GetAccountLastNote($truentityId: ID!) {
    getAccountLastNote(truentityId: $truentityId) {
      lastNoteDate
    }
  }
`;

export type AccountNotesFilterOptions = {
  monthYear?: string;
  shareWithProvider?: boolean;
  pinToProfile?: boolean;
};

export const GET_ACCOUNT_NOTES_BY_FILTER = gql`
  query GetAccountNotesByFilter($truentityId: ID!, $filterOptions: AccountNotesFilterOptionsInput!) {
    getAccountNotesByFilter(truentityId: $truentityId, filterOptions: $filterOptions) {
      id
      title
      note
      modeOfCapture
      shareWithProvider
      pinToProfile
      createdAt
    }
  }
`;

export const GET_DEVICE_BY_ID = gql`
  query GetAccountDeviceById($deviceId: String!) {
    getAccountDeviceById(deviceId: $deviceId) {
      id
      externalPatientId
      hardwareId
      hardwarePatientId
      status
      monitoringDevice {
        id
        brandName
        name
        typeName
      }
    }
  }
`;

export const UPDATE_ACCOUNT_NOTE = gql`
  mutation UpdateAccountNote(
    $truentityId: ID!
    $noteId: String!
    $modeOfCapture: String!
    $description: String!
    $shareWithProvider: Boolean!
    $pinToProfile: Boolean!
  ) {
    updateAccountNote(
      truentityId: $truentityId
      noteId: $noteId
      modeOfCapture: $modeOfCapture
      description: $description
      shareWithProvider: $shareWithProvider
      pinToProfile: $pinToProfile
    ) {
      status
      message
    }
  }
`;

export const DELETE_ACCOUNT_NOTE = gql`
  mutation DeleteAccountNote($truentityId: String!, $noteId: String!) {
    deleteAccountNote(truentityId: $truentityId, noteId: $noteId) {
      status
      message
    }
  }
`;

export const UPDATE_ACCOUNT_NOTE_PROVIDERS = gql`
  mutation UpdateAccountNoteProviders($truentityId: ID!, $noteIds: [String!]!) {
    updateAccountNoteProviders(truentityId: $truentityId, noteIds: $noteIds) {
      status
      message
    }
  }
`;

export const UPDATE_ACCOUNT_NOTE_PIN = gql`
  mutation UpdateAccountNotePin($truentityId: ID!, $noteIds: [String!]!) {
    updateAccountNotePin(truentityId: $truentityId, noteIds: $noteIds) {
      status
      message
    }
  }
`;

export const DELETE_ACCOUNT_MONITORING_DEVICE = gql`
  mutation RemoveAccountDevice($truentityId: String!, $deviceId: String!) {
    removeAccountDevice(truentityId: $truentityId, deviceId: $deviceId) {
      status
      message
    }
  }
`;

export const UPDATE_ACCOUNT_MONITORING_DEVICE = gql`
  mutation UpdateAccountDevice(
    $truentityId: String!
    $deviceId: String!
    $deviceType: String!
    $deviceBrand: String!
    $hardwareId: String!
  ) {
    updateAccountDevice(
      truentityId: $truentityId
      deviceId: $deviceId
      deviceType: $deviceType
      deviceBrand: $deviceBrand
      hardwareId: $hardwareId
    ) {
      status
      message
    }
  }
`;

export const ADD_ACCOUNT_NOTE = gql`
  mutation CreateAccountNote($truentityId: ID!, $modeOfCapture: String!, $description: String!, $dateTime: String) {
    createAccountNote(truentityId: $truentityId, modeOfCapture: $modeOfCapture, description: $description, dateTime: $dateTime) {
      message
      status
    }
  }
`;

export type GetCoverLetterBodyResponse = {
  relyingParty: {
    coverLetterBody: string;
  };
};

export const GET_COVER_LETTER_BODY = gql`
  query GetRpmCoverLetterBody($relyingPartyId: ID!) {
    relyingParty(relyingPartyId: $relyingPartyId) {
      coverLetterBody
    }
  }
`;

export const GET_ACCOUNT_NOTE_BY_ID = gql`
  query GetAccountNotesById($truentityId: ID!, $noteId: String!) {
    getAccountNotesById(truentityId: $truentityId, noteId: $noteId) {
      title
      note
      modeOfCapture
      isArchived
      shareWithProvider
    }
  }
`;

export const GET_ACCOUNT_NOTES = gql`
  query GetAccountNotes($truentityId: ID!, $isArchived: Boolean, $pageNum: Int!, $pageSize: Int!) {
    getAccountNotes(truentityId: $truentityId, isArchived: $isArchived, pageNum: $pageNum, pageSize: $pageSize) {
      notes {
        id
        note
        title
        isArchived
        modeOfCapture
        shareWithProvider
        pinToProfile
        relyingPartyAdmin {
          name
        }
        createdAt
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_CARE_PLAN_VALUES = gql`
  query GetCarePlanValuesQuery {
    getCarePlanValues {
      conditions
      outcomes
      goals
      barriers
    }
  }
`;

export const GET_RPM_REVIEW_ACTIVITIES = gql`
  query GetRpmReviewActivitiesQuery($truentityId: ID!, $pageNum: Int!, $pageSize: Int!) {
    getRpmReviewActivities(truentityId: $truentityId, pageNum: $pageNum, pageSize: $pageSize) {
      careActivity {
        id
        title
        notes
        subType
        isEncounter
        totalTimeSpentSecs
        startedAt
        endedAt
        updatedAt
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export const GET_RPM_ACTIVITIES = gql`
  query GetRpmActivitiesQuery($truentityId: ID!, $monthYear: String!, $subType: String!, $pageNum: Int!, $pageSize: Int!) {
    getRpmActivities(truentityId: $truentityId, monthYear: $monthYear, subType: $subType, pageNum: $pageNum, pageSize: $pageSize) {
      setupTotalTime
      monthlyTotalTime
      careActivity {
        id
        title
        subTitle
        notes
        subType
        isEncounter
        startedAt
        endedAt
        totalTimeSpentSecs
        performedBy {
          user {
            firstName
            lastName
          }
        }
        contactMode
        updatedAt
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export const ADD_RPM_ACTIVITY = gql`
  mutation AddRpmActivity($careActivityInput: RpmCareActivityInput!) {
    addRpmActivity(careActivityInput: $careActivityInput) {
      status
      message
    }
  }
`;

export const UPDATE_RPM_ACTIVITY = gql`
  mutation UpdateRpmActivity($careActivityInput: RpmCareActivityInput!, $activityId: ID!) {
    updateRpmActivity(careActivityInput: $careActivityInput, activityId: $activityId) {
      status
      message
    }
  }
`;

export const DELETE_RPM_ACTIVITY = gql`
  mutation DeleteRpmActivity($truentityId: ID!, $activityId: ID!) {
    deleteRpmActivity(truentityId: $truentityId, activityId: $activityId) {
      status
      message
    }
  }
`;

export const GET_COMMUNICATION_LOGS = gql`
  query GetCommunicationLogs($truentityId: String, $filterOptions: ActivityLogsFilterOptionsInput) {
    getCommunicationLogs(truentityId: $truentityId, filterOptions: $filterOptions) {
      activityLogs {
        id
        activityTime
        activityType
        logText
        data
      }
    }
  }
`;

export type AddProviderResponse = {
  createProviders: {
    status: string;
    message: string;
  };
};

export const ADD_PROVIDER = gql`
  mutation CreateProviders(
    $firstName: String!
    $lastName: String!
    $npiNumber: String!
    $title: String
    $email: String
    $phone: String
    $fax: String
  ) {
    createProviders(
      firstName: $firstName
      lastName: $lastName
      npiNumber: $npiNumber
      title: $title
      email: $email
      phone: $phone
      fax: $fax
    ) {
      status
      message
    }
  }
`;

export const GET_ALL_PROVIDERS = gql`
  query GetAllProviders($pageSize: Int!, $pageNum: Int!) {
    getAllProviders(pageSize: $pageSize, pageNum: $pageNum) {
      providers {
        id
        individualFirstName
        individualMiddleName
        individualLastName
        email
        phone
        fax
        npiNumber
        title
        deaNumber
        stateLicense
        hasValidSession
        taxonomyCode
        contacts {
          type
          value
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export const GET_PROVIDERS_BY_FILTER = gql`
  query GetAllProviders($pageSize: Int!, $pageNum: Int!, $filterOptions: ProviderFilterOptionsInput!) {
    providersLookup(pageSize: $pageSize, pageNum: $pageNum, filterOptions: $filterOptions) {
      providers {
        id
        individualFirstName
        individualMiddleName
        individualLastName
        email
        phone
        fax
        npiNumber
        title
        deaNumber
        stateLicense
        taxonomyCode
        contacts {
          type
          value
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type GetProvidersByFilterResponse = {
  providersLookup: {
    providers: ProviderType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export type UpdateProviderResponse = {
  updateProvider: {
    status: string;
    errors: string[];
  };
  errors: string[];
};

export const UPDATE_PROVIDER = gql`
  mutation UpdateProvider($id: ID!, $input: UpdateProviderInput!) {
    updateProvider(id: $id, input: $input) {
      status
      errors
    }
  }
`;

export const UPDATE_ACCOUNT_PROVIDERS = gql`
  mutation UpdateAccountProviders($truentityId: ID!, $providerIds: [String!]!) {
    updateAccountProviders(truentityId: $truentityId, providerIds: $providerIds) {
      status
      message
    }
  }
`;

export const UPDATE_RELYING_PARTY_PROVIDERS = gql`
  mutation UpdateRelyingPartyProviders($providerIds: [String!]!) {
    updateRelyingPartyProviders(providerIds: $providerIds) {
      status
      message
    }
  }
`;

export const GET_ACCOUNT_VITALS_DATA_AVAILABILITY = gql`
  query GetAccountVitalsDataAvailabilityQuery($truentityId: String!) {
    getAccountVitalsDataAvailability(truentityId: $truentityId) {
      isBloodGlucoseDataAvailable
      isBloodPressureDataAvailable
      isHeartRateDataAvailable
    }
  }
`;

export const UPDATE_ACCOUNTS_PROVIDER_PRIMARY_CARE = gql`
  mutation UpdateProviderPrimaryCare($truentityId: ID!, $providerId: String!) {
    updateProviderPrimaryCare(truentityId: $truentityId, providerId: $providerId) {
      status
      message
    }
  }
`;

export type GetRpmReportsWithProviderSession = {
  getProviderSessionRpmReports: {
    providerActivities: RpmProviderActivitiesType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};
export type GetProviderSessionRpmReportResponse = {
  getProviderSessionRpmReport: RpmProviderActivitiesType;
};

export const GET_PROVIDER_SESSION_RPM_REPORTS = gql`
  query GetProviderSessionRpmReportsQuery(
    $authCode: String!
    $relyingPartyId: String!
    $filterOptions: RpmReportsFilterOptionsInput!
    $pageSize: Int!
    $pageNum: Int!
  ) {
    getProviderSessionRpmReports(
      authCode: $authCode
      filterOptions: $filterOptions
      pageSize: $pageSize
      pageNum: $pageNum
      relyingPartyId: $relyingPartyId
    ) {
      providerActivities {
        id
        status
        performedAt
        createdAt
        requestedAt
        rpmReport {
          id
          status
          monthYear
          reportStartAt
          reportEndAt
          reportedDocS3Key
          lastReportedOn
          account {
            id
            truentityId
            birthDate
            user {
              id
              email
              firstName
              lastName
              email
            }
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;
export const GET_PROVIDER_SESSION_RPM_REPORT = gql`
  query GetProviderSessionRpmReportQuery($authCode: String!, $providerActivityId: ID!) {
    getProviderSessionRpmReport(authCode: $authCode, providerActivityId: $providerActivityId) {
      id
      status
      performedAt
      createdAt
      rpmReport {
        id
        status
        monthYear
        reportStartAt
        reportEndAt
        reportedDocS3Key
        lastReportedOn
        account {
          id
          truentityId
          gender
          birthDate
          phone
          healthPlan {
            id
            orgName
          }
          user {
            id
            email
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export type UpdateProviderSessionRpmReportStatusResponse = {
  updateProviderSessionRpmReportStatus: {
    status: string;
    message: string;
  };
};

export const UPDATE_PROVIDER_SESSION_RPM_REPORT_STATUS = gql`
  mutation UpdateProviderSessionRpmReportStatus(
    $providerActivityId: String!
    $reviewStatus: String!
    $authCode: String!
    $comment: String
  ) {
    updateProviderSessionRpmReportStatus(
      providerActivityId: $providerActivityId
      reviewStatus: $reviewStatus
      authCode: $authCode
      comment: $comment
    ) {
      status
      message
    }
  }
`;

export type GetAccountDiagnosisResponseType = {
  getAccountDiagnoses: {
    diagnoses: Diagnosis[];
    meta?: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export const GET_ACCOUNT_DIAGNOSES = gql`
  query GetAccountDiagnosesQuery($truentityId: String!, $filterOptions: DiagnosesFilterOptionsInput, $pageSize: Int, $pageNum: Int) {
    getAccountDiagnoses(truentityId: $truentityId, filterOptions: $filterOptions, pageSize: $pageSize, pageNum: $pageNum) {
      diagnoses {
        id
        code
        name
        category
        system
      }
    }
  }
`;

type EnrolmentStats = {
  enrolledAccountsCount: number;
  unenrolledAccountsCount: number;
  candidateAccountsCount: number;
  scheduledForEnrollmentAccountsCount: number;
  scheduledForEnrollmentAccountsPercentage: number;
  enrolledAccountsPercentage: number;
  candidateAccountsPercentage: number;
  totalEnrolledAccountsCount: number;
  totalCandidateAccountsCount: number;
};

type ReadingsStats = {
  activeAccountsCount: number;
  inactiveAccountsCount: number;
  todayAccountsReadingsCount: number;
  monthlyReadingsBrackets: {
    name: string;
    count: number;
    percentage: number;
  }[];
};

type ActivitiesStats = {
  totalDevicesCount: number;
  totalUnreadAlertsCount: number;
  accountsDeviceTypeBrackets: {
    name: string;
    count: number;
    percentage: number;
  }[];
  accountsTimeSpentBrackets: {
    name: string;
    count: number;
    percentage: number;
  }[];
};

export type RpmStatisticsFilterOptionsInput = {
  organization: string;
  startDate?: string;
  endDate?: string;
};

export type RpmStatistics = {
  enrollmentStats: EnrolmentStats;
  readingsStats: ReadingsStats;
  activitiesStats: ActivitiesStats;
};
export type GetRpmStatisticsResponse = {
  getRpmStatistics: RpmStatistics;
};

export type GetRpmAccountsCountByStatusResponse = {
  getRpmAccountsCountByStatus: {
    count: number;
  };
};

export const GET_RPM_STATISTICS = gql`
  query GetRpmStatisticsQuery($filterOptions: RpmStatisticsFilterOptionsInput!) {
    getRpmStatistics(filterOptions: $filterOptions) {
      enrollmentStats {
        enrolledAccountsCount
        candidateAccountsCount
        scheduledForEnrollmentAccountsCount
        scheduledForEnrollmentAccountsPercentage
        enrolledAccountsPercentage
        candidateAccountsPercentage
        totalEnrolledAccountsCount
        totalCandidateAccountsCount
      }
      readingsStats {
        activeAccountsCount
        inactiveAccountsCount
        todayAccountsReadingsCount
        monthlyReadingsBrackets {
          name
          count
          percentage
        }
      }
      activitiesStats {
        totalDevicesCount
        totalUnreadAlertsCount
        accountsTimeSpentBrackets {
          name
          count
          percentage
        }
        accountsDeviceTypeBrackets {
          name
          count
          percentage
        }
      }
    }
  }
`;

export const REFRESH_RPM_STATISTICS = gql`
  mutation RefreshRpmStatisticsSnapshot($filterOptions: RpmStatisticsFilterOptionsInput!) {
    refreshRpmStatisticsSnapshot(filterOptions: $filterOptions) {
      status
      message
    }
  }
`;

export const GET_RPM_ACCOUNTS_COUNT_BY_STATUS = gql`
  query GetRpmAccountsCountByStatusQuery($status: String!) {
    getRpmAccountsCountByStatus(status: $status) {
      count
    }
  }
`;

export type RpmSetupTypes = {
  id: string;
  type: string;
  status: string;
  isRequiredForEnrollment: boolean;
  isManualStatusUpdate: boolean;
  createdAt: string;
  canCurrentTabBeCompleted: boolean;
  isDisabled: boolean;
  isReadOnly: boolean;
  shouldRefreshTab?: boolean;
};

export type GetRpmSetupResponse = {
  getRpmSetup: RpmSetupTypes[];
};

export const GET_RPM_SETUPS = gql`
  query GetRpmSetupQuery($truentityId: String!) {
    getRpmSetup(truentityId: $truentityId) {
      id
      type
      status
      isRequiredForEnrollment
      isManualStatusUpdate
      createdAt
    }
  }
`;

export type CreateRpmSetupResponse = {
  createRpmSetup: {
    status: string;
    message: string[];
    rpmSetups: RpmSetupTypes[];
  };
};

export const CREATE_RPM_SETUPS = gql`
  mutation CreateRpmSetup($truentityId: ID!, $rpmSetupValues: [RpmSetupValuesInput!]!) {
    createRpmSetup(truentityId: $truentityId, rpmSetupValues: $rpmSetupValues) {
      status
      message
      rpmSetups {
        id
        type
        status
        isRequiredForEnrollment
        isManualStatusUpdate
        createdAt
      }
    }
  }
`;

export type UpdateRpmSetupResponse = {
  updateRpmSetup: {
    status: string;
    message: string;
    rpmSetups: RpmSetupTypes;
  };
};

export const UPDATE_RPM_SETUP = gql`
  mutation UpdateRpmSetup($rpmSetupId: String!, $status: String, $isRequiredForEnrollment: Boolean, $isManualStatusUpdate: Boolean) {
    updateRpmSetup(
      rpmSetupId: $rpmSetupId
      status: $status
      isRequiredForEnrollment: $isRequiredForEnrollment
      isManualStatusUpdate: $isManualStatusUpdate
    ) {
      status
      message
      rpmSetup {
        id
        type
        status
        isRequiredForEnrollment
        isManualStatusUpdate
        createdAt
      }
    }
  }
`;

export type GetAccountAlertLimitsResponse = {
  getAccountAlertLimitsConfigurations: AlertLimitsType;
};

export const GET_ACCOUNT_ALERT_LIMITS = gql`
  query GetAccountAlertLimitsConfigurations($truentityId: String!) {
    getAccountAlertLimitsConfigurations(truentityId: $truentityId) {
      bloodGlucose {
        cLowValue
        lowValue
        highValue
        cHighValue
      }
      heartRate {
        cLowValue
        lowValue
        highValue
        cHighValue
      }
      sysBloodPressure {
        cLowValue
        lowValue
        highValue
        cHighValue
      }
      diaBloodPressure {
        cLowValue
        lowValue
        highValue
        cHighValue
      }
    }
  }
`;

export type UpdateAccountAlertLimitsResponse = {
  updateAccountAlertLimits: {
    accountClinicalLevels: {
      bloodGlucose: AlertLimitType;
      heartRate: AlertLimitType;
      sysBloodPressure: AlertLimitType;
      diaBloodPressure: AlertLimitType;
    };
    status: string;
    errors: string[];
  };
};

export const UPDATE_ACCOUNT_ALERT_LIMITS = gql`
  mutation UpdateAccountAlertLimits($truentityId: ID!, $alertLimitsInput: AccountAlertLimitsInput!) {
    updateAccountAlertLimits(truentityId: $truentityId, alertLimitsInput: $alertLimitsInput) {
      accountClinicalLevels {
        bloodGlucose {
          cLowValue
          lowValue
          highValue
          cHighValue
        }
        heartRate {
          cLowValue
          lowValue
          highValue
          cHighValue
        }
      }
      status
      errors
    }
  }
`;
export type MonthlyResponse = {
  monthYearName: string;
  report: RpmReportsType;
};
export type GetRpmReportsMonthlyResponse = {
  getRpmReportsMonthly: MonthlyResponse[];
};

export const GET_RPM_REPORTS_MONTHLY = gql`
  query GetRpmReportsMonthlyQuery($truentityId: ID!) {
    getRpmReportsMonthly(truentityId: $truentityId) {
      monthYearName
      report {
        id
        status
        reportedDocS3Key
      }
    }
  }
`;

export type reviewRpmReportResponse = {
  reviewRpmReport: {
    status: string;
    message: string;
  };
};
export const REVIEW_RPM_REPORT = gql`
  mutation ReviewRpmReport($rpmReportId: String!, $reviewSubmitType: ReviewSubmitTypeEnum!) {
    reviewRpmReport(rpmReportId: $rpmReportId, reviewSubmitType: $reviewSubmitType) {
      status
      message
    }
  }
`;
export type RegenerateRpmReportResponse = {
  regenerateRpmReports: {
    status: string;
    message: string;
  };
};

export const REGENERATE_RPM_REPORT = gql`
  mutation RegenerateRpmReports($reportIds: [ID!]!) {
    regenerateRpmReports(reportIds: $reportIds) {
      status
      message
    }
  }
`;

export type GetReportAvailabilitySenderMethodAvailability = {
  getRpmReportMethodAvailability: {
    jsonMessage: string;
  };
};

export const GET_RPM_REPORT_SENDER_METHOD_AVAILABILITY = gql`
  query GetRpmReportMethodAvailabilityQuery($truentityId: ID!) {
    getRpmReportMethodAvailability(truentityId: $truentityId) {
      jsonMessage
    }
  }
`;

export type SendOtpMailToProviderResponse = {
  sendOtpMailToProvider: {
    status: string;
    message: string;
  };
};

export const SEND_OTP_MAIL_TO_PROVIDER = gql`
  mutation SendOtpMailToProvider($providerId: String!, $providerOtpOptions: OtpOptionsInput!) {
    sendOtpMailToProvider(providerId: $providerId, providerOtpOptions: $providerOtpOptions) {
      status
      message
    }
  }
`;

export const GET_CLAIMS = gql`
  query GetRpmClaimsQuery($monthYear: String!, $pageNum: Int!, $pageSize: Int!, $isExport: Boolean!, $all: Boolean) {
    getRpmClaims(monthYear: $monthYear, pageNum: $pageNum, pageSize: $pageSize, isExport: $isExport, all: $all) {
      billingAccounts {
        id
        relyingParty {
          id
          name
        }
        account {
          accountsMonitoringDevices {
            externalPatientId
            status
          }
          rpmEnrolledAt
          rpmStatus
          birthDate
          truentityId
          user {
            firstName
            lastName
          }
        }
        ehrId
        description
        diagnosesCodes
        diagnosesNames
        cptCode
        recordedAt
        dateOfService
        provider {
          name
          npiNumber
        }
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export type DeleteVitalsReadingResponse = {
  deleteAccountVitalsReading: {
    status: string;
  };
};

export const DELETE_ACCOUNT_VITALS_READING = gql`
  mutation DeleteAccountVitalsReading($id: ID!) {
    deleteAccountVitalsReading(id: $id) {
      status
    }
  }
`;

export const GET_RELYING_PARTY_ALERT_LIMITS = gql`
  query GetRelyingPartyAlertLimitsConfigurations($relyingPartyId: String!) {
    getRelyingPartyAlertLimitsConfigurations(relyingPartyId: $relyingPartyId) {
      bloodGlucose {
        cLowValue
        lowValue
        highValue
        cHighValue
      }
      heartRate {
        cLowValue
        lowValue
        highValue
        cHighValue
      }
      sysBloodPressure {
        cLowValue
        lowValue
        highValue
        cHighValue
      }
      diaBloodPressure {
        cLowValue
        lowValue
        highValue
        cHighValue
      }
    }
  }
`;

export type GetRelyingPartyAlertLimitsResponse = {
  getRelyingPartyAlertLimitsConfigurations: AlertLimitsType;
};

export const UPDATE_RELYING_PARTY_ALERT_LIMITS = gql`
  mutation UpdateRelyingPartyAlertLimits($relyingPartyId: ID!, $submitType: SubmitTypeEnum!, $alertLimitsInput: AccountAlertLimitsInput!) {
    updateRelyingPartyAlertLimits(relyingPartyId: $relyingPartyId, submitType: $submitType, alertLimitsInput: $alertLimitsInput) {
      relyingPartyClinicalLevels {
        bloodGlucose {
          cLowValue
          lowValue
          highValue
          cHighValue
        }
        heartRate {
          cLowValue
          lowValue
          highValue
          cHighValue
        }
      }
      status
      message
    }
  }
`;

export type UpdateRelyingPartyAlertLimitsResponse = {
  updateRelyingPartyAlertLimits: {
    relyingPartyClinicalLevels: {
      bloodGlucose: AlertLimitType;
      heartRate: AlertLimitType;
      sysBloodPressure: AlertLimitType;
      diaBloodPressure: AlertLimitType;
    };
    status: string;
    message: string;
  };
};

export type GetProviderRelyingPartiesResponse = {
  getProviderSessionRelyingParties: {
    relyingParties: {
      id: string;
      name: string;
    }[];
  };
};

export const GET_PROVIDER_SESSION_RELYING_PARTIES = gql`
  query GetProviderSessionRelyingParties($authCode: String!) {
    getProviderSessionRelyingParties(authCode: $authCode) {
      relyingParties {
        id
        name
      }
    }
  }
`;

export type RpmDeviceDetails = {
  id: string;
  name: string;
  status: string;
  deviceBrand: string;
  deviceType: string;
  isDeviceUsedByAccountOwner: boolean;
  isDeviceUsedByOtherUsers: boolean;
};

export type GetDeviceDetailsByGatewayIdTypes = {
  getDeviceDetailsByGatewayId: RpmDeviceDetails[];
};

export const GET_DEVICE_DETAILS_BY_GATEWAY_ID = gql`
  query GetDeviceDetailsByGatewayId($gatewayId: String!, $truentityId: ID!, $filterDeviceTypes: [String!]) {
    getDeviceDetailsByGatewayId(gatewayId: $gatewayId, truentityId: $truentityId, filterDeviceTypes: $filterDeviceTypes) {
      id
      name
      status
      deviceBrand
      deviceType
      isDeviceUsedByAccountOwner
      isDeviceUsedByOtherUsers
    }
  }
`;

export type GetRpmReportByAccountResponse = {
  getRpmReportByAccount: Partial<RpmReportsType>;
};

export const GET_RPM_REPORT_BY_ACCOUNT = gql`
  query GetRpmReportByAccount($truentityId: ID!, $monthYear: String) {
    getRpmReportByAccount(truentityId: $truentityId, monthYear: $monthYear) {
      id
      monthYear
      reportedDocS3Key
      account {
        user {
          firstName
          lastName
        }
      }
    }
  }
`;

export type ReviewAccountsRpmReportsResponse = {
  reviewAccountsRpmReports: {
    status: string;
    message: string;
  };
};

export const REVIEW_ACCOUNTS_RPM_REPORTS = gql`
  mutation ReviewAccountsRpmReports($truentityIds: [String!]!, $reviewSubmitType: ReviewSubmitTypeEnum!, $monthYear: String!) {
    reviewAccountsRpmReports(truentityIds: $truentityIds, reviewSubmitType: $reviewSubmitType, monthYear: $monthYear) {
      status
      message
    }
  }
`;

export type GetRpmReportsCommunicationsResponse = {
  getRpmReportCommunicationActivities: {
    rpmReportsCommunications: RpmReportsCommunicationType[];
    meta: {
      totalPages: number;
      totalCount: number;
    };
  };
};

export const GET_RPM_REPORTS_COMMUNICATIONS = gql`
  query GetRpmReportCommunicationActivities($rpmReportStatusType: RpmReportDailySendStatusTypeEnum!, $pageNum: Int!, $pageSize: Int!) {
    getRpmReportCommunicationActivities(rpmReportStatusType: $rpmReportStatusType, pageNum: $pageNum, pageSize: $pageSize) {
      rpmReportsCommunications {
        rpmReport {
          id
          createdAt
          updatedAt
          account {
            truentityId
            user {
              firstName
              lastName
              middleName
            }
          }
          lastReportedOn
          reportedDocS3Key
          statusUpdatedAt
          monthYear
          dailySendStatus
          reviewProviders {
            provider {
              id
              individualFirstName
              individualLastName
            }
            reportSendingContacts {
              type
              value
              status
            }
          }
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;

export type UpdateRpmReportsStatusesResponse = {
  updateRpmReportsStatuses: {
    status: string;
    message: string;
  };
};

export type UpdateRpmReportsStatusesVariables = {
  rpmReportIds: string[];
  rpmDailySendStatus: UpdateRpmReportStatusTypeEnum;
};

export const UPDATE_RPM_REPORTS_STATUSES = gql`
  mutation UpdateRpmReportsStatuses($rpmReportIds: [ID!]!, $rpmDailySendStatus: RpmReportDailySendStatusTypeEnum!) {
    updateRpmReportsStatuses(rpmReportIds: $rpmReportIds, rpmDailySendStatus: $rpmDailySendStatus) {
      status
      message
    }
  }
`;
export type AccountsGatewayHardwareType = {
  deviceId: string;
  hardwareId: string;
  gatewayId: string;
  formattedGatewayId: string;
  status: string;
  lastGatewayCheckingTime: string;
  signalStrength: string;
  deviceBrandName: string;
  deviceTypeName: string;
  deviceName: string;
  truentityId: string;
  birthDate: string;
  zipcode: string;
  rpmStatus: string;
  user: UserType;
  phone: string;
  latestReadings: {
    id: string;
    value: string;
    unit: string;
    recordedAt: string;
  }[];
};

export type GetAccountsByGatewayAndDeviceIdResponse = {
  getAccountsByGatewayDeviceId: {
    rpmAccounts: AccountsGatewayHardwareType[];
    meta: Meta;
  };
};

export const GET_ACCOUNTS_BY_GATEWAY_AND_DEVICE_ID = gql`
  query GetAccountsByGatewayDeviceId($pageNum: Int!, $pageSize: Int!, $id: String!) {
    getAccountsByGatewayDeviceId(pageNum: $pageNum, pageSize: $pageSize, id: $id) {
      rpmAccounts {
        deviceId
        hardwareId
        gatewayId
        formattedGatewayId
        signalStrength
        lastGatewayCheckingTime
        status
        truentityId
        birthDate
        zipcode
        rpmStatus
        phone
        deviceBrandName
        deviceTypeName
        deviceName
        user {
          firstName
          lastName
        }
        latestReadings {
          id
          value
          unit
          recordedAt
        }
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export type GetRpmReportsCountsResponse = {
  getRpmReportsCounts: {
    readyForSending: number;
    scheduledForSending: number;
    sent: number;
    failed: number;
  };
};

export const GET_RPM_REPORTS_COUNTS = gql`
  query GetRpmReportsCountsQuery {
    getRpmReportsCounts {
      readyForSending
      scheduledForSending
      sent
      failed
    }
  }
`;

export type GetRpmFollowupConsultsType = {
  getRpmFollowupConsults: {
    rpmFollowupConsults: RpmFollowupConsultType[];
  };
};

export const GET_RPM_FOLLOWUP_CONSULTS = gql`
  query GetRpmFollowupConsultsQuery($filterOptions: RpmFollowupConsultsFilterOptionsInput!) {
    getRpmFollowupConsults(filterOptions: $filterOptions) {
      rpmFollowupConsults {
        id
        consultAt
      }
    }
  }
`;

export type GetRpmFollowupConsultType = {
  getRpmFollowupConsult: RpmFollowupConsultType;
};

export const GET_RPM_FOLLOWUP_CONSULT = gql`
  query GetRpmFollowupConsultQuery($followupConsultId: String!) {
    getRpmFollowupConsult(followupConsultId: $followupConsultId) {
      id
      consultAt
      signOffAt
      reviewProvider {
        name
      }
      reportS3Key
      accountsFollowupReminder {
        id
        followUpOn
      }
      status
      statusUpdatedAt
      isTelehealthVisitLaunched
    }
  }
`;

export type GetAccountSmartGoalResponse = {
  getAccountSmartGoals: {
    accountGoals: AccountSmartGoalType[];
    meta: Meta;
  };
};

export const GET_ACCOUNT_SMART_GOALS = gql`
  query GetAccountSmartGoals($truentityId: ID!, $filterOptions: SmartGoalsFilterOptionsInput, $pageNum: Int, $pageSize: Int) {
    getAccountSmartGoals(truentityId: $truentityId, filterOptions: $filterOptions, pageNum: $pageNum, pageSize: $pageSize) {
      accountGoals {
        id
        goalContent
        status
        statusChangeAt
        isArchived
        rpmFollowupConsult {
          id
        }
        createdAt
        updatedAt
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export const UPDATE_ACCOUNT_SMART_GOAL = gql`
  mutation UpdateAccountSmartGoal($accountSmartGoalId: ID!, $status: AccountSmartGoalStatusEnum, $isArchived: Boolean) {
    updateAccountSmartGoal(accountSmartGoalId: $accountSmartGoalId, status: $status, isArchived: $isArchived) {
      status
      message
    }
  }
`;

export type GetGoalTemplatesResponse = {
  getAllTemplates: SmartGoalCategory[];
};

export const GET_GOAL_TEMPLATES = gql`
  query GetAllTemplatesQuery($categories: [AccountDiagnosisCategoryEnum!]) {
    getAllTemplates(categories: $categories) {
      category
      subCategory {
        categoryName
        templates {
          id
          templateName
          templateText
          category
          subCategory
          placeholders
          validationRules
        }
      }
    }
  }
`;

export const CREATE_ACCOUNT_SMART_GOAL = gql`
  mutation CreateAccountSmartGoal(
    $truentityId: ID!
    $goals: [AccountSmartGoalInput!]!
    $customGoals: [String!]
    $rpmFollowupConsultId: String
  ) {
    createAccountSmartGoal(
      truentityId: $truentityId
      goals: $goals
      customGoals: $customGoals
      rpmFollowupConsultId: $rpmFollowupConsultId
    ) {
      status
      message
    }
  }
`;

export const UPDATE_RPM_ACCOUNT_STATUS = gql`
  mutation UpdateRpmAccountStatus($truentityId: String!, $rpmStatus: String!) {
    updateRpmAccountStatus(truentityId: $truentityId, rpmStatus: $rpmStatus) {
      account {
        truentityId
        user {
          firstName
          lastName
        }
        healthPlan {
          id
          orgName
        }
        rpmEnrolledAt
        rpmUnenrolledAt
        onboardedAt
        rpmStatus
        setting
        rpmApprovalStatus
      }
    }
  }
`;

export const UPDATE_ORDER_CHECKED = gql`
  mutation UpdateOrderChecked($truentityId: ID!, $orderChecked: Boolean!) {
    updateOrderChecked(truentityId: $truentityId, orderChecked: $orderChecked) {
      accountSummary {
        id
        orderChecked
        orderDatetime
        orderPdf
      }
    }
  }
`;

export type UpdateRpmAccountStatusResponse = {
  updateRpmAccountStatus: {
    account: PatientsData;
  };
};
export const UPDATE_RPM_ACCOUNT_MONITORED_CONDITION = gql`
  mutation UpdateRpmAccountMonitoredCondition($truentityId: ID!, $conditions: [AccountDiagnosisCategoryEnum!]!) {
    updateRpmAccountMonitoredCondition(truentityId: $truentityId, conditions: $conditions) {
      status
      message
    }
  }
`;

export type ChangeAccountTelehealthVisitLaunchedFlagResponse = {
  changeAccountTelehealthVisitLaunchedFlag: {
    status: string;
    message: string;
    account: AccountType;
  };
};

export const CHANGE_ACCOUNT_TELEHEALTH_VISIT_LAUNCHED_FLAG = gql`
  mutation ChangeAccountTelehealthVisitLaunchedFlag($truentityId: ID!, $isAccountTelehealthVisitLaunched: Boolean!) {
    changeAccountTelehealthVisitLaunchedFlag(
      truentityId: $truentityId
      isAccountTelehealthVisitLaunched: $isAccountTelehealthVisitLaunched
    ) {
      status
      message
      account {
        accountSummary {
          isTelehealthVisitLaunched
        }
      }
    }
  }
`;

export const GET_ACCOUNT_ORDERS = gql`
  query GetAccountOrders($truentityId: ID!) {
    getAccountOrders(truentityId: $truentityId) {
      orderChecked
      orderDatetime
      orderPdf
      orderType
    }
  }
`;

export type GetAccountOrdersResponse = {
  getAccountOrders: AccountOrderType[];
};

export const UPDATE_ACCOUNT_ORDER = gql`
  mutation UpdateAccountOrder($truentityId: ID!, $orderChecked: Boolean!, $orderType: String!) {
    updateAccountOrder(truentityId: $truentityId, orderChecked: $orderChecked, orderType: $orderType) {
      accountOrder {
        orderChecked
        orderDatetime
        orderPdf
        orderType
      }
    }
  }
`;

export type UpdateAccountOrderResponse = {
  updateAccountOrders: AccountOrderType;
};

export const GET_RPM_KPI_STATISTICS_QUERY = gql`
  query GetRpmKpiStatisticsQuery {
    getRpmKpiStatistics {
      criticallyHigh
      criticallyLow
      medRegimenCount
      noReadings
      enrolledThisMonth
      enrolledYesterday
      enrolledTotal
      readingsCategories {
        name
        value
        accounts {
          truentityId
          user {
            firstName
            lastName
          }
        }
      }
      treatmentsCategories {
        name
        value
        accounts {
          truentityId
          user {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export interface ReadingCategory {
  name: string;
  value: number;
  accounts?: AccountType[];
}

export type GetRpmKpiStatisticsQueryResponse = {
  getRpmKpiStatistics: {
    criticallyHigh: number;
    criticallyLow: number;
    medRegimenCount: number;
    noReadings: number;
    enrolledThisMonth: number;
    enrolledYesterday: number;
    enrolledTotal: number;
    highReadings: number;
    lowReadings: number;
    medReadings: number;
    readingsCategories: Required<ReadingCategory>[];
    treatmentsCategories: Required<ReadingCategory>[];
  };
};

export type ActiveRelyingPartyAdminSubscriptionResponse = {
  relyingPartyAdminEngagementTracking: {
    adminsWorkingOnPatientActivity: ActivityLogType[];
  };
};

export const ON_RELYING_PARTY_ADMIN_ACTIVITY_ENGAGEMENT_TRACKER = gql`
  subscription OnRelyingPartyAdminActivityEngagementTracker($truentityId: String!) {
    relyingPartyAdminEngagementTracking(truentityId: $truentityId) {
      adminsWorkingOnPatientActivity {
        id
        logText
        activityType
        relyingPartyAdmin {
          id
          roleType
          user {
            firstName
            lastName
            provider {
              title
            }
          }
        }
      }
    }
  }
`;

export type PatientSetupDataUpdateNotificationResponse = {
  account: AccountType;
  updatedBy: RelyingPartyAdminType;
  affectedTab: string;
  action: string;
  lastUpdatedTime: string;
};

export const ON_PATIENT_SETUP_DATA_CHANGE = gql`
  subscription OnPatientSetupDataChange($truentityId: ID!, $rpmSetupType: String!) {
    patientSetupDataUpdateNotification(truentityId: $truentityId, rpmSetupType: $rpmSetupType) {
      affectedTab
      action
      lastUpdatedTime
    }
  }
`;

export const GET_RPM_MONTHLY_REPORTS_FOR_REVIEW = gql`
  query GetRpmReportsMonthlyQuery($truentityId: ID!, $includeCurrentMonth: Boolean) {
    getRpmReportsMonthly(truentityId: $truentityId, includeCurrentMonth: $includeCurrentMonth) {
      monthYearName
      report {
        id
        status
        reportedDocS3Key
        rpmAccountReviewWorkflow {
          id
          signOffAt
        }
      }
    }
  }
`;

export type GetRpmAccountReviewWorkflowResponse = {
  getRpmAccountReviewWorkflow: PatientReviewWorkflowType;
};

export const GET_PATIENT_RPM_REVIEW_WORKFLOW = gql`
  query GetRpmAccountReviewWorkflow($accountReviewWorkflowId: ID!) {
    getRpmAccountReviewWorkflow(accountReviewWorkflowId: $accountReviewWorkflowId) {
      signOffAt
      completedAt
    }
  }
`;

export const START_PATIENT_REVIEW_WORKFLOW = gql`
  mutation CreateAccountReviewWorkflow($rpmReportId: ID!) {
    createAccountReviewWorkflow(rpmReportId: $rpmReportId) {
      status
      message
    }
  }
`;

export const SAVE_PATIENT_REVIEW_NOTE = gql`
  mutation CreateAccountReviewNote($accountReviewWorkflowId: ID!, $note: String!, $accountReviewNoteId: ID, $title: String) {
    createAccountReviewNote(
      accountReviewWorkflowId: $accountReviewWorkflowId
      note: $note
      accountReviewNoteId: $accountReviewNoteId
      title: $title
    ) {
      status
      message
    }
  }
`;

export type RpmAccountReviewWorkflowNotesResponse = {
  rpmAccountReviewWorkflowNotes: {
    rpmAccountReviewNotes: PatientReviewNoteType[];
  };
};

export const RPM_ACCOUNT_REVIEW_WORKFLOW_NOTES = gql`
  subscription RpmAccountReviewWorkflowNotes($accountReviewWorkflowId: ID!) {
    rpmAccountReviewWorkflowNotes(accountReviewWorkflowId: $accountReviewWorkflowId) {
      rpmAccountReviewNotes {
        id
        title
        note
        authorType
        relyingPartyAdmin {
          id
          presignedAvatarUrl
          user {
            firstName
            lastName
          }
        }
        replies {
          id
          title
          note
          authorType
          relyingPartyAdmin {
            id
            presignedAvatarUrl
            user {
              firstName
              lastName
            }
          }
          createdAt
        }
        createdAt
      }
    }
  }
`;

export const DELETE_REVIEW_NOTE = gql`
  mutation DeleteAccountReviewNote($accountReviewNoteId: ID!) {
    deleteAccountReviewNote(accountReviewNoteId: $accountReviewNoteId) {
      status
      message
    }
  }
`;

export const UPDATE_ACCOUNT_REVIEW_WORKFLOW_STATUS = gql`
  mutation UpdateAccountReviewWorkflowStatus($accountReviewWorkflowId: ID!, $reviewType: String!) {
    updateAccountReviewWorkflowStatus(accountReviewWorkflowId: $accountReviewWorkflowId, reviewType: $reviewType) {
      status
      message
    }
  }
`;

export const UPDATE_ACCOUNT_REVIEW_NOTE = gql`
  mutation UpdateAccountReviewNote($accountReviewNoteId: ID!, $note: String!, $title: String) {
    updateAccountReviewNote(accountReviewNoteId: $accountReviewNoteId, note: $note, title: $title) {
      status
      message
    }
  }
`;

export type RelyingPartyAdminFollowUpResponse = {
  relyingPartyFollowups: { accountFollowups: FollowUpRemindersTypes[]; meta?: MetaDataType };
};

export const CURRENT_RELYING_PARTY_ADMIN_FOLLOWUP_ID = gql`
  query RelyingPartyFollowupsQuery($pageNum: Int, $pageSize: Int, $filterOptions: AccountFollowupFilterOptionsInput) {
    relyingPartyFollowups(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accountFollowups {
        id
      }
    }
  }
`;

export const CURRENT_RELYING_PARTY_ADMIN_FOLLOWUPS = gql`
  query RelyingPartyFollowupsQuery($pageNum: Int, $pageSize: Int, $filterOptions: AccountFollowupFilterOptionsInput) {
    relyingPartyFollowups(pageNum: $pageNum, pageSize: $pageSize, filterOptions: $filterOptions) {
      accountFollowups {
        id
        followUpOn
        type
        notes
        status
        account {
          truentityId
          rpmEnrolledAt
          user {
            firstName
            lastName
          }
        }
        createdAt
        category
        rpmAlert {
          id
          label
          reading
          recordedAt
          description
        }
        relyingPartyAdmin {
          id
          user {
            firstName
            lastName
          }
        }
        createdByRelyingPartyAdmin {
          user {
            firstName
            lastName
          }
        }
      }
      meta {
        totalCount
        totalPages
      }
    }
  }
`;

export type GetRpmMonthlyReviewAccountsResponse = {
  getRpmMonthlyReviewAccounts: {
    accounts: Partial<PatientsData>[];
    meta: MetaDataType;
  };
};

export const GET_RPM_MONTHLY_REVIEW_ACCOUNTS = gql`
  query GetRpmMonthlyReviewAccounts($filterOptions: RpmMonthlyReviewAccountsInput, $pageNum: Int, $pageSize: Int) {
    getRpmMonthlyReviewAccounts(filterOptions: $filterOptions, pageNum: $pageNum, pageSize: $pageSize) {
      accounts {
        id
        truentityId
        phone
        gender
        numCriticalAlerts
        rpmAccountReportReviewStatus
        rpmStatus
        rpmStatusUpdatedAt
        rpmApprovalStatus
        onboardedAt
        healthPlan {
          orgName
        }
        birthDate
        zipcode
        rpmEnrolledAt
        user {
          firstName
          lastName
        }
      }
      meta {
        totalPages
        totalCount
      }
    }
  }
`;
