import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { FollowupFormModeTypes } from '@/components/Dialogs/FollowUpAddEditDialog';
import FollowUpTaskAddEditDialog from '@/components/Dialogs/FollowUpTaskAddEditDialog';
import FollowUpTaskInfoDialog from '@/components/Dialogs/FollowUpTaskInfoDialog';
import MainSideMenu from '@/components/SideMenus/MainSideMenu';
import { H1 } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetFilteredRelyingPartyAdminsResponse } from '@/graphql/account';
import {
  FollowUpCategoryTypes,
  FollowUpStatusTypes,
  FollowUpTaskTypes,
  FollowUpTypes,
  GET_FILTERED_RELYING_PARTY_ADMINS
} from '@/graphql/account';
import type { RelyingPartyAdminFollowUpResponse } from '@/graphql/remotePatientMonitoring';
import { CURRENT_RELYING_PARTY_ADMIN_FOLLOWUPS } from '@/graphql/remotePatientMonitoring';
import type { FollowUpRemindersTypes } from '@/types/accountProfile';
import { getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { formatDate } from '@/util/format';
import { getAlertTypeStyles } from '@/util/rpm';
import { unknown } from '@/util/string';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { ErrorOutlineRounded } from '@mui/icons-material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Chip, IconButton, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { RelyingPartyAdminData } from '../Administration';

const FollowUpsTask = () => {
  const { showModal } = useModal();
  const currentUser = useReactiveVar(currentLoggedUserVar);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const { setContent, setDrawerOpen } = useContext(SideMenuContext);

  const {
    data: currentAdminFollowUps,
    loading: isCurrentAdminFollowUpsLoading,
    refetch: refetchCurrentAdminFollowUp
  } = useQuery<RelyingPartyAdminFollowUpResponse>(CURRENT_RELYING_PARTY_ADMIN_FOLLOWUPS, {
    variables: {
      pageNum: currentPage,
      pageSize: DEFAULT_PAGE_SIZE,
      filterOptions: {
        relyingPartyAdminId: currentUser?.id || 'all',
        status: FollowUpStatusTypes.PENDING,
        category: [FollowUpCategoryTypes.ALERT_REVIEW, FollowUpCategoryTypes.CHART_REVIEW]
      }
    },
    fetchPolicy: 'cache-and-network'
  });

  const [triggerRelyingPartyAdminData] = useLazyQuery<GetFilteredRelyingPartyAdminsResponse>(GET_FILTERED_RELYING_PARTY_ADMINS, {
    variables: {
      pageNum: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      excludedRoles: ['SUPER_ADMIN']
    }
  });

  const handleCheckButton = useCallback(
    (data: FollowUpRemindersTypes) => {
      const { hide } = showModal(FollowUpTaskInfoDialog, {
        title: 'Follow-up Task Information',
        hideDialog: () => hide(),
        followUpData: data,
        category: data.category,
        onCompleted: () => {
          hide();
          refetchCurrentAdminFollowUp();
        }
      });
    },
    [refetchCurrentAdminFollowUp, showModal]
  );

  const handleEditButton = useCallback(
    async (followUpData: FollowUpRemindersTypes) => {
      const response = await triggerRelyingPartyAdminData();
      const relyingPartyAdmins = (response.data?.filteredRelyingPartyAdmins?.relyingPartyAdmins || []) as RelyingPartyAdminData[];

      const modal = showModal(FollowUpTaskAddEditDialog, {
        title: 'Edit Alert Review Task',
        accountAssignees: relyingPartyAdmins,
        category: FollowUpCategoryTypes.CHART_REVIEW,
        hideDialog: () => modal.hide(),
        onUpdated: () => {
          modal.hide();
          refetchCurrentAdminFollowUp();
        },
        formMode: FollowupFormModeTypes.UPDATE,
        followUpTaskData: followUpData
      });
    },
    [refetchCurrentAdminFollowUp, showModal, triggerRelyingPartyAdminData]
  );

  const renderAlertLabelChip = useCallback((value: string) => {
    const alertTypeStyles = getAlertTypeStyles(value);

    if (alertTypeStyles?.labelColor) {
      return (
        <Chip
          icon={
            <ErrorOutlineRounded
              sx={{
                color: `${alertTypeStyles?.labelColor} !important`
              }}
            />
          }
          variant="outlined"
          label={alertTypeStyles?.labelText}
          sx={{
            borderColor: alertTypeStyles?.labelColor,
            color: alertTypeStyles?.labelColor
          }}
        />
      );
    }
  }, []);

  const columns: GridColDef<FollowUpRemindersTypes>[] = useMemo(
    () => [
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => getAccountUserFullName(params.row?.account?.user)
      },
      {
        field: 'Task Type',
        headerName: 'Task Type',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => {
          if (params.row.type === FollowUpTypes.CALL_PATIENT) {
            return FollowUpTaskTypes.CONTACT_PATIENT;
          }
          return params.row.type;
        }
      },
      {
        field: 'label',
        headerName: 'Alert Type',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => params.row.rpmAlert?.label || unknown(),
        renderCell: params => renderAlertLabelChip(params.value)
      },
      {
        field: 'taskDateAndTime',
        headerName: 'Task Due at',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => formatDate(params.row.followUpOn)
      },
      {
        field: 'assignedBy',
        headerName: 'Assigned By',
        sortable: true,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        valueGetter: params => getAccountUserFullName(params.row?.createdByRelyingPartyAdmin?.user)
      },
      {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => (
          <Stack direction="row">
            <IconButton onClick={() => handleCheckButton(params.row)}>
              <TaskAltIcon color="info" />
            </IconButton>
            <IconButton onClick={() => handleEditButton(params.row)}>
              <ModeEditIcon />
            </IconButton>
          </Stack>
        )
      }
    ],
    [handleCheckButton, handleEditButton, renderAlertLabelChip]
  );

  useEffect(() => {
    setContent(<MainSideMenu />);
  }, [setContent, setDrawerOpen]);

  return (
    <Stack spacing={1}>
      <Stack
        component={Paper}
        spacing={2}
        direction="row"
        alignItems="center"
        elevation={0}
        sx={{
          padding: 3,
          borderRadius: '8px'
        }}
      >
        <H1
          sx={{
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '32px',
            flex: 1,
            textAlign: 'left'
          }}
        >
          My Follow-up Tasks
        </H1>
      </Stack>

      <Stack sx={{ pt: 2 }}>
        <TruentityDataGrid
          name={'dg-follow-up-task'}
          autoHeight
          rows={currentAdminFollowUps?.relyingPartyFollowups?.accountFollowups || []}
          columns={columns}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          loading={isCurrentAdminFollowUpsLoading || false}
          rowCount={currentAdminFollowUps?.relyingPartyFollowups?.meta?.totalCount || 0}
          paginationMode="server"
          rowSelection={false}
        />
      </Stack>
    </Stack>
  );
};

export default FollowUpsTask;
