import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import { MedRecDialog } from '@/components/Dialogs';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import DropdownMenu from '@/components/DropdownMenu';
import LoadingOverlay from '@/components/LoadingOverlay';
import MedicationTypeIcon from '@/components/MedicationTypeIcon';
import MuiMenu from '@/components/MuiMenu';
import { Body1, Body2 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import TriageContext from '@/context/triageContext';
import { ASSIGN_MEDREC_TO_RPM_REPORT } from '@/graphql/remotePatientMonitoring';
import useMedication from '@/hooks/useMedication';
import { theme } from '@/styles/mui-theme';
import { type Medication, type MedicationVerificationStatus } from '@/types/medication';
import type { MedicationStatus } from '@/types/triage-medication-types';
import { buildRenderValue, isCurrent, isVerified } from '@/util/medications';
import { localeCompare } from '@/util/sort';
import { parseIsCurrentFromColumnTitle } from '@/util/triage';
import { useMutation } from '@apollo/client';
import { Paper, Stack, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import type { DataGridProProps, GridColDef } from '@mui/x-data-grid-pro';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { dateValueFormatter, handleUndefinedFormatter } from './datagrid-formatters';
import TriageActions from './triageActions';
import type { SelectableFilter } from './triageFilters';
import TriageFilters from './triageFilters';

export const AdherenceKeysToPropMap = {
  'taken-as-directed': 'isTakenAsDirected',
  'no-longer-taking': 'isNoLongerTaking',
  'taking-differently': 'isTakingDifferently',
  'unable-to-verify': 'isUnableToVerify'
};

function StyledDataGrid({ ...props }: DataGridProProps) {
  return (
    <Box
      sx={{
        '& .verified': {
          color: theme.palette.success.dark
        },
        '& .unverified': {
          color: theme.palette.error.dark
        }
      }}
    >
      <TruentityDataGrid name={'dg-triage-medication-list'} {...props} />
    </Box>
  );
}

const TriageMedicationList = () => {
  const gridRef = useGridApiRef();
  const { showModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { handleVerifyMedications, verifyMedicationsLoading } = useMedication();
  const { id } = useParams();

  const { triageMedications, changeMedicationStatuses, updateMedications, medicationSelected, verifyMedications } =
    useContext(TriageContext);
  const { reloadMeds } = useContext(PatientDetailContext);

  const [selectedMedications, setSelectedMedications] = useState<Medication[]>([]);
  const [rowData, setRowData] = useState<Medication[]>([]);
  const [pagingSize, setPagingSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(0);
  const verificationStatusFlag = useRef(false);

  const [assignMedRecSnapshot] = useMutation(ASSIGN_MEDREC_TO_RPM_REPORT);

  const initialFilter = useMemo(
    () => ({
      category: 'medication-status',
      value: 'Current Meds'
    }),
    []
  );

  const [selectedFilter, setSelectedFilter] = useState<SelectableFilter>(initialFilter);

  const onRowDoubleClick = data => {
    medicationSelected(data.row);
  };

  const unverifiedMedsThatAreNoLongerCurrent = (meds: Medication[], isCurrent: boolean) => {
    const notCurrentAndVerified: MedicationVerificationStatus[] = meds
      .filter((m: Medication) => isVerified(m) && m.isCurrent && !isCurrent)
      .map((med: Medication) => ({ id: med.id, isVerified: false } as MedicationVerificationStatus));
    if (notCurrentAndVerified.length > 0) {
      verifyMedications(notCurrentAndVerified);
    }
  };

  const changeMedsIsCurrent = (isCurrent: boolean) => {
    unverifiedMedsThatAreNoLongerCurrent(selectedMedications, isCurrent);
    const statuses: MedicationStatus[] = selectedMedications.map((medication: Medication) => ({
      id: medication.id,
      isCurrent
    })) as MedicationStatus[];
    changeMedicationStatuses(statuses);
  };

  const showVerifyAndLockAndCertifyConfirmationDialog = () => {
    const modalRef = showModal(ConfirmDialog, {
      title: 'Confirm Action',
      message:
        'This will mark the selected medications as Verified and others as Unverified. The verified medications will then be included in this MedRec. Are you sure?',
      onAgree: () => {
        verifyAndLockAndCertify();
        modalRef.hide();
      },
      onDisagree: () => modalRef.hide()
    });
  };

  const verifyAndLockAndCertify = useCallback(async () => {
    const selectedMedicationIds = selectedMedications.map(medication => medication.id);

    const getStatusObject = (medication: Medication, isVerified: boolean): MedicationVerificationStatus => ({
      id: medication.id as string,
      isVerified
    });

    const selectedMedicationStatuses: MedicationVerificationStatus[] = selectedMedications.map(medication =>
      getStatusObject(medication, true)
    );

    const isVerifiedNotSelectedMedications = triageMedications.filter(
      medication => medication.lastVerifiedById !== null && !selectedMedicationIds.includes(medication.id)
    );

    const verifiedNotSelectedMedicationStatuses: MedicationVerificationStatus[] = isVerifiedNotSelectedMedications.map(medication =>
      getStatusObject(medication, false)
    );

    const allMedications: MedicationVerificationStatus[] = [...selectedMedicationStatuses, ...verifiedNotSelectedMedicationStatuses];
    const status = await handleVerifyMedications(allMedications);

    if (status) {
      reloadMeds();
      verificationStatusFlag.current = true;
    }
  }, [triageMedications, selectedMedications]);

  const handleNewMedRec = useCallback(
    (success: boolean, truentityId: string, createdMedRecId = '', openAssignDialog = false) => {
      if (success) {
        enqueueSnackbar('MedRec Snapshot Created', {
          variant: 'success'
        });

        if (openAssignDialog) {
          showAssignMedRecSnapshotToRpmReportConfirmation(createdMedRecId, truentityId);
        }
      } else {
        enqueueSnackbar('MedRec Snapshot Failed', {
          variant: 'error'
        });
      }
    },
    [enqueueSnackbar]
  );

  const assignMedRecSnapshotToRPMReport = useCallback(
    async (medrecSnapshotId: string, id: string | undefined) => {
      return await assignMedRecSnapshot({
        variables: {
          truentityId: id,
          medrecSnapshotId
        }
      });
    },
    [assignMedRecSnapshot]
  );

  const showAssignMedRecSnapshotToRpmReportConfirmation = useCallback(
    (medrecSnapshotId: string, truentityId: string) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Assign Medication Reconciliation Snapshot',
        message: "Do you wish to make this MedRec the primary MedRec in this patient's RPM Setup?",
        onAgree: () => {
          assignMedRecSnapshotToRPMReport(medrecSnapshotId, truentityId).finally(() => {
            modal.hide();
          });
          enqueueSnackbar('MedRec Snapshot Assigned Successfully', {
            variant: 'success'
          });
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [assignMedRecSnapshotToRPMReport, showModal]
  );

  const showModalMedRec = useCallback(
    medications => {
      if (medications) {
        const verifiedMeds = [...medications].sort(localeCompare('displayName', false)).filter(v => isVerified(v) && isCurrent(v));
        const medRecModal = showModal(MedRecDialog, {
          truentityId: id,
          title: 'Certify Med Rec',
          verifiedMeds,
          handleNewMedRec,
          hideDialog: () => {
            medRecModal.hide();
          }
        });
      }
    },
    [handleNewMedRec, id, showModal]
  );

  const filterBy = useCallback(
    ({ category, value }: SelectableFilter) => {
      let meds = [...triageMedications];
      switch (category) {
        case 'adherence':
          {
            const key = AdherenceKeysToPropMap[value];
            meds = meds.filter(m => {
              return m[key] === true;
            });
          }

          break;

        case 'medication-status':
          meds = meds.filter(m => m.isCurrent === parseIsCurrentFromColumnTitle(value));
          break;

        case 'supplement':
          meds = meds.filter(m => m.isSupplement === (value === 'Yes'));
          break;

        case 'verified':
          meds = meds.filter(m => (isVerified(m) && value === 'Yes') || (!isVerified(m) && value === 'No'));
          break;
      }

      setRowData(meds);
    },
    [triageMedications]
  );

  useEffect(() => {
    if (verificationStatusFlag.current) {
      showModalMedRec(triageMedications);
      verificationStatusFlag.current = false;
    }
    verificationStatusFlag.current = false;
  }, [showModalMedRec, triageMedications]);

  const columns: GridColDef<Medication>[] = useMemo(
    () => [
      {
        field: 'displayName',
        headerName: 'Name',
        width: 250,
        filterable: true,
        sortable: true,
        cellClassName: params => {
          return isVerified(params.row) ? 'verified' : 'unverified';
        }
      },
      {
        field: 'isSupplement',
        headerName: '',
        headerAlign: 'center',
        align: 'center',
        width: 70,
        sortable: true,
        renderCell: params => {
          const iconType = params.value ? 'SUPPLEMENT' : 'PRESCRIPTION';
          return (
            <span style={{ width: '100%', textAlign: 'center', padding: '10px' }}>
              {<MedicationTypeIcon type={iconType}></MedicationTypeIcon>}
            </span>
          );
        }
      },
      // {
      //   field: 'lastVerifiedById',
      //   headerName: 'Verified',
      //   headerAlign: 'center',
      //   align: 'center',
      //   width: 90,
      //   sortable: true,

      //   renderCell: params => {
      //     const userIsVerified = isVerified(params.value);
      //     return userIsVerified ? <VerifiedUserIcon color={'success'} /> : <GppBadIcon color={'error'} />;
      //   }
      // },
      // {
      //   field: 'adherence',
      //   headerName: 'Adherence',
      //   headerAlign: 'center',
      //   align: 'center',
      //   width: 90,
      //   sortable: true,
      //   valueGetter: params => ({
      //     isTakenAsDirected: !!params.row.isTakenAsDirected,
      //     isTakingDifferently: !!params.row.isTakingDifferently,
      //     isNoLongerTaking: !!params.row.isNoLongerTaking,
      //     isUnableToVerify: !!params.row.isUnableToVerify
      //   }),
      //   renderCell: params => {
      //     switch (true) {
      //       case params.value.isTakenAsDirected:
      //         return <CheckCircleIcon color={'success'} />;

      //       case params.value.isTakingDifferently:
      //         return <CheckCircleIcon color={'warning'} />;

      //       case params.value.isNoLongerTaking:
      //         return <CancelIcon color={'error'} />;

      //       case params.value.isUnableToVerify:
      //         return <HelpIcon color={'info'} />;
      //     }

      //     return <RemoveCircleIcon color={'action'} />;
      //   },
      //   sortComparator: (v1, v2) => {
      //     const getWeight = v => {
      //       switch (true) {
      //         case v.isTakenAsDirected:
      //           return 0;

      //         case v.isTakingDifferently:
      //           return 1;

      //         case v.isNoLongerTaking:
      //           return 2;

      //         case v.isUnableToVerify:
      //           return 3;

      //         default:
      //           return 4;
      //       }
      //     };

      //     const a = getWeight(v1);
      //     const b = getWeight(v2);

      //     if (a === b) return 0;

      //     return a > b ? 1 : -1;
      //   }
      // },
      // {
      //   field: 'isCurrent',
      //   headerName: 'Status',
      //   headerAlign: 'center',
      //   align: 'center',
      //   minWidth: 100,
      //   flex: 1,
      //   filterable: true,
      //   sortable: true,
      //   valueFormatter: params => medStatusFormatter(params)
      // },
      {
        field: 'instructions',
        headerName: 'SIG',
        minWidth: 350,
        filterable: false,
        sortable: false,
        renderCell: params => (
          <Tooltip title={params.row.instructions} arrow>
            <Box sx={{ textTransform: 'capitalize' }}>{params.row.instructions ? params.row.instructions.toLowerCase() : '---'}</Box>
          </Tooltip>
        )
      },
      {
        field: 'strength',
        headerName: 'Strength',
        minWidth: 100,
        flex: 2,
        filterable: true,
        sortable: false,
        valueFormatter: params => buildRenderValue(params.value)
      },
      // {
      //   field: 'dosage',
      //   headerName: 'Dosage',
      //   minWidth: 100,
      //   flex: 2,
      //   filterable: true,
      //   sortable: false,
      //   valueFormatter: params => buildValueFormatter(params)
      // },
      {
        field: 'prescriptionWrittenDateAt',
        headerName: 'Date Written',
        minWidth: 110,
        flex: 1,
        filterable: true,
        sortable: true,
        valueFormatter: params => dateValueFormatter(params)
      },
      {
        field: 'lastFillDateAt',
        headerName: 'Fill Date',
        minWidth: 110,
        flex: 1,
        filterable: true,
        sortable: true,
        valueFormatter: params => dateValueFormatter(params)
      },
      {
        field: 'soldDateAt',
        headerName: 'Sold Date',
        minWidth: 110,
        flex: 1,
        filterable: true,
        sortable: true,
        valueFormatter: params => dateValueFormatter(params)
      },
      {
        field: 'numRefills',
        headerName: 'Refills',
        headerAlign: 'center',
        align: 'center',
        minWidth: 40,
        filterable: true,
        sortable: true,
        valueFormatter: params => handleUndefinedFormatter(params)
      },
      {
        field: 'daysSupply',
        headerName: 'Days',
        headerAlign: 'center',
        align: 'center',
        minWidth: 40,
        filterable: true,
        sortable: true,
        valueFormatter: params => handleUndefinedFormatter(params)
      },
      {
        field: 'prescriberName',
        headerName: 'Provider',
        minWidth: 150,
        flex: 1,
        filterable: true,
        sortable: true,
        valueFormatter: params => handleUndefinedFormatter(params)
      }
      // {
      //   field: 'pharmacyName',
      //   headerName: 'Pharmacy',
      //   minWidth: 100,
      //   flex: 1,
      //   filterable: true,
      //   sortable: true,
      //   valueFormatter: params => handleUndefinedFormatter(params)
      // }
    ],
    []
  );

  useEffect(() => {
    if (gridRef.current) {
      if (triageMedications?.length > 0) {
        gridRef.current.setRowSelectionModel([]);
        setSelectedFilter({ ...initialFilter });
      } else {
        setRowData([]);
      }
    }
  }, [gridRef, initialFilter, triageMedications]);

  useEffect(() => {
    filterBy(selectedFilter);
  }, [filterBy, selectedFilter]);

  return (
    <Stack spacing={2}>
      <LoadingOverlay text={'Verifying Medications...'} active={verifyMedicationsLoading} />
      <Stack direction="row" justifyContent={'flex-end'} spacing={1}>
        <Stack direction="row" alignItems={'center'} sx={{ width: '100%' }} spacing={2}>
          <TriageFilters
            defaultFilter={initialFilter}
            setSelectedFilter={setSelectedFilter}
            selectedFilter={selectedFilter}
          ></TriageFilters>
          <>
            <Body1 fontWeight="bold">Count:</Body1>{' '}
            <Body2>
              {rowData.length} / {triageMedications.length}
            </Body2>
          </>
        </Stack>
        <MuiMenu
          label="Create MedRec"
          disabled={selectedMedications?.length <= 0}
          options={[
            {
              label: 'Verify, Lock and Certify',
              onAction: showVerifyAndLockAndCertifyConfirmationDialog
            }
          ]}
        />
        {selectedMedications?.length > 0 && (
          <TriageActions
            selectedMedications={selectedMedications}
            updateMedications={updateMedications}
            changeMedsIsCurrent={changeMedsIsCurrent}
            verifyMedications={verifyMedications}
          ></TriageActions>
        )}

        {selectedMedications?.length === 0 && (
          <DropdownMenu
            menuItemsData={{
              label: `Page Size: ${pagingSize}`,
              items: [
                {
                  label: '10',
                  callback: () => setPagingSize(10)
                },
                {
                  label: '20',
                  callback: () => setPagingSize(20)
                },
                {
                  label: '50',
                  callback: () => setPagingSize(50)
                },
                {
                  label: '100',
                  callback: () => setPagingSize(100)
                },
                {
                  label: '200',
                  callback: () => setPagingSize(200)
                }
              ]
            }}
            MenuProps={{
              elevation: 3,
              anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
              transformOrigin: { vertical: 'top', horizontal: 'right' }
            }}
            ButtonProps={{ variant: 'outlined', color: 'primary' }}
          />
        )}
      </Stack>

      <Paper component={Stack} direction="column">
        <StyledDataGrid
          apiRef={gridRef}
          columns={columns}
          rows={rowData}
          checkboxSelection
          getRowHeight={() => 'auto'}
          paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
          onPaginationModelChange={({ page }) => {
            setCurrentPage(page);
          }}
          onRowDoubleClick={onRowDoubleClick}
          onRowSelectionModelChange={() => {
            const rows = gridRef.current.getSelectedRows();
            const medications: Medication[] = Array.from(rows.values()) as Medication[];
            setSelectedMedications(medications);
          }}
        />
      </Paper>
    </Stack>
  );
};

export default TriageMedicationList;
