import { H3 } from '@/components/Typography';
import { REQUEST_MED_HISTORY_MEDICATIONS } from '@/graphql/medHistory';
import type { ImportAccountCareSummaryResponse } from '@/graphql/rpmWorkflow';
import { IMPORT_ACCOUNT_CARE_SUMMARY } from '@/graphql/rpmWorkflow';
import { tomorrow } from '@/util/date';
import { formatDate } from '@/util/format';
import { useMutation } from '@apollo/client';
import SendIcon from '@mui/icons-material/Send';
import { Alert, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import type { Dispatch, SetStateAction } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle } from 'react';

type Props = {
  truentityId: string;
  setIsExternalServicesLoading: Dispatch<SetStateAction<boolean>>;
  rpmFollowupConsultId?: string;
};

export type RequestExternalStepsHandles = {
  submitForm: () => Promise<any>;
};

const RequestExternalServiceStep = forwardRef<RequestExternalStepsHandles, Props>(
  ({ truentityId, setIsExternalServicesLoading, rpmFollowupConsultId }, ref) => {
    const { enqueueSnackbar } = useSnackbar();

    const [requestMedication, { loading: requestMedicationLoading }] = useMutation(REQUEST_MED_HISTORY_MEDICATIONS);
    const [importAccountCareSummary, { loading: importAccountCareSummaryLoading }] =
      useMutation<ImportAccountCareSummaryResponse>(IMPORT_ACCOUNT_CARE_SUMMARY);

    const handleSendRequest = useCallback(async () => {
      try {
        const resultOnePromise = importAccountCareSummary({
          variables: {
            truentityId,
            appointmentDate: formatDate(tomorrow(), 'YYYY-MM-DD'),
            rpmFollowupConsultId: rpmFollowupConsultId ?? null
          }
        });
        const resultTwoPromise = requestMedication({
          variables: {
            truentityIds: [truentityId]
          }
        });
        const [resultOne, resultTwo] = await Promise.all([resultOnePromise, resultTwoPromise]);

        if (resultOne.data?.importAccountCareSummary?.status === 'Success') {
          enqueueSnackbar('Clinical summaries have been successfully requested.', { variant: 'success' });
        } else {
          enqueueSnackbar('Failed to request clinical summaries. Please consider adding them manually', { variant: 'error' });
        }
        if (resultTwo.data.requestMedHistoryMedications?.status.includes('Success')) {
          enqueueSnackbar('Medications have been successfully requested.', { variant: 'success' });
        } else {
          enqueueSnackbar('Failed to request medications. Please consider adding them manually', { variant: 'error' });
        }

        return [resultOne, resultTwo];
      } catch (error) {
        enqueueSnackbar('Can not request medications and clinical summaries, Please contact support.', { variant: 'error' });
        throw new Error('Failed to execute requests. Please check network or data issues.');
      }
    }, [truentityId, importAccountCareSummary, requestMedication, enqueueSnackbar]);

    useImperativeHandle(ref, () => ({
      submitForm: handleSendRequest
    }));

    useEffect(() => {
      setIsExternalServicesLoading(requestMedicationLoading || importAccountCareSummaryLoading);
    }, [requestMedicationLoading, importAccountCareSummaryLoading, setIsExternalServicesLoading]);

    return (
      <Stack>
        <H3
          sx={{
            marginBottom: 3
          }}
        >
          Request Medications and Clinical Summary
        </H3>
        <Alert icon={<SendIcon fontSize="inherit" />} severity="info">
          You may now request the most recent medications and the Clinical Summary document for this patient so that they are available in
          the portal, prior to the patient's {rpmFollowupConsultId ? 'follow-up' : 'initial'} visit.
        </Alert>
      </Stack>
    );
  }
);

export default RequestExternalServiceStep;
