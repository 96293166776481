import Button from '@/components/Button';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import PatientStartVisitDialog from '@/components/Dialogs/PatientStartVisitDialog';
import PatientDetailContext from '@/context/patientDetailContext';
import type { UpdateRpmFollowupConsultResponse } from '@/graphql/rpmWorkflow';
import { UPDATE_RPM_FOLLOWUP_CONSULT } from '@/graphql/rpmWorkflow';
import useToken from '@/hooks/useToken';
import ButtonPopover from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmWorkflow/ButtonPopover';
import { Role } from '@/types/admin';
import { RpmFollowupConsultStatusType, VisitTypeEnum } from '@/types/remotePatientMonitoring';
import { mandatoryTabsForRpmFollowup } from '@/util/constants';
import { getFollowupConsultEligibility } from '@/util/rpm';
import { useRpmFollowupConsultStore } from '@/zustand/RpmFollowupConsultStore';
import { useMutation } from '@apollo/client';
import VideoChatIcon from '@mui/icons-material/VideoChat';
import { Stack } from '@mui/material';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useContext, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

const RpmFollowupConsultStatusBar = () => {
  const { patientInfo, setReloadPatientInfo } = useContext(PatientDetailContext);
  const { showModal, hideModal } = useModal();
  const { rpmFollowupConsultId } = useParams();
  const { roleType } = useToken();
  const confirmationDialogId = useRef<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { setShouldReFetchRpmFollowupConsult, rpmFollowupConsultTabs } = useRpmFollowupConsultStore();
  const { rpmFollowupConsult } = useRpmFollowupConsultStore();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const isFollowupCompleted = useMemo(
    () => rpmFollowupConsult?.status === RpmFollowupConsultStatusType.COMPLETED,
    [rpmFollowupConsult?.status]
  );
  const isProviderAdmin = useMemo(() => roleType === Role.PROVIDER, [roleType]);

  const [updateRpmFollowupConsult] = useMutation<UpdateRpmFollowupConsultResponse>(UPDATE_RPM_FOLLOWUP_CONSULT);

  const hidePrevModal = () => {
    confirmationDialogId.current && hideModal(confirmationDialogId.current);
  };

  const onStartTelehealthVisit = async (keepModal?: boolean) => {
    try {
      const response = await updateRpmFollowupConsult({
        variables: {
          id: rpmFollowupConsultId,
          rpmFollowupConsultInput: {
            isTelehealthVisitLaunched: true
          }
        }
      });
      if (response.data?.updateRpmFollowupConsult?.status === 'Success') {
        setShouldReFetchRpmFollowupConsult(true);
        setReloadPatientInfo(true);
      } else {
        enqueueSnackbar('Unable to start visit, Please contact support', { variant: 'warning' });
      }
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Unable to start visit, Please contact support', { variant: 'error' });
    } finally {
      if (!keepModal) {
        hidePrevModal();
      }
    }
  };

  const handleFollowupTelehealthVisitButton = () => {
    const modal = showModal(PatientStartVisitDialog, {
      title: 'Preparing for Follow-up Telehealth Visit',
      patientInfo: patientInfo,
      onStartTelehealthVisit: onStartTelehealthVisit,
      hideDialog: () => {
        modal.hide();
      },
      visitType: VisitTypeEnum.FOLLOWUP_VISIT
    });
    confirmationDialogId.current = modal.id;
  };

  const updateRpmFollowupConsultStatus = async () => {
    try {
      const response = await updateRpmFollowupConsult({
        variables: {
          id: rpmFollowupConsultId,
          rpmFollowupConsultInput: {
            status: RpmFollowupConsultStatusType.COMPLETED
          }
        }
      });

      if (response.data?.updateRpmFollowupConsult?.status === 'Success') {
        enqueueSnackbar('Marked RPM Follow-up Visit as Completed.', {
          variant: 'success'
        });
        setShouldReFetchRpmFollowupConsult(true);
      } else {
        enqueueSnackbar(response.data?.updateRpmFollowupConsult?.message ?? 'Could not mark RPM Follow-up Visit as Completed.', {
          variant: 'error'
        });
      }
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Could not mark RPM Follow-up Visit as Completed.', {
        variant: 'error'
      });
    }
  };

  const openIncompleteTabsPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onFollowupStatusUpdateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const isFollowupEligible = getFollowupConsultEligibility(rpmFollowupConsultTabs);
    if (isFollowupEligible) {
      const modal = showModal(ConfirmDialog, {
        title: 'Confirm Complete',
        message: "This will mark the Patient's Follow-up Visit as 'Completed'. Are you sure you want to proceed?",
        onAgree: async () => {
          await updateRpmFollowupConsultStatus();
          modal.hide();
        },
        onDisagree: () => {
          modal.hide();
        },
        maxWidth: 'md'
      });
    } else {
      openIncompleteTabsPopover(e);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!isProviderAdmin ? (
        <Stack direction="row" justifyContent="space-between" alignItems="center" borderRadius={4} padding={2} spacing={2}>
          <Button
            variant={'outlined'}
            color="success"
            startIcon={<VideoChatIcon />}
            disabled={patientInfo?.accountRpmMonitoredCondition?.length === 0 || isFollowupCompleted}
            onClick={handleFollowupTelehealthVisitButton}
          >
            Start Visit
          </Button>
          <Stack direction="row" justifyContent="flex-end" spacing={1}>
            <Button disabled={isFollowupCompleted} onClick={onFollowupStatusUpdateClick}>
              Mark as Completed
            </Button>
          </Stack>
        </Stack>
      ) : (
        <></>
      )}
      <ButtonPopover
        id={'followup-consult-popover'}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={handleClose}
        mandatoryTabs={mandatoryTabsForRpmFollowup}
        rpmFollowupConsultId={rpmFollowupConsultId}
      />
    </>
  );
};

export default RpmFollowupConsultStatusBar;
