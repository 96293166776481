import MuiTabs from '@/components/MuiTabs';
import { H1 } from '@/components/Typography';
import { AthenaObjectActionType } from '@/types/administration';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import MedicationIcon from '@mui/icons-material/Medication';
import PaidIcon from '@mui/icons-material/Paid';
import { Box } from '@mui/material';
import AthenaObjectTab from './Components/AthenaObjectTab';

const AthenaObjects = () => {
  return (
    <>
      <Box>
        <H1 textAlign="left">Data Imported from Athenahealth</H1>
      </Box>
      <Box mt={2}>
        <MuiTabs
          aria-label="Athena health object"
          tabVariant="fullWidth"
          tabs={[
            {
              label: 'Insurance',
              icon: <LocationCityIcon />,
              sx: { textTransform: 'uppercase' },
              children: <AthenaObjectTab type={AthenaObjectActionType.PATIENT_INSURANCE} />
            },
            {
              label: 'Medications',
              icon: <MedicationIcon />,
              sx: { textTransform: 'uppercase' },
              children: <AthenaObjectTab type={AthenaObjectActionType.PATIENT_MEDICATION} />
            },
            {
              label: 'Claim',
              icon: <PaidIcon />,
              sx: { textTransform: 'uppercase' },
              children: <AthenaObjectTab type={AthenaObjectActionType.PATIENT_CLAIMS} />
            }
          ]}
          customPadding
        />
      </Box>
    </>
  );
};

export default AthenaObjects;
