import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import MedConsultDetailsDialog from '@/components/Dialogs/MedConsultDetailsDialog';
import type { SplitActionButton } from '@/components/MuiMenu';
import MuiMenu from '@/components/MuiMenu';
import { H3 } from '@/components/Typography';
import PatientDetailContext from '@/context/patientDetailContext';
import type {
  Consult,
  DuplicateMedConsultResponse,
  GetMedConsultResponse,
  GetMedConsultsResponse,
  GetMedConsultTemplatesResponse,
  MedConsultTemplate
} from '@/graphql/med-consult';
import { DUPLICATE_MED_CONSULT, GET_MED_CONSULT, GET_MED_CONSULTATIONS, GET_MED_CONSULT_TEMPLATES } from '@/graphql/med-consult';
import type { UpdateRpmSetupResponse } from '@/graphql/remotePatientMonitoring';
import { UPDATE_RPM_SETUP } from '@/graphql/remotePatientMonitoring';
import type { GetDocumentPreviewResponse } from '@/graphql/rpmWorkflow';
import { GET_DOCUMENT_PREVIEW } from '@/graphql/rpmWorkflow';
import BaseRpmWorkflowTabContent from '@/routes/PatientDetails/RemotePatientMonitoring/BaseRpmWorkflowTabContent';
import RpmFollowTabFilterSelect from '@/routes/PatientDetails/RemotePatientMonitoring/Components/FollowupConsults/RpmTabFilterSelect';
import {
  MedConsultStatusType,
  RpmFollowupConsultFilterType,
  RpmFollowupConsultStatusType,
  RpmSetupStatusTypes,
  RpmWorkflowTab
} from '@/types/remotePatientMonitoring';
import { getAccountUserFullName } from '@/util/account';
import { formatDate } from '@/util/format';
import {
  getConditionStrings,
  MedConsultStatusColorMapping,
  medConsultToString,
  replaceUnderscoresWithSpaces,
  updateRpmTabStatus
} from '@/util/rpm';
import { useMedConsultWindowStore } from '@/zustand/MedConsultWindowStore';
import { useRpmFollowupConsultStore } from '@/zustand/RpmFollowupConsultStore';
import { useRpmSetupStore } from '@/zustand/RpmSetupStore';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArticleIcon from '@mui/icons-material/Article';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SubjectIcon from '@mui/icons-material/Subject';
import { Chip, IconButton, Stack, Tooltip } from '@mui/material';
import type { GridColDef, GridRowId } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PdfDialog from '../../../components/Dialogs/PdfDialog';
import RpmWorkflowTabTitle from './RpmWorkflowTabTitle';

const MedConsults = () => {
  const { id: truentityId, rpmFollowupConsultId } = useParams();
  const { setOpenWindow, setConsult, setSelectedConsultType, selectedConsultType, refetchConsults, setRefetchConsults } =
    useMedConsultWindowStore();
  const { enqueueSnackbar } = useSnackbar();
  const { rpmSetupTabs, editRpmStatus, activeRpmSetupTab } = useRpmSetupStore();
  const { rpmFollowupConsultTabs, rpmFollowupConsult } = useRpmFollowupConsultStore();
  const { patientInfo } = useContext(PatientDetailContext);

  const isActionDisabled = useMemo(() => {
    if (rpmFollowupConsultId) {
      return rpmFollowupConsult?.status === RpmFollowupConsultStatusType.COMPLETED;
    } else {
      return false;
    }
  }, [rpmFollowupConsult, rpmFollowupConsultId]);

  const isReadOnlyRole = useMemo(() => {
    if (rpmFollowupConsultId) {
      return rpmFollowupConsultTabs[RpmWorkflowTab.MEDICAL_CONSULTS]?.isReadOnly || false;
    } else {
      return activeRpmSetupTab?.isReadOnly || false;
    }
  }, [activeRpmSetupTab, rpmFollowupConsultId, rpmFollowupConsultTabs]);

  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [medConsultTypes, setMedConsultTypes] = useState<SplitActionButton[]>([]);
  const [followupFilterType, setFollowupFilterType] = useState<RpmFollowupConsultFilterType>(RpmFollowupConsultFilterType.INDIVIDUAL);

  const [getDocumentPreview] = useLazyQuery<GetDocumentPreviewResponse>(GET_DOCUMENT_PREVIEW);
  const { data: getMedConsultTemplates, error: getMedConsultTemplatesError } = useQuery<GetMedConsultTemplatesResponse>(
    GET_MED_CONSULT_TEMPLATES,
    { onCompleted: () => setSelectedConsultType(null) }
  );

  const {
    data: getMedConsultsData,
    error: getMedConsultsError,
    refetch: fetchMedConsults,
    loading: isFetchMedConsultLoading
  } = useQuery<GetMedConsultsResponse>(GET_MED_CONSULTATIONS, {
    variables: {
      truentityId,
      pageNum: 1,
      filterOptions: {
        rpmFollowupConsultId: rpmFollowupConsultId,
        returnAll: Boolean(rpmFollowupConsultId && followupFilterType === RpmFollowupConsultFilterType.ALL)
      },
      pageSize: DEFAULT_PAGE_SIZE
    },
    skip: !truentityId
  });

  const [getMedConsult, { error: getMedConsultError }] = useLazyQuery<GetMedConsultResponse>(GET_MED_CONSULT, {
    onCompleted: getMedConsultData => {
      setConsult(getMedConsultData.medConsult);
    },
    fetchPolicy: 'network-only'
  });
  const [updateRpmSetup] = useMutation<UpdateRpmSetupResponse>(UPDATE_RPM_SETUP);
  const [duplicateMedConsultMutation, { loading: loadingDuplicateMedConsult }] =
    useMutation<DuplicateMedConsultResponse>(DUPLICATE_MED_CONSULT);

  const { showModal } = useModal();

  const showConfirmDialog = useCallback(
    (title: string, message: string, onAgree: () => void) => {
      const modal = showModal(ConfirmDialog, {
        title,
        message,
        onAgree: () => {
          modal.hide();
          onAgree();
        },
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [showModal]
  );

  const fetchDocumentPreview = useCallback(
    async (s3Key: string) => {
      try {
        const documentResponse = await getDocumentPreview({
          variables: {
            documentS3Key: s3Key
          }
        });
        if (documentResponse?.data?.documentPreview?.documentUrl) {
          fetch(`${documentResponse?.data?.documentPreview?.documentUrl}`)
            .then(response => response.blob())
            .then(blob => {
              const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
              const modal = showModal(PdfDialog, {
                title: 'Intake (HPI) Report Preview',
                pdfUrl: url,
                hideDialog: () => {
                  modal.hide();
                }
              });
            })
            .catch(error => {
              console.error(error);
            });
        } else {
          enqueueSnackbar('Failed to load document preview', { variant: 'error' });
        }
      } catch (err) {
        enqueueSnackbar('Failed to load document preview', { variant: 'error' });
      }
    },
    [enqueueSnackbar, getDocumentPreview, showModal]
  );

  const handleRefetchMedConsults = useCallback(async () => {
    await fetchMedConsults();
    setRefetchConsults(false);
  }, [fetchMedConsults, setRefetchConsults]);

  const selectConsultation = useCallback(
    async (consult: Consult) => {
      if (consult) {
        await getMedConsult({
          variables: {
            medConsultId: consult.id
          }
        });
        setOpenWindow(true);
      }
    },
    [getMedConsult, setOpenWindow]
  );

  const duplicateConsult = useCallback(
    async (consult: Consult) => {
      try {
        const response = await duplicateMedConsultMutation({
          variables: {
            medConsultId: consult.id,
            rpmFollowupConsultId
          }
        });

        if (response?.data?.duplicateMedConsult?.status === 'Success') {
          enqueueSnackbar(response?.data?.duplicateMedConsult?.message ?? 'Successfully duplicated the Intake (HPI)', {
            variant: 'success'
          });
          setRefetchConsults(true);
        } else {
          const errorMessage = response?.errors?.[0]?.message || 'Something went wrong. Could not duplicate Intake (HPI).';
          enqueueSnackbar(errorMessage, {
            variant: 'error'
          });
        }
      } catch (err: any) {
        enqueueSnackbar(err?.message ?? 'Something went wrong. Could not duplicate Intake (HPI).', { variant: 'error' });
      }
    },
    [setRefetchConsults, enqueueSnackbar, rpmFollowupConsultId, duplicateMedConsultMutation]
  );

  const handleDuplicateConsult = useCallback(
    (consult: Consult) => {
      const modal = showModal(ConfirmDialog, {
        title: 'Confirm Duplicate Intake (HPI)',
        message: 'This action will generate a new Intake (HPI) with the same questions and answers. Are you sure you want to proceed?',
        onAgree: async () => {
          await duplicateConsult(consult);
          modal.hide();
        },
        onDisagree: () => {
          modal.hide();
        },
        maxWidth: 'md'
      });
    },
    [showModal, duplicateConsult]
  );

  const handleConsultModal = useCallback(
    (consult: Consult) => {
      const modal = showModal(MedConsultDetailsDialog, {
        title: consult.title,
        content: medConsultToString(consult),
        onDisagree: () => {
          modal.hide();
        }
      });
    },
    [showModal]
  );

  const columns: GridColDef<Consult>[] = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Title',
        sortable: true,
        flex: 2,
        align: 'left'
      },
      {
        field: 'startTime',
        headerName: 'Performed On',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => formatDate(params.value)
      },
      {
        field: 'performedBy',
        headerName: 'Performed By',
        sortable: true,
        flex: 1,
        align: 'left',
        valueGetter: params => getAccountUserFullName(params.row.relyingPartyAdmin?.user) ?? 'Unknown'
      },
      {
        field: 'status',
        headerName: 'Status',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          return (
            <Chip
              label={replaceUnderscoresWithSpaces(params?.value)}
              color={MedConsultStatusColorMapping.get(params?.value) ?? 'warning'}
              sx={{ textTransform: 'capitalize' }}
              variant="filled"
            />
          );
        }
      },
      {
        field: 'reportS3Key',
        headerName: 'Report',
        sortable: false,
        flex: 1,
        align: 'center',
        headerAlign: 'center',
        renderCell: params => {
          return (
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
              <Tooltip title="View Intake (HPI) Report">
                <span>
                  <IconButton
                    size="small"
                    onClick={() => {
                      fetchDocumentPreview(params?.row?.reportS3Key);
                    }}
                    disabled={!params?.row?.reportS3Key}
                  >
                    <ArticleIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          );
        }
      },
      {
        field: 'id',
        headerName: 'Actions',
        sortable: false,
        align: 'center',
        flex: 1,
        headerAlign: 'center',
        filterable: false,
        renderCell: params => {
          return (
            <Stack direction="row" sx={{ width: '100%' }} alignItems="center" columnGap={1} justifyContent={'center'}>
              <Tooltip title="Preview">
                <span>
                  <IconButton
                    onClick={() => {
                      handleConsultModal(params.row);
                    }}
                    disabled={params.row.status !== MedConsultStatusType.ENDED}
                  >
                    <SubjectIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Duplicate">
                <span>
                  <IconButton
                    onClick={() => {
                      handleDuplicateConsult(params.row);
                    }}
                    disabled={isReadOnlyRole || loadingDuplicateMedConsult || isActionDisabled}
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="View">
                <span>
                  <IconButton
                    onClick={() => {
                      selectConsultation(params.row).catch(err => console.error(err));
                    }}
                    disabled={isReadOnlyRole || isActionDisabled}
                  >
                    <ArrowForwardIcon fontSize="small" />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          );
        }
      }
    ],
    [fetchDocumentPreview, handleConsultModal, isReadOnlyRole, isActionDisabled, selectConsultation]
  );

  const onFollowupFilterChange = useCallback(
    (value: RpmFollowupConsultFilterType) => {
      setFollowupFilterType(value);
    },
    [setFollowupFilterType]
  );

  const onMedConsultTypeSelected = useCallback(
    (template: MedConsultTemplate) => {
      setSelectedConsultType(template);
    },
    [setSelectedConsultType]
  );

  useEffect(() => {
    if (getMedConsultTemplatesError) {
      enqueueSnackbar('Failed to get intake (HPI) templates', { variant: 'error' });
    } else if (getMedConsultTemplates) {
      const mappedTypes = getMedConsultTemplates.medConsultTemplates.map(item => {
        return {
          label: item.name,
          onAction: () => onMedConsultTypeSelected(item)
        } as SplitActionButton;
      });
      setMedConsultTypes(mappedTypes);
    }
  }, [enqueueSnackbar, getMedConsultTemplates, getMedConsultTemplatesError, onMedConsultTypeSelected]);

  useEffect(() => {
    if (refetchConsults) {
      handleRefetchMedConsults().catch(err => console.error(err));
    }
  }, [handleRefetchMedConsults, refetchConsults]);

  useEffect(() => {
    if (selectionModel?.length > 0) {
      showConfirmDialog('Confirm Action', 'Are you sure you want to mark this as completed?', () =>
        updateRpmTabStatus(
          rpmSetupTabs,
          RpmWorkflowTab.MEDICAL_CONSULTS,
          editRpmStatus,
          updateRpmSetup,
          true,
          RpmSetupStatusTypes.COMPLETED
        ).catch(err => console.error(err))
      );
    }
  }, [editRpmStatus, rpmSetupTabs, selectionModel, showConfirmDialog, updateRpmSetup]);

  useEffect(() => {
    if (patientInfo && patientInfo.accountRpmMonitoredCondition && getMedConsultTemplates) {
      const matchedTemplate = getMedConsultTemplates.medConsultTemplates.find(
        medConsultTemplate =>
          getConditionStrings(patientInfo.accountRpmMonitoredCondition).toLowerCase() === medConsultTemplate.name.toLowerCase()
      );

      setSelectedConsultType(matchedTemplate || null);
    }
  }, [patientInfo, medConsultTypes, getMedConsultTemplates, setSelectedConsultType]);

  useEffect(() => {
    if (getMedConsultError) {
      enqueueSnackbar('Something went wrong. Could not get intake (HPI) details, please try again.', { variant: 'error' });
    }
  }, [enqueueSnackbar, getMedConsultError]);

  useEffect(() => {
    if (getMedConsultsError) {
      enqueueSnackbar('Something went wrong. Could not get intake (HPI), please try again.', { variant: 'error' });
    }
  }, [enqueueSnackbar, getMedConsultsError]);

  return (
    <BaseRpmWorkflowTabContent isReadOnly={isReadOnlyRole}>
      <Stack component="form" spacing={3}>
        <Stack direction="row" justifyContent={'space-between'} alignItems="center">
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <RpmWorkflowTabTitle refreshButton={{ onClick: () => fetchMedConsults(), isLoading: isFetchMedConsultLoading }}>
              <H3>Intake (HPI)</H3>
            </RpmWorkflowTabTitle>
            {rpmFollowupConsultId && (
              <RpmFollowTabFilterSelect onFilterValueChange={onFollowupFilterChange} followupFilterType={followupFilterType} />
            )}
          </Stack>
          <MuiMenu
            disabled={isReadOnlyRole || isActionDisabled}
            label={selectedConsultType === null ? 'START AN INTAKE (HPI)' : selectedConsultType?.name}
            options={medConsultTypes}
          ></MuiMenu>
        </Stack>

        <TruentityDataGrid
          name={'dg-med-consults'}
          autoHeight
          columns={columns}
          rows={getMedConsultsData?.medConsults.medConsults || []}
          checkboxSelection={!isReadOnlyRole && !isActionDisabled}
          disableRowSelectionOnClick={true}
          rowSelectionModel={selectionModel}
          hideFooterSelectedRowCount
          onRowSelectionModelChange={newSelection => {
            setSelectionModel(newSelection);
          }}
          isRowSelectable={params => params.row.status === MedConsultStatusType.ENDED}
        />
      </Stack>
    </BaseRpmWorkflowTabContent>
  );
};

export default MedConsults;
