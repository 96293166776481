import Alert from '@/components/Alert';
import Button from '@/components/Button';
import ReportSignOffDialog from '@/components/Dialogs/ReportSignOffDialog';
import { Small } from '@/components/Typography';
import SideMenuContext from '@/context/sideMenuContext';
import type { GetRpmReportsMonthlyResponse, MonthlyResponse } from '@/graphql/remotePatientMonitoring';
import {
  GET_RPM_MONTHLY_REPORTS_FOR_REVIEW,
  SAVE_PATIENT_REVIEW_NOTE,
  START_PATIENT_REVIEW_WORKFLOW
} from '@/graphql/remotePatientMonitoring';
import useToken from '@/hooks/useToken';
import RpmPatientReviewNotesList from '@/routes/PatientDetails/RemotePatientMonitoring/Components/RpmPatientReviewWorkflow/RpmPatientReviewNotesList';
import { color } from '@/styles/assets/colors';
import { Role } from '@/types/admin';
import type { GeneralUpdateResponseType } from '@/types/graphql';
import { formatDateAndTime } from '@/util/format';
import { useAccountStore } from '@/zustand/AccountStore';
import { useMutation, useQuery } from '@apollo/client';
import { CloseFullscreen, OpenInFull } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SendIcon from '@mui/icons-material/Send';
import {
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  type SelectChangeEvent
} from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

const RpmPatientReviewWorkflowWindow: React.FC = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { setShowPatientReviewWindow } = useAccountStore();
  const { showModal } = useModal();
  const { roleType } = useToken();

  const [isWindowExpanded, setIsWindowExpanded] = useState(false);
  const [reportMonth, setReportMonth] = useState<MonthlyResponse | null>(null);
  const isProviderAdmin = useMemo(() => roleType === Role.PROVIDER, [roleType]);

  const { register, handleSubmit, watch, reset } = useForm<{ note: string }>();
  const noteWatch = watch('note');

  const notesEndRef = useRef<HTMLDivElement | null>(null);
  const { drawerOpen } = useContext(SideMenuContext);

  // const [
  //   fetchPatientRpmReviewWorkflow,
  //   { error: errorPatientRpmReviewWorkflow, loading: loadingPatientRpmReviewWorkflow, refetch: reFetchPatientRpmReviewWorkflow }
  // ] = useLazyQuery<GetRpmAccountReviewWorkflowResponse>(GET_PATIENT_RPM_REVIEW_WORKFLOW, {
  //   fetchPolicy: 'cache-and-network'
  // });

  const {
    data: rpmMonthlyReportsData,
    error: errorRpmMonthlyReports,
    loading: loadingRpmMonthlyReports,
    refetch: reFetchRpmMonthlyReports
  } = useQuery<GetRpmReportsMonthlyResponse>(GET_RPM_MONTHLY_REPORTS_FOR_REVIEW, {
    variables: {
      truentityId: id,
      includeCurrentMonth: true
    },
    fetchPolicy: 'cache-and-network'
  });

  const [startPatientReviewWorkflow, { loading: loadingStartPatientReviewWorkflow }] = useMutation<{
    createAccountReviewWorkflow: GeneralUpdateResponseType;
  }>(START_PATIENT_REVIEW_WORKFLOW);

  const [savePatientReviewNote, { loading: loadingSavePatientReviewNote }] = useMutation<{
    createAccountReviewNote: GeneralUpdateResponseType;
  }>(SAVE_PATIENT_REVIEW_NOTE);

  const handleClose = () => {
    setShowPatientReviewWindow(false);
  };

  const handleExpand = () => {
    isWindowExpanded ? setIsWindowExpanded(false) : setIsWindowExpanded(true);
  };

  const handleSendNote = async (data: { note: string }) => {
    try {
      const response = await savePatientReviewNote({
        variables: {
          accountReviewWorkflowId: reportMonth?.report?.rpmAccountReviewWorkflow?.id,
          note: data.note
        }
      });

      if (response?.data?.createAccountReviewNote?.status === 'Success') {
        enqueueSnackbar('Note saved successfully', {
          variant: 'success'
        });
        reset();
      }
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Something went wrong. Could not save note.', {
        variant: 'error'
      });
    }
  };

  const handleMonthlyReportSelect = (event: SelectChangeEvent) => {
    const selectedReportMonth = rpmMonthlyReportsData?.getRpmReportsMonthly?.find(
      rpmMonthlyReport => rpmMonthlyReport.monthYearName === event.target.value
    );
    if (selectedReportMonth) {
      setReportMonth(selectedReportMonth);
    }
  };

  const handleStartPatientReview = async () => {
    try {
      const response = await startPatientReviewWorkflow({
        variables: {
          rpmReportId: reportMonth?.report?.id
        }
      });

      if (response?.data?.createAccountReviewWorkflow?.status === 'Success') {
        enqueueSnackbar('Patient Review started successfully', {
          variant: 'success'
        });
        await reFetchRpmMonthlyReports({
          truentityId: id,
          includeCurrentMonth: true
        });
      } else {
        const errorMessage = response?.errors?.[0]?.message ?? 'Something went wrong. Could not start Patient Review.';
        enqueueSnackbar(errorMessage, {
          variant: 'error'
        });
      }
    } catch (err: any) {
      enqueueSnackbar(err?.message ?? 'Something went wrong. Could not start Patient Review.', {
        variant: 'error'
      });
    }
  };

  const checkReportSignOffDisabled = useCallback(() => {
    return Boolean(
      roleType !== Role.PROVIDER ||
        !reportMonth?.report?.rpmAccountReviewWorkflow ||
        reportMonth?.report?.rpmAccountReviewWorkflow?.signOffAt
    );
  }, [reportMonth?.report?.rpmAccountReviewWorkflow?.signOffAt, roleType]);

  const showReportSignOffDialog = () => {
    if (reportMonth?.report?.rpmAccountReviewWorkflow?.id) {
      const modal = showModal(ReportSignOffDialog, {
        title: 'Visit Sign Off',
        hideDialog: () => {
          modal.hide();
        },
        accountReviewWorkflowId: reportMonth.report.rpmAccountReviewWorkflow.id,
        onSignOffComplete: (success: boolean) => {
          if (success) {
            reFetchRpmMonthlyReports({
              truentityId: id,
              includeCurrentMonth: true
            });
          }
        }
      });
    } else {
      enqueueSnackbar('No RPM Patient Review Workflow found for this Month', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    if (rpmMonthlyReportsData?.getRpmReportsMonthly) {
      if (reportMonth !== null) {
        const selectedReportMonth = rpmMonthlyReportsData?.getRpmReportsMonthly?.find(
          rpmMonthlyReport => rpmMonthlyReport.monthYearName === reportMonth.monthYearName
        );
        if (selectedReportMonth) {
          setReportMonth(selectedReportMonth);
        }
      } else {
        setReportMonth(rpmMonthlyReportsData.getRpmReportsMonthly?.[0]);
      }
    }
  }, [rpmMonthlyReportsData?.getRpmReportsMonthly]);

  useEffect(() => {
    if (errorRpmMonthlyReports) {
      const errorMessage = errorRpmMonthlyReports?.message ?? 'Could not fetch RPM Monthly Reports';
      enqueueSnackbar(errorMessage, {
        variant: 'error'
      });
    }
  }, [enqueueSnackbar, errorRpmMonthlyReports]);

  const drawerWidth = drawerOpen ? '360px' : '72px';
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      position={isWindowExpanded ? { x: 0, y: 0 } : undefined}
      disabled={isWindowExpanded}
    >
      <Card
        elevation={14}
        sx={{
          zIndex: theme => theme.zIndex.drawer + 20,
          position: 'fixed',
          bottom: isWindowExpanded ? 0 : 10,
          right: isWindowExpanded ? 0 : 40,
          width: isWindowExpanded ? `calc(100% - ${drawerWidth})` : 500,
          height: isWindowExpanded ? `calc(100% - 85px)` : 590,
          background: '#fff',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <CardHeader
          title="Patient Review"
          subheader={
            reportMonth?.report?.rpmAccountReviewWorkflow?.signOffAt ? (
              <Small marginTop={1} color={color.primaryDark} sx={{ display: 'inline' }}>
                Signed off on {formatDateAndTime(reportMonth.report.rpmAccountReviewWorkflow.signOffAt)}
              </Small>
            ) : undefined
          }
          id="draggable-dialog-title"
          action={
            <Stack flexDirection="column" width="100%" height="auto" alignItems="stretch" justifyContent="flex-start">
              <Stack flexDirection="row" justifyContent="flex-end">
                <IconButton size="small" aria-label="Close" onClick={handleExpand}>
                  {isWindowExpanded ? <CloseFullscreen /> : <OpenInFull />}
                </IconButton>
                <IconButton size="small" aria-label="Close" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Stack>
              <Stack direction="row" alignItems="center">
                <FormControl color="primary" variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <Select
                    color="primary"
                    variant="outlined"
                    id="review-report_months"
                    defaultValue={reportMonth?.monthYearName ?? null}
                    value={reportMonth?.monthYearName ?? null}
                    disabled={loadingRpmMonthlyReports || (rpmMonthlyReportsData?.getRpmReportsMonthly?.length ?? 0) === 0}
                    onChange={handleMonthlyReportSelect}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    {rpmMonthlyReportsData?.getRpmReportsMonthly?.map(({ monthYearName }, index) => (
                      <MenuItem
                        sx={{
                          minWidth: 120
                        }}
                        key={index}
                        value={monthYearName}
                      >
                        {monthYearName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  disabled={checkReportSignOffDisabled() || loadingRpmMonthlyReports}
                  sx={{ backgroundColor: color.secondaryMain }}
                  size="small"
                  onClick={showReportSignOffDialog}
                >
                  Sign Off
                </Button>
              </Stack>
            </Stack>
          }
          sx={{ p: 2, cursor: isWindowExpanded ? 'auto' : 'move', borderBottom: 1, borderColor: theme => theme.palette.grey[300] }}
        />
        <CardContent
          sx={{
            display: 'inline-block',
            height: '100%',
            width: '100%',
            maxHeight: 'auto',
            overflowY: 'auto'
          }}
        >
          {!reportMonth?.report?.rpmAccountReviewWorkflow?.id ? (
            <Alert description="No RPM Patient Review Workflow found for this Month" status={'warning'} />
          ) : (
            <></>
          )}
          <RpmPatientReviewNotesList accountReviewWorkflowId={reportMonth?.report?.rpmAccountReviewWorkflow?.id} />
          <div ref={notesEndRef} />
        </CardContent>
        <CardActions sx={{ p: 1 }}>
          {reportMonth?.report?.rpmAccountReviewWorkflow ? (
            <Stack direction="row" component="form" onSubmit={handleSubmit(handleSendNote)} alignItems="center" flexGrow={1}>
              <TextField
                id="note"
                {...register('note', { required: true })}
                name={'note'}
                placeholder={'Type a note...'}
                variant="outlined"
                fullWidth
                required
                sx={{ marginRight: '8px' }}
              />
              {loadingSavePatientReviewNote ? (
                <CircularProgress color="primary" />
              ) : (
                <IconButton type="submit" disabled={!noteWatch?.trim()}>
                  <SendIcon />
                </IconButton>
              )}
            </Stack>
          ) : (
            <Stack width="100%" marginBottom={2} direction="row" justifyContent="center">
              {isProviderAdmin && (
                <Button
                  isLoading={loadingStartPatientReviewWorkflow}
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleStartPatientReview}
                >
                  Start Review
                </Button>
              )}
            </Stack>
          )}
        </CardActions>
      </Card>
    </Draggable>
  );
};

export default RpmPatientReviewWorkflowWindow;
