import { UPDATE_ACCOUNT_REVIEW_WORKFLOW_STATUS } from '@/graphql/remotePatientMonitoring';
import type { GeneralUpdateResponseType } from '@/types/graphql';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { today } from '@/util/date';
import { formatDateAndTime } from '@/util/format';
import { useMutation, useReactiveVar } from '@apollo/client';
import { DialogActions, DialogContent, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import type React from 'react';
import Button from '../Button';
import { H4 } from '../Typography';
import type { BaseDialogProps } from './BaseDialog';
import BaseDialog from './BaseDialog';

interface StoreAddDialogProps extends BaseDialogProps {
  hideDialog: () => void;
  onSignOffComplete: (success: boolean) => void;
  accountReviewWorkflowId: string;
}

const ReportSignOffDialog: React.FC<StoreAddDialogProps> = ({
  title,
  hideDialog,
  onSignOffComplete,
  accountReviewWorkflowId,
  ...props
}) => {
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();

  const [updateRpmReportReviewStatus, { loading: updateRpmReportReviewStatusLoading }] = useMutation<{
    updateAccountReviewWorkflowStatus: GeneralUpdateResponseType;
  }>(UPDATE_ACCOUNT_REVIEW_WORKFLOW_STATUS);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const response = await updateRpmReportReviewStatus({
        variables: {
          accountReviewWorkflowId: accountReviewWorkflowId,
          reviewType: 'SIGN_OFF'
        }
      });

      if (response?.data?.updateAccountReviewWorkflowStatus?.status === 'Success') {
        enqueueSnackbar('Successfully signed off', {
          variant: 'success'
        });
      } else if (response?.data?.updateAccountReviewWorkflowStatus?.status === 'Warning') {
        enqueueSnackbar(
          response?.data?.updateAccountReviewWorkflowStatus?.message ?? 'Successfully signed off Patient Report with warnings.',
          {
            variant: 'warning'
          }
        );
      } else {
        const errorMessage = response?.errors?.[0]?.message || 'Something went wrong, unable to sign off. Please try again later.';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }

      onSignOffComplete(true);
    } catch (error) {
      enqueueSnackbar('Unable to sign off', { variant: 'error' });
      onSignOffComplete(false);
    } finally {
      hideDialog();
    }
  };

  return (
    <BaseDialog {...props} title={title} hideDialog={hideDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <form onSubmit={onSubmit}>
          <TableContainer>
            <Table sx={{ border: 'none' }}>
              <TableBody>
                {accountReviewWorkflowId && (
                  <>
                    <TableRow sx={{ borderBottom: 'none' }}>
                      <TableCell sx={{ borderBottom: 'none', padding: '2px 2px 18px' }}>
                        <H4>
                          Signed by {`${currentUser?.user?.firstName} ${currentUser?.user?.lastName}`}; Date{' '}
                          {formatDateAndTime(today().toDate())}
                        </H4>
                      </TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Grid item xs={12}>
            <DialogActions sx={{ justifyContent: 'end', padding: '5px 0 0 0' }}>
              <Button
                type="reset"
                a11yLabel="Cancel"
                appearance="outline"
                onClick={() => {
                  hideDialog();
                }}
              />
              <Button isLoading={updateRpmReportReviewStatusLoading} type="submit" a11yLabel="Sign Off" appearance="primary">
                Sign Off
              </Button>
            </DialogActions>
          </Grid>
        </form>
      </DialogContent>
    </BaseDialog>
  );
};

export default ReportSignOffDialog;
