import { FollowupFormModeTypes } from '@/components/Dialogs/FollowUpAddEditDialog';
import type { DefaultFollowUpEditType } from '@/components/Dialogs/FollowUpTaskAddEditDialog';
import SelectList from '@/components/SelectList';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import TruentityTextField from '@/components/TruentityTextField';
import type { FollowUpCategoryTypes } from '@/graphql/account';
import type { RelyingPartyAdminData } from '@/routes/Administration';
import { followUpTaskTypes } from '@/util/followUp';
import { Autocomplete, Grid, TextField } from '@mui/material';
import type React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  accountAssignees: RelyingPartyAdminData[];
  formMode: FollowupFormModeTypes;
  category: FollowUpCategoryTypes;
};

const AlertReviewFollowUpEditForm: React.FC<Props> = ({ accountAssignees, formMode, category }) => {
  const { control } = useFormContext<DefaultFollowUpEditType>();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            control={control}
            name={`followUpDueDate`}
            render={({ field: { onChange, value } }) => (
              <TruentityDatePicker
                label="Follow-up Due Date"
                format="YYYY-MM-DD"
                value={value}
                onChange={onChange}
                TextFieldProps={{ required: true }}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            name={`type`}
            render={({ field: { onChange, value } }) => (
              <SelectList
                label={'Follow-up Type'}
                options={followUpTaskTypes(category)}
                placeholder="Select an option..."
                value={value}
                keepOriginalValue
                onChange={onChange}
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="taskAssignees"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                id="follow-up-assignees"
                options={accountAssignees}
                onChange={(_event, value) => onChange(value)}
                getOptionLabel={option => option.name || ''}
                value={value}
                disabled={formMode === FollowupFormModeTypes.UPDATE}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Follow-up Assignee"
                    placeholder="select an option"
                    margin="dense"
                    inputProps={{ ...params.inputProps }}
                    required
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            control={control}
            name="note"
            render={({ field: { onChange, value } }) => (
              <TruentityTextField
                value={value}
                multiline
                fullWidth
                rows={2}
                inputProps={{ max: 2 }}
                placeholder="Type your note here"
                id="note"
                label="Notes (Optional)"
                onChange={onChange}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AlertReviewFollowUpEditForm;
